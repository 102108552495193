import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@core/auth-guard.service';
import { RedirectAuthGuardService } from '@core/redirect-auth-guard.service';
import { TokenCadoComponent } from '@core/tokenCado/token-cado/token-cado.component';
import { OrderResolver } from '@services/routing/order.resolver';
import { RoutesEnum } from '@shared/models/common/routes.enum';
import { CategoriesResolver } from '@shared/resolvers/categories.resolver';
import { MerchantResolver } from '@shared/resolvers/merchant.resolver';

const routes: Routes = [
  {
    path: 'order',
    resolve: {ordeResolver: OrderResolver},
    loadChildren: () => import('./features/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'my-orders',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/my-orders/my-orders.module').then((m) => m.MyOrdersModule),
  },
  {
    path: 'my-orders/:orderId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/my-orders/order-details/order-details.module').then((m) => m.OrderDetailsModule),
  },
  {
    path: 'my-profile',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/my-profile/my-profile.module').then((m) => m.MyProfileModule),
  },
  {
    path: 'my-cado',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/my-cado/my-cado.module').then((m) => m.MyCadoModule),
  },
  {
    path: `${RoutesEnum.AUTHENT}`,
    canActivate: [RedirectAuthGuardService],
    loadChildren: () => import('./core/authentification/authentification.module').then((m) => m.AuthentificationModule),
  },
  {
    path: 'token',
    component: TokenCadoComponent,
  },
  {
    path: `${RoutesEnum.SIGNUP}`,
    loadChildren: () => import('./features/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: `${RoutesEnum.LANDING}`,
    loadChildren: () => import('./features/landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'payment',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'activation',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/card-activation/card-activation.module').then((m) => m.CardActivationModule),
  },
  {
    path: 'cards-activation/:cardsActivationId',
    loadChildren: () => import('./features/cards-activation/cards-activation.module').then((m) => m.CardsActivationModule),
  },
  {
    path: 'retailers-all-cado',
    resolve: {categories: CategoriesResolver},
    loadChildren: () => import('./features/retailers/all-cado/retailers-all-cado.module').then((m) => m.RetailersAllCadoModule),
  },
  {
    path: 'retailers-my-cado',
    resolve: {categories: CategoriesResolver},
    loadChildren: () => import('./features/retailers/my-cado/retailers-my-cado.module').then((m) => m.RetailersMyCadoModule),
  },
  {
    path: 'routing',
    loadChildren: () => import('./core/routing/routing.module').then((m) => m.RoutingModule),
  },
  {
    path: `${RoutesEnum.FORGOTTEN_PASSWORD}`,
    loadChildren: () => import('./core/reinit-password/reinit-password.module').then((m) => m.ReinitPasswordModule),
  },
  {
    path: '',
    redirectTo: `${RoutesEnum.AUTHENT}`,
    pathMatch: 'full',
  },
  {
    path: 'retailer/:merchantId',
    runGuardsAndResolvers: 'always',
    resolve: {categories: CategoriesResolver, merchant: MerchantResolver},
    loadChildren: () => import('./features/retailer/retailer.module').then((m) => m.RetailerModule),
  },
  {
    path: 'geoloc',
    resolve: {categories: CategoriesResolver},
    loadChildren: () => import('./features/geoloc/geoloc.module').then((m) => m.GeolocModule),
  },
  {
    path: `${RoutesEnum.LOGOUT}`,
    loadChildren: () => import('./core/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: `${RoutesEnum.BENEFICIARIES}`,
    canActivate: [AuthGuardService],
    data: {redirectAfterLogin: `/${RoutesEnum.BENEFICIARIES}`},
    loadChildren: () => import('./features/beneficiaries/beneficiaries.module').then((m) => m.BeneficiariesModule),
  },
  {
    path: `${RoutesEnum.MY_PURCHASE_VOUCHER}`,
    canActivate: [AuthGuardService],
    data: {redirectAfterLogin: `/${RoutesEnum.MY_PURCHASE_VOUCHER}`},
    loadChildren: () => import('./features/my-purchase-voucher/my-purchase-voucher.module').then((m) => m.MyPurchaseVoucherModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
