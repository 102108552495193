import { CustomerTypeEnum } from '@shared/enums/customer-type.enum';
import { Company } from '@shared/models/company/company.model';
import { Identity } from '@shared/models/user/identity.model';
import { PaymentMandateDto } from '../payment/payment-dto.model';
import { CommissionData } from './commissions-data.model';
import { Contact } from './contact.model';

export class Customer {
  id: number;
  type: CustomerTypeEnum;
  creationDate: Date;
  company: Company;
  person: Identity;
  billingContact: Contact;
  mandate: PaymentMandateDto;
  canExceedOrderMaxAmount: boolean;
  commissionsOfLastOrder: CommissionData;
  marche?: string;
  numeroMarche?: string;
  codeServiceLastOrder?: string;
}
