import { OrderGroupCreateDto } from '@services/ws/order/order/signatures/createManagerOrder/order-group-create-dto.model';
import { PreviewOrderCreateDto } from '@services/ws/order/order/signatures/createManagerOrder/preview-order-create-dto.model';
import { ActivationContactTypeEnum } from '@shared/enums/activation-contact-type.enum';
import { OrderFormatEnum } from '@shared/enums/order-format.enum';

export class ProductItemOrderCreateDto {
  productId: number;
  preview: PreviewOrderCreateDto;
  format: OrderFormatEnum;
  eventId: number;
  useCategoryId: number;
  packagingId: number;
  isPersonalizedByBeneficiary: boolean;
  isPersonalizedVoucherValueByBeneficiary: boolean;
  activationContactType: ActivationContactTypeEnum;
  groups: Array<OrderGroupCreateDto>;
}
