import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Title } from '@shared/enums/title.enum';
import { UserStore } from '@shared/stores/user.store';
import { TitleEnum } from 'nit-angular-lib';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { UserToken } from '../models/user/user-token.model';
import { User } from '../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly userStore: UserStore
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'user/')}${environment.versionApi}`;
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/users/me
   * qui retourne les infos de l'utilisateur connecté
   */
  public findMe(): Observable<User> {
    return this.http.get<User>(this.url + '/users/me');
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/userPartnerTokens
   * qui retourne un token pour appeler Comiteo
   */
  public readToken(partnerId: string): Observable<UserToken> {
    return this.http.get<UserToken>(`${this.url}/userPartnerTokens/${partnerId}`);
  }

  /**
   * Permet de mettre à jour les données de l'utilisateur
   */
  public updateUser(): Observable<User> {
    return this.findMe().pipe(
      tap((user: User) => {
        this.userStore.setUser(user);
      })
    );
  }

  public toTitleEnum(title: Title): TitleEnum {
    if (title === Title.MR) {
      return TitleEnum.MR;
    }
    if (title === Title.MS) {
      return TitleEnum.MME;
    }
    return null;
  }

  public toTitle(title: TitleEnum): Title {
    if (title === TitleEnum.MR) {
      return Title.MR;
    }
    if (title === TitleEnum.MME) {
      return Title.MS;
    }
    return null;
  }

}
