import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@layouts/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { ErrorService } from '@services/error/error.service';
import { UrlService } from '@services/miscellaneous/url.service';
import { ToastService } from '@shared/components/toast/toast.service';
import { ProductTypeEnum } from '@shared/enums/product-type.enum';
import { UserActiveEnum } from '@shared/enums/user-active.enum';
import { UserIdentityCheckStatus } from '@shared/enums/user-identity-check-status ';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { User } from '@shared/models/user/user.model';
import { UserStore } from '@shared/stores/user.store';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  constructor(
    private readonly router: Router,
    private readonly urlService: UrlService,
    private readonly errorService: ErrorService,
    private readonly translateService: TranslateService,
    private readonly cartService: CartService,
    private readonly loaderService: LoaderService,
    private readonly toastService: ToastService,
    private readonly userStore: UserStore
  ) {
  }

  initUser(profil = '', productcode = ''): void {
    /**
     * les valeurs 'cts', 'kcu', 'kdo' viennent en minuscule ddu site Vitrine
     * donc pas utilisation de l'Enumération
     */
    const productDefined = !!productcode;
    let user: User = this.userStore.getCurrentConnectedUser();
    let coherenceProfilAppelUrl = true;
    if (user && user.id !== '-1') {
      productcode = !!productcode ? productcode : 'cts';
      // Arrivée par URL "/order?profil=pro&productcode=kcu" et déjà connecté  en INDIVIDUAL
      // normalement n'arrivera pas
      // un INDIVIDUAL ne pourra accéder à un autre product que cts
      productcode = user.type === UserTypeEnum.INDIVIDUAL ? 'cts' : productcode;
      // on vient du site vitrine et le profil n'est pas cohérent, on redirigera vers CS Vitrine
      // mais normalement, ça ne peut pas arriver
      if ((profil === 'pro' && user.type !== UserTypeEnum.PROFESSIONAL) || (profil === 'part' && user.type !== UserTypeEnum.INDIVIDUAL)) {
        coherenceProfilAppelUrl = false;
      }
      //
    } else {
      // en mode hors connecté , on crée un utilisateur Wordpress dont on utilisera que le type
      user = {
        id: '-1',
        active: UserActiveEnum.ACTIVE,
        login: 'wordpress',
        managerId: 0,
        identity: null,
        identityCheckStatus: UserIdentityCheckStatus.UNKNOWN,
      };
      if (profil === 'pro') {
        user.type = UserTypeEnum.PROFESSIONAL;
      } else if (profil === 'part') {
        user.type = UserTypeEnum.INDIVIDUAL;
      }
      this.userStore.setUser(user);
    }

    const productType = this.calculateProductType(productcode, user);

    if (!user.type || !productType) {
      this.router.navigateByUrl('/authent');
      throw new Error(`Accès avec les paramètres [${profil}, ${productcode}] non autorisé en mode déconnecté`);
    }

    this.cartService.setCartProduct(productType, productDefined, user);
    if (!this.cartService.verifyCart(user) || !coherenceProfilAppelUrl) {
      if (user.type === UserTypeEnum.INDIVIDUAL) {
        this.toastService.showInfinitToastWithTitle(this.translateService.instant('LOGIN.ERROR.REDIRECT_PART'), 'error');
      }
      if (user.type === UserTypeEnum.PROFESSIONAL) {
        this.toastService.showInfinitToastWithTitle(this.translateService.instant('LOGIN.ERROR.REDIRECT_PRO'), 'error');
      }
      this.urlService.redirectToSiteVitrine('produits-cado', true);

      throw new Error(`REDIRECT`);
    }
  }

  calculateProductType(productCode: string, user: User): ProductTypeEnum {
    let productType: ProductTypeEnum = null;

    if (user.type !== UserTypeEnum.INDIVIDUAL && productCode === 'kdo') {
      productType = ProductTypeEnum.KDO;
    } else if (user.type !== UserTypeEnum.INDIVIDUAL && productCode === 'kcu') {
      productType = ProductTypeEnum.KCU;
    } else if (productCode === 'cts') {
      productType = ProductTypeEnum.CTS;
    }

    return productType;
  }

}
