import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/components/toast/toast.service';
import { CardIdDto } from '@shared/models/api/card/card-id-dto.model';
import { UserCardDto } from '@shared/models/api/card/user-card-dto.model';
import { ErrorCodeApiEnum } from '@shared/models/api/enums/error-code-api.enum';
import { PurchaseVoucherId } from '@shared/models/api/purchase-voucher/purchase-voucher-id.model';
import { PurchaseVoucherReservationCreate } from '@shared/models/api/purchase-voucher/purchase-voucher-reservation-create.model';
import { PurchaseVoucherReservationId } from '@shared/models/api/purchase-voucher/purchase-voucher-reservation-id.model';
import { PurchaseVouchers } from '@shared/models/api/purchase-voucher/purchase-vouchers.model';
import { StrongAuthentication } from '@shared/models/api/strong-authentication/strong-authentication.model';
import { AmountDto } from '@shared/models/api/transverse/amount-dto.model';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorService } from './error/error.service';
import { FilesService } from './miscellaneous/files.service';
import { PurchaseVoucherControllerService } from './ws/purchase-voucher/purchase-voucher-controller.service';
import { ReadIndividualPurchaseVoucherInput } from './ws/purchase-voucher/signatures/read-individual-purchase-voucher-input.model';
import { CheckStrongAuthenticationRequest } from './ws/strong-authentication/signatures/check-strong-authentication-request.model';
import { StrongAuthenticationCreate } from './ws/strong-authentication/signatures/strong-authentication-create.model';
import { StrongAuthenticationControllerService } from './ws/strong-authentication/strong-authentication-controller.service';
import { StrongAuthentOriginEnum } from '@shared/models/api/enums/strong-authent-origin.enum';

@Injectable({
  providedIn: 'root',
})
export class PurchaseVoucherService {

  @Output() onConfirmVoucherAsked: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private readonly purchaseVoucherController: PurchaseVoucherControllerService,
    private readonly strongAuthenticationController: StrongAuthenticationControllerService,
    private readonly fileService: FilesService,
    private readonly toastService: ToastService,
    private readonly errorService: ErrorService,
    private readonly translateService: TranslateService
  ) {
  }

  readPurchaseVouchers$(input: ReadIndividualPurchaseVoucherInput): Observable<PurchaseVouchers> {
    return this.purchaseVoucherController.readIndividualPurchaseVouchers(input);
  }

  showPurchaseVoucherDocument$(purchaseVoucherId: number): Observable<boolean> {
    return this.purchaseVoucherController.readPurchaseVoucherDocument(purchaseVoucherId).pipe(
      tap((data) => {
        const blob = new Blob([data], {type: 'application/pdf'});

        const downloadURL = (window.URL || URL || window.webkitURL).createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Bon_Achat_${purchaseVoucherId}.pdf`;
        link.click();
      }),
      map(() => true)
    );
  }

  requirePurchaseVoucher$(
    voucherAmount: number,
    cards: UserCardDto[],
    merchantId: string,
    individualId: number | string
  ): Observable<[StrongAuthentication, PurchaseVoucherReservationId]> {
    return this.initPurchaseVoucher$(voucherAmount, cards, merchantId, individualId).pipe(
      switchMap((purchaseVoucherReservationId: PurchaseVoucherReservationId) => {
        // Récupération du requestId pour l'authentification forte
        return this.getStrongAuthenticationRequestId$(individualId, purchaseVoucherReservationId.id).pipe(
          switchMap((requestId: string) => {
            return this.createStrongAuthentication$(requestId, StrongAuthentOriginEnum.VOUCHER);
          }),
          withLatestFrom(of(purchaseVoucherReservationId))
        );
      })
    );
  }

  createStrongAuthentication$(requestId: string, strongAuthentOrigin: StrongAuthentOriginEnum): Observable<StrongAuthentication> {
    const strongAuthenticationCreate = new StrongAuthenticationCreate();
    strongAuthenticationCreate.requestId = requestId;
    return this.strongAuthenticationController.createStrongAuthentication$(strongAuthenticationCreate,
          strongAuthentOrigin);
  }

  checkStrongAuthentication$(strongAuthenticationId: number, code: string, isAuthent?: boolean): Observable<string> {
    const checkStrongAuthenticationRequest = new CheckStrongAuthenticationRequest();
    checkStrongAuthenticationRequest.code = code;
    return this.strongAuthenticationController
      .checkStrongAuthentication$(strongAuthenticationId, checkStrongAuthenticationRequest, isAuthent)
      .pipe(map((strongAuthenticationResponse) => strongAuthenticationResponse.id));
  }

  confirmPurchaseVoucher$(
    individualId: number | string,
    purchaseVoucherReservationId: number,
    strongToken: string
  ): Observable<PurchaseVoucherId> {
    return this.purchaseVoucherController.confirmPurchaseVoucher(individualId, purchaseVoucherReservationId, strongToken);
  }

  setIsConfirmVoucherAsked(isConfirmVoucherAsked: boolean): void {
    this.onConfirmVoucherAsked.emit(isConfirmVoucherAsked);
  }

  getIsConfirmVoucherAsked(): EventEmitter<boolean> {
    return this.onConfirmVoucherAsked;
  }

  private initPurchaseVoucher$(
    voucherAmount: number,
    cards: UserCardDto[],
    merchantId: string,
    individualId: number | string
  ): Observable<PurchaseVoucherReservationId> {
    const signature = new PurchaseVoucherReservationCreate();
    signature.amount = new AmountDto();
    signature.amount.amount = voucherAmount;
    signature.amount.currency = 'EUR';
    signature.cards = cards.map((card) => this.getCardId(card));
    signature.merchantId = merchantId;

    return this.purchaseVoucherController.initPurchaseVoucher(signature, individualId).pipe(
      catchError((errors) => {
        const errorApi = this.errorService.getFirstErrorApi(errors);
        if (errorApi.code === ErrorCodeApiEnum.CELL_PHONE_RECENTLY_UPDATED_112) {
          this.toastService.showInfinitToastWithTitle(this.translateService.instant('RETAILER.PURCHASE_VOUCHER.ERROR_PHONE_UPDATE_TOO_RECENT'), 'error');
        } else {
          this.handleInitPurchaseVoucherErrors(errors);
        }
        // Erreurs déjà traitées, on retourne EMPTY
        return EMPTY;
      })
    );
  }

  private getStrongAuthenticationRequestId$(individualId: number | string, purchaseVoucherReservationId: number): Observable<string> {
    // @ts-ignore
    return this.purchaseVoucherController.confirmPurchaseVoucher(individualId, purchaseVoucherReservationId).pipe(
      tap(() => {
        // Ne devrait jamais se produire, car sans jeton d'authentification cette API doit renvoyer une erreur
        return of(null);
      }),
      catchError(error => {
        const errorApi = this.errorService.getFirstErrorApi(error);
        if (errorApi.code === ErrorCodeApiEnum.STRONG_AUTHENTIFICATION_NEEDED_084) {
          return of(errorApi.additionalInformation.requestId);
        } else {
          throw error;
        }
      })
    );
  }

  private getCardId(card: UserCardDto): CardIdDto {
    const cardId = new CardIdDto();
    cardId.id = card.id;
    return cardId;
  }

  private handleInitPurchaseVoucherErrors(errors: HttpErrorResponse): void {
    if (errors.status === 400) {
      let errorText = '';

      if (!errors.error) {
        this.toastService.showInfinitToastWithTitle(this.translateService.instant('USUAL.TECHNICAL_ERROR'), 'error');
      }

      const errorsList = errors.error.errors;
      const prefix = errorsList.length > 1 ? '- ' : '';
      const suffix = errorsList.length > 1 ? '\n' : '';

      errorsList.forEach((error) => {
        errorText = errorText.concat(prefix + error.message + suffix);
      });
      this.toastService.showInfinitToastWithTitle(errorText, 'error');
    } else {
      this.errorService.errorHandling(errors);
    }
  }
}
