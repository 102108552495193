import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { CartService } from '@services/cart.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { ManagedCustomer } from '@shared/models/user/managed-customer.model';
import { User } from '@shared/models/user/user.model';
import { CustomerStore } from '@shared/stores/customer.store';

@Component({
  selector: 'cado-select-customer-modal',
  templateUrl: './select-customer-modal.component.html',
})
export class SelectCustomerModalComponent implements OnInit {
  @Input() user: User;

  constructor(
    private readonly router: Router,
    private readonly menuService: MenuDesktopService,
    private readonly modalService: ModalCadoService,
    private readonly cartService: CartService,
    private readonly customerStore: CustomerStore
  ) {
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalService.close('selectCustomerModalId');
  }

  onCustomerClick(customer: ManagedCustomer): void {
    this.customerStore.setCustomerId(customer?.id);
    this.menuService.showDropdownWithUser(customer?.id, this.user.customers);
    this.menuService.showUserConnectedWithName(`${this.user.identity.firstName} ${this.user.identity.lastName}`);

    // On récupère le panier en base
    this.cartService.loadCartFromServer$(this.user.managerId, customer.id, this.user);

    this.closeModal();

    // On force le refresh au cas où on serait déjà sur la page my-orders
    this.redirectTo('//my-orders');
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([uri]));
  }
}
