import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cado-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() linkLabel: any;
  @Input() internalLinkUrl?: any;
  @Input() externalLinkUrl?: any;
  @Input() iconToAddBefore: string;
  @Input() iconToAddAfter: string;
  @Input() disabled = false;
  @Input() newTab = false;
  @Output() submitted = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  submit(): void {
    this.submitted.emit();
  }

}
