<header id="menu-mobile">
    <div class="row ligne-menu">

        <div class="col-md-3 col-3 col-sm-3">
            <div class="icon">
                <a *ngIf="backUrl$ | async as backUrl" [routerLink]="[backUrl]" class="back_btn">
                    <i class="icon-arrow-left"></i>
                </a>
            </div>
        </div>

        <div class="col-md-6 col-6 col-sm-6">

            <div class="logo">

                <div (click)="redirectHome()" *ngIf="!isParcoursDigital">
                    <img alt="Cadostore" id="logo_store" src="assets/images/cado-store-logo-mobile.png">
                </div>
                <div *ngIf="isParcoursDigital">
                    <img alt="Cadostore" id="logo_store" src="assets/images/cado-store-logo-mobile.png">
                </div>

            </div>

        </div>

        <div *ngIf="showMenuButtons$ | async" class="col-md-3 col-3 col-sm-3 d-flex justify-content-end">
            <div (click)="onMyProfileClick()" *ngIf="pageName !== PageNameEnum.LANDING_PAGE || !isParcoursDigital"
                 [ngClass]="!user || user.type === UserTypeEnum.PROFESSIONAL ? 'icon-profile' : ''"
                 class="icon-profil-fill icon-cart"></div>
            <div (click)="goToOrder()" *ngIf="!user || user.type === UserTypeEnum.PROFESSIONAL" class="icon-bag-stroke icon-cart">
            </div>

            <div *ngIf="(!user || user.type === UserTypeEnum.PROFESSIONAL) && (name && cartItemsCount > 0 && cartItemsCount < 10)"
                 class="cart-count cart-count-small d-flex  align-items-center  justify-content-center">{{cartItemsCount}}
            </div>

            <div *ngIf="(!user || user.type === UserTypeEnum.PROFESSIONAL) && (name && cartItemsCount >= 10 && cartItemsCount < 100)"
                 class="cart-count cart-count-medium d-flex  align-items-center  justify-content-center">{{cartItemsCount}}
            </div>

            <div *ngIf="(!user || user.type === UserTypeEnum.PROFESSIONAL) && (name && cartItemsCount >= 100)"
                 class="cart-count cart-count-large d-flex  align-items-center  justify-content-center">{{cartItemsCount}}
            </div>
        </div>
    </div>
</header>
