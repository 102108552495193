<ng-container *ngIf="redirection$ | async"></ng-container>
<cado-modal id="popupCustomerSelection">
    <cado-modal-header (modalClose)="closePopupCustomerSelection()" [titleModal]="'TOKEN.SELECT_CUSTOMER' | translate"></cado-modal-header>

    <div *ngIf="user" class="div-customers">
        <div *ngFor="let customer of user.customers">
            <cado-link-two-rows (submitted)="onCustomerClick(customer); closePopupCustomerSelection()" [row1]="customer.companyName"
                                [row2]="'CUSTOMER_TYPE_ENUM.'+customer.type | translate"></cado-link-two-rows>
        </div>
    </div>
</cado-modal>
