export enum PageNameEnum {
  MY_ORDERS = 'MY_ORDERS',
  MY_CADO = 'MY_CADO',
  MY_REPORTS = 'MY_REPORTS',
  MY_PROFILE = 'MY_PROFILE',
  ALL_CADO_RETAILERS = 'ALL_CADO_RETAILERS',
  MY_CADO_RETAILERS = 'MY_CADO_RETAILERS',
  RETAILER = 'RETAILER',
  CARD_ACTIVATION = 'CARD_ACTIVATION',
  CARDS_ACTIVATION = 'CARDS_ACTIVATION',
  PAYMENT = 'PAYMENT',
  GEOLOC = 'GEOLOC',
  ORDER = 'ORDER',
  BENEFICIARIES = 'BENEFICIARIES',
  MY_PURCHASE_VOUCHER = 'MY_PURCHASE_VOUCHER',
  MY_PURCHASE_VOUCHER_SUCCESS = 'MY_PURCHASE_VOUCHER_SUCCESS',
  SIGNUP = 'SIGNUP',
  AUTHENT = 'AUTHENT',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  LANDING_PAGE = 'LANDING_PAGE'
}
