import { Injectable } from '@angular/core';
import { CardActivationRequest } from '@services/ws/card/signatures/createActivation/card-activation-request-dto.model';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class CardActivationStore {
  private readonly cardActivationRequest = new BehaviorSubject<CardActivationRequest>(
    JSON.parse(localStorage.getItem(LOCALSTORAGE.CARD_ACTIVATION_REQUEST))
  );

  private readonly cardActivationRequest$: Observable<CardActivationRequest> = this.cardActivationRequest.asObservable();

  constructor() {
  }

  public setCardActivationRequest(cardActivationRequest: CardActivationRequest): void {
    localStorage.setItem(LOCALSTORAGE.CARD_ACTIVATION_REQUEST, JSON.stringify(cardActivationRequest));
    this.cardActivationRequest.next(cardActivationRequest);
  }

  public getCardActivationRequest$(): Observable<CardActivationRequest> {
    return this.cardActivationRequest$;
  }

  public resetCardActivationRequest(): void {
    localStorage.removeItem(LOCALSTORAGE.CARD_ACTIVATION_REQUEST);

    this.cardActivationRequest.next(null);
  }

}
