export const ITEM_SCOPE = 'scopes';
export const ITEM_TOKEN = 'token';
export const ITEM_EXPIRES = 'expires';
export const ITEM_USER = 'user';
export const ITEM_DIGITAL_COURSE = 'digitalCourse';
export const TARGET = 'target';
export const ID_CURRENT_CUSTOMER = 'idCurrentCustomer';
export const FROM_BIMPLI = 'fromBimpli';
export const PARCOURS_DIGITAL = 'parcoursDigital';
export const IS_SUDO = 'isSudo';
