import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@layouts/loader/loader.service';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { ErrorService } from '@services/error/error.service';
import { ManagerService } from '@services/manager.service';
import { PurchaseVoucherService } from '@services/purchase-voucher.service';
import { ToastService } from '@shared/components/toast/toast.service';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { CustomerStore } from '@shared/stores/customer.store';
import { ManagerStore } from '@shared/stores/manager.store';
import { PageStore } from '@shared/stores/page.store';
import { UserStore } from '@shared/stores/user.store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, delay, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';
import { GoogleTagManagerService } from '@services/google-tag-manager.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'cado-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  mainComponentSubscription: Subscription = new Subscription();
  showToast = false;
  refresh$: Observable<boolean>;
  isSousMenuVisible: boolean;
  isRetailersSousMenuVisible = false;
  showMobileFooter$: Observable<boolean>;
  isPro = false;
  isPhoneVerified = false;
  isFromBimpli = false;
  isParcoursDigital = false;


  constructor(
    private readonly toastService: ToastService,
    private readonly menuService: MenuDesktopService,
    private readonly pageStore: PageStore,
    private readonly userStore: UserStore,
    private readonly customerStore: CustomerStore,
    private readonly purchaseVoucherService: PurchaseVoucherService,
    private readonly errorService: ErrorService,
    private readonly loaderService: LoaderService,
    private readonly managerService: ManagerService,
    private readonly managerStore: ManagerStore,
    private readonly route: ActivatedRoute,
    private readonly modalService: ModalCadoService,
    private readonly googleTagManagerService: GoogleTagManagerService
  ) {
  }

  ngOnInit(): void {
    this.initiateGoogleTagManager();


    this.mainComponentSubscription.add(this.pageStore.isFromBimpli$
      .pipe(
        tap((isFromBimpli) => {
          this.isFromBimpli = isFromBimpli;
        }),
        delay(0)
      ).subscribe());

    this.mainComponentSubscription.add(this.pageStore.isParcoursDigital$
      .pipe(
        tap((isParcoursDigital) => {
          this.isParcoursDigital = isParcoursDigital;
        }),
        delay(0)
      ).subscribe());

    const sousMenuRefresh$ = combineLatest([
      this.menuService.nameValue$,
      this.pageStore.currentPage$,
      this.menuService.showRetailersMenu$,
    ]).pipe(
      tap(([name, page, retailerMenu]) => {
        this.isSousMenuVisible =
          retailerMenu ||
          (name !== '' &&
            page !== PageNameEnum.PAYMENT &&
            page !== PageNameEnum.CARD_ACTIVATION &&
            page !== PageNameEnum.CARDS_ACTIVATION &&
            page !== PageNameEnum.RETAILER &&
            page !== PageNameEnum.GEOLOC &&
            page !== PageNameEnum.ORDER &&
            page !== PageNameEnum.BENEFICIARIES &&
            page !== PageNameEnum.MY_PURCHASE_VOUCHER_SUCCESS &&
            page !== PageNameEnum.UPDATE_PASSWORD &&
            page !== PageNameEnum.LANDING_PAGE
          );
      }),
      // Ajout d'un délai car la page est initialisée à "MyOrders", mais si on est sur un autre composant ça change avant la fin de l'init
      // et paf, erreur ExpressionChangedAfterItHasBeenCheckedError
      delay(0)
    );
    const customerRefresh$ = this.customerStore.customerId$.pipe(
      distinctUntilChanged(),
      switchMap(() => {
        return this.customerStore.loadCustomer();
      })
    );
    const userRefresh$ = this.userStore.userConnected$().pipe(
      tap((user) => {
        this.isPro = user?.type === UserTypeEnum.PROFESSIONAL;
      }),
      switchMap((user) => {
        if (!!user) {
          // Appel de l'api ReadManager
          this.loaderService.startLoading();
          return this.managerService.readManager(user.managerId).pipe(
            catchError(() => {
              return of(null as ManagerDto);
            }),
            finalize(() => this.loaderService.stopLoading())
          );
        } else {
          return of(null as ManagerDto);
        }
      }),
      tap((manager) => {
        this.managerStore.setManager(manager);
      })
    );

    this.refresh$ = combineLatest([sousMenuRefresh$, customerRefresh$, userRefresh$]).pipe(map(() => true));

    this.mainComponentSubscription.add(
      this.toastService.showStatus.subscribe((value) => {
        this.showToast = value;
      })
    );

    this.mainComponentSubscription.add(
      this.menuService.hasRetailersMenu.subscribe((value) => {
        this.isRetailersSousMenuVisible = value;
      })
    );

    this.showMobileFooter$ = this.menuService.showMobileFooter$();

    this.managerStore.manager$.pipe(
      tap(manager => {
        this.isPhoneVerified = manager?.isCellPhoneNumberChecked;
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.mainComponentSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    const cookieEnabled = localStorage.getItem(LOCALSTORAGE.COOKIE_ENABLED);

    if (!cookieEnabled) {
      this.modalService.open('idGtmConsentModal');
    } else {
      this.googleTagManagerService.changeConsent(cookieEnabled === 'true');
    }
  }

  private initiateGoogleTagManager() {
    /* Google Tag Manager */
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.gtmId}');
    // Define dataLayer and the gtag function.
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });
        gtag('js', new Date());`;
    document.head.appendChild(script);
    /* End Google Tag Manager */

    /* Google Tag Manager (noscript) */
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
    /* End Google Tag Manager (noscript) */
  }
}
