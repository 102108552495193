import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cado-modal-header',
  templateUrl: 'header-modal-cado.component.html',
  styleUrls: ['./header-modal-cado.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderModalCadoComponent implements OnInit {

  @Input() titleModal: string;
  @Input() isClosable: boolean;
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalClose.emit();
  }

}
