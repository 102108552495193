import { Injectable } from '@angular/core';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { User } from '@shared/models/user/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// J'ai créé ce store au lieu de mettre l'observable dans le service afin de séparer le stockage des infos et les processus métier
// car cela générait des dépendances cycliques

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  user$: Observable<User>;
  private readonly userSubject: BehaviorSubject<User>;

  constructor() {
    const userConnecte = JSON.parse(sessionStorage.getItem(SESSIONSTORAGE.ITEM_USER));
    this.userSubject = new BehaviorSubject<User>(userConnecte);
    this.user$ = this.userSubject.asObservable();
  }

  setUser(user: User): void {
    sessionStorage.setItem(SESSIONSTORAGE.ITEM_USER, JSON.stringify(user));
    this.userSubject.next(user);
  }

  userNotNull$(): Observable<User> {
    return this.user$.pipe(filter((u) => !!u));
  }

  userConnected$(): Observable<User> {
    return this.user$.pipe(
      map((user) => {
        return user && user.id === '-1' ? null : user;
      })
    );
  }

  disconnect() {
    sessionStorage.removeItem(SESSIONSTORAGE.ITEM_USER);
    this.userSubject.next(null);
  }

  // Attention, cette méthode renvoie le user connecté, mais il vaut mieux utiliser directement l'observable
  getCurrentConnectedUser(): User {
    const user = this.userSubject.value;
    return user && user.id === '-1' ? null : user;
  }
}
