import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from './toast.service';

@Component({
  selector: 'cado-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  show = false;
  title = '';
  type = 'success';
  showSubscription: Subscription;
  titleSubscription: Subscription;
  typeSubscription: Subscription;
  classSubscription: Subscription;

  constructor(
    private readonly toastService: ToastService
  ) {
  }

  private _classToast = 'toast';

  get classToast() {
    return this._classToast;
  }

  set classToast(value) {
    this._classToast = value;
  }

  ngOnInit(): void {
    this.showSubscription = this.toastService.showStatus.subscribe((value) => {
      this.show = value;
    });
    this.titleSubscription = this.toastService.showTitle.subscribe((value) => {
      this.title = value;
    });
    this.typeSubscription = this.toastService.showType.subscribe((value) => {
      this.type = value;
    });
    this.classSubscription = this.toastService.showCss.subscribe((value) => {
      this.classToast = value;
    });
  }

  ngOnDestroy(): void {
    this.showSubscription.unsubscribe();
    this.titleSubscription.unsubscribe();
  }

  /**
   * Permet de fermet le toast
   */
  closeToast(): void {
    this.toastService.hideToast();
  }
}
