import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OrderTypeEnum } from '@shared/enums/orders-type.enum';
import { ProductDto } from '@shared/models/api/product/product-dto.model';
import { PaymentCarriersDto, PaymentCarriersSignature } from '@shared/models/payment/payment-dto.model';
import { Event, Events } from '@shared/models/product/event.model';
import { Packagings } from '@shared/models/product/packaging.model';
import { Previews } from '@shared/models/product/preview.model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  readonly urlProduct: string;
  readonly urlEvents: string;
  readonly urlPackagings: string;
  readonly urlCarriers: string;

  constructor(
    private readonly http: HttpClient
  ) {
    this.urlProduct = `/CADO/${(environment.localApi ? '' : 'product/')}${environment.versionApi}/products/`;
    this.urlEvents = `/CADO/${(environment.localApi ? '' : 'product/')}${environment.versionApi}/events`;
    this.urlPackagings = `/CADO/${(environment.localApi ? '' : 'product/')}${environment.versionApi}/packagings`;
    this.urlCarriers = `/CADO/${(environment.localApi ? '' : 'product/')}${environment.versionApi}/carriers`;
  }

  public readProduct(productId: number, customerType?: string): Observable<ProductDto> {
    const currentUrl = `${this.urlProduct}${productId}`;

    let params = new HttpParams();

    if (!!customerType) {
      params = params.set('customerType', customerType);
    }

    return this.http.get<ProductDto>(currentUrl, {params});
  }

  /**
   * Fonction qui permet de faire appel à l'API /products/{productId}/previews
   * qui retourne les previews des produits
   */
  public readPreviews(productId: number, customerType?: string, event?: Event): Observable<Previews> {
    let url = `${this.urlProduct}${productId}/previews`;
    if (customerType != null || event != null) {
      url += '?';
      if (customerType != null) {
        url += '&customerType=' + customerType;
      }
      if (event != null) {
        url += '&eventId=' + event.id;
      }
    }

    return this.http.get<Previews>(url);
  }

  /**
   * Fonction qui permet de faire appel à l'API /events
   * qui retourne les evenements des produits
   */
  public readEvents(productId?: number): Observable<Events> {
    let url = this.urlEvents;
    if (productId != null) {
      url += '?productId=' + productId;
    }
    return this.http.get<Events>(url);
  }

  /**
   * Fonction qui permet de faire appel à l'API /packagings
   * qui retourne les packagings des produits
   */
  public readPackagings(customerType?: string, orderType?: OrderTypeEnum, productId?: number): Observable<Packagings> {
    let url = this.urlPackagings;
    if (customerType != null || orderType != null || productId != null) {
      url += '?';
      if (customerType != null) {
        url += '&customerType=' + customerType;
      }
      if (orderType != null) {
        url += '&orderType=' + orderType;
      }
      if (productId != null) {
        url += '&productId=' + productId;
      }
    }
    return this.http.get<Packagings>(url);
  }

  public readCarriers(signature: PaymentCarriersSignature): Observable<PaymentCarriersDto> {
    let url = this.urlCarriers;

    if (
      signature &&
      (signature.familyProductId ||
        signature.customerType ||
        signature.orderType ||
        signature.deliveryType ||
        signature.packagingId ||
        signature.isOverseas != null)
    ) {
      url += '?';

      if (signature.familyProductId) {
        url += '&familyProductId=' + signature.familyProductId;
      }

      if (signature.customerType) {
        url += '&customerType=' + signature.customerType;
      }

      if (signature.orderType) {
        url += '&orderType=' + signature.orderType;
      }

      if (signature.deliveryType) {
        url += '&deliveryType=' + signature.deliveryType;
      }

      if (signature.packagingId) {
        url += '&packagingId=' + signature.packagingId;
      }

      if (signature.isOverseas != null) {
        url += '&isOverseas=' + signature.isOverseas;
      }
    }

    return this.http.get<PaymentCarriersDto>(url);
  }
}
