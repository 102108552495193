<cado-modal [classSurcharge]="'cado-modal-smaller'" id="idModalConfirmDisconnect">

    <cado-modal-header (modalClose)="closeModal()"
                       [isClosable]="true"></cado-modal-header>
    <div class="container  text-center">
        <img src="assets/images/icon-bimpli-black.svg" alt="Icône Bimpli"><br>
        <div class="col-12 mt-4 title">{{"LOGOUT.MODAL.TITLE" | translate}}</div>

        <!-- Boutons desktop -->
        <div class="d-flex align-items-center justify-content-center mt-5 btn-gap-wrapper ">
            <div class="d-none d-sm-block">
                <cado-button-secondary (click)="closeModal()"
                                       [buttonLabel]="'LOGOUT.MODAL.CANCEL' | translate"></cado-button-secondary>
            </div>
            <div class="d-none d-sm-block">
                <cado-button-primary (click)="onValidate()"
                                     [buttonLabel]="'LOGOUT.MODAL.CONFIRM' | translate"></cado-button-primary>
            </div>
        </div>

        <!-- Boutons mobile -->
        <div class="row">
            <div class="col-12 d-sm-none">
                <cado-button-primary (click)="onValidate()"
                                     [buttonLabel]="'LOGOUT.MODAL.CONFIRM' | translate"
                                     [classSurcharge]="'fill-space'"></cado-button-primary>
            </div>
            <div class="col-12 d-sm-none mt-2">
                <cado-button-secondary (click)="closeModal()"
                                       [buttonLabel]="'LOGOUT.MODAL.CANCEL' | translate"
                                       [classSurcharge]="'fill-space'"></cado-button-secondary>
            </div>
        </div>
    </div>
</cado-modal>
