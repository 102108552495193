import { AddressDto } from '@shared/models/api/transverse/address-dto.model';
import { TitleEnum } from 'nit-angular-lib';

export class OrderBeneficiaryCreateDto {
  externalId: string;
  title: TitleEnum;
  firstName: string;
  lastName: string;
  email: string;
  address: AddressDto;
  voucherValue: number;
}
