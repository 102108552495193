import { Injectable } from '@angular/core';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectionStore {
  redirection$: Observable<string>;
  private readonly redirectionSubject: BehaviorSubject<string>;

  constructor() {
    // On récupère et on enregistre la redirection dans le local storage, car lors de la création d'un nouveau compte,
    // l'utilisateur reçoit un mail d'activation, qui ouvre un nouvel onglet, et donc une nouvelle session.
    // Les données du session storage ne sont pas transmises à ce nouvel onglet, vu qu'il s'agit d'une nouvelle session
    // alors que les données du local storage le sont, car il s'agit du même site.
    const redirection: string = localStorage.getItem(LOCALSTORAGE.REDIRECTION);
    this.redirectionSubject = new BehaviorSubject<string>(redirection);
    this.redirection$ = this.redirectionSubject.asObservable();
  }

  // gestion de la redirection à la connexion
  setRedirection(redirection: string) {
    localStorage.setItem(LOCALSTORAGE.REDIRECTION, redirection);
    this.redirectionSubject.next(redirection);
  }

  resetRedirection() {
    localStorage.removeItem(LOCALSTORAGE.REDIRECTION);
    this.redirectionSubject.next(null);
  }
}
