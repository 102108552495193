import { Title } from '@shared/enums/title.enum';

export class CommissionData {

  establishmentCode: string;

  agency: string;

  externalId: string;

  title: Title;

  firstName: string;

  lastName: string;

  vendorEstablishmentCode: string;

  vendorAgency: string;

  vendorExternalId: string;

  vendorCode: string;

  vendorTitle: Title;

  vendorFirstName: string;

  vendorLastName: string;
}
