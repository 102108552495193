import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@layouts/loader/loader.service';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { OrderControllerService } from '@services/ws/order/order/order-controller.service';
import { ReadManagerOrderCriteriaDto } from '@services/ws/order/order/signatures/readManagerOrder/read-manager-order-criteria-dto.model';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { RoutesEnum } from '@shared/models/common/routes.enum';
import { User } from '@shared/models/user/user.model';
import { PageStore } from '@shared/stores/page.store';
import { UserStore } from '@shared/stores/user.store';
import { Observable, Subscription } from 'rxjs';
import { delay, finalize, map, tap } from 'rxjs/operators';
import { PaymentService } from '../../features/payment/payment.service';
import { PageNameEnum } from './page-name.enum';

@Component({
  selector: 'cado-sous-menu-desktop',
  templateUrl: './sous-menu-desktop.component.html',
  styleUrls: ['./sous-menu-desktop.component.scss'],
})
export class SousMenuDesktopComponent implements OnInit, OnDestroy {
  @Input() isRetailers: boolean;

  refresh$: Observable<boolean>;

  showPartMyOrdersMenu: boolean;
  user: User;
  pageName: PageNameEnum;

  PageNameEnum = PageNameEnum;
  UserTypeEnum = UserTypeEnum;

  private readonly subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly menuService: MenuDesktopService,
    private readonly loaderService: LoaderService,
    private readonly paymentService: PaymentService,
    private readonly orderControllerService: OrderControllerService,
    private readonly userStore: UserStore,
    private readonly pageStore: PageStore
  ) {
  }

  ngOnInit(): void {
    const pageName$ = this.pageStore.currentPage$.pipe(
      // Ajout d'un délai pour éviter ExpressionChangedAfterItHasBeenCheckedError
      delay(0),
      tap((pageName: PageNameEnum) => (this.pageName = pageName))
    );

    this.refresh$ = pageName$.pipe(map(() => true));

    const userSubscription = this.menuService.nameValue.subscribe((value) => {
      this.user = this.userStore.getCurrentConnectedUser();
    });

    this.subscription.add(userSubscription);

    this.user = this.userStore.getCurrentConnectedUser();

    if (this.user?.type === UserTypeEnum.INDIVIDUAL) {
      // Appel de l'api ReadManagerOrdersV1 pour voir s'il y a une commande.
      const criteria = new ReadManagerOrderCriteriaDto();
      criteria.customerId = this.user.customers[0].id;
      criteria.managerId = this.user.managerId;
      criteria.pageNumber = 1;
      criteria.pageSize = 1;

      this.loaderService.startLoading();

      const readManagerOrderSubscription = this.orderControllerService
        .readManagerOrders(criteria)
        .pipe(
          tap((orders) => {
            this.showPartMyOrdersMenu = orders.items && orders.items.length > 0;
          }),
          finalize(() => this.loaderService.stopLoading())
        )
        .subscribe();

      this.subscription.add(readManagerOrderSubscription);

      // Souscription au service qui indique qu'une commande a été créée
      const orderCreatedSubscription = this.paymentService.orderCreatedObservable$
        .pipe(tap((hasOrder) => (this.showPartMyOrdersMenu = hasOrder)))
        .subscribe();

      this.subscription.add(orderCreatedSubscription);
    }
  }

  onMyCadoClick(): void {
    this.router.navigateByUrl('/my-cado');
  }

  onMyProfileClick(): void {
    this.router.navigateByUrl('/my-profile');
  }

  onMyOrdersClick(): void {
    this.router.navigateByUrl('/my-orders');
  }

  onAllCadoRetailersClick(): void {
    this.router.navigateByUrl('/retailers-all-cado');
  }

  onMyCadoRetailersClick(): void {
    this.router.navigateByUrl('/retailers-my-cado');
  }

  goToMyPurchaseVoucher(): void {
    this.router.navigateByUrl(RoutesEnum.MY_PURCHASE_VOUCHER);
  }

  isPro() {
    if (this.user) {
      return this.user.type === UserTypeEnum.PROFESSIONAL;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scroll(id) {
    document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
  }
}
