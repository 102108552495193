import { Injectable } from '@angular/core';
import { ProductService } from '@services/product.service';
import { OrderFormatEnum } from '@shared/enums/order-format.enum';
import { OrderTypeEnum } from '@shared/enums/orders-type.enum';
import { ProductTypeEnum } from '@shared/enums/product-type.enum';
import { CartProductItemDto } from '@shared/models/api/order/cart/cart-product-item-dto.model';
import { CartSummaryDto } from '@shared/models/api/order/cart/cart-summary-dto.model';
import { OrderGroupDto } from '@shared/models/api/order/cart/order-group-dto.model';
import { OrderItemDetail } from '@shared/models/create-order/order-item-detail.model';
import { OrderItem } from '@shared/models/create-order/order-item.model';
import { Order } from '@shared/models/create-order/order.model';
import { Event, Events } from '@shared/models/product/event.model';
import { Packaging, Packagings } from '@shared/models/product/packaging.model';
import { Preview, Previews } from '@shared/models/product/preview.model';
import { User } from '@shared/models/user/user.model';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartMappingService {
  constructor(
    private readonly productService: ProductService
  ) {
  }

  cartSummaryDtoToOrder$(cartSummary: CartSummaryDto, user: User): Observable<Order> {
    const firstProduct: CartProductItemDto = cartSummary.productItems[0];
    return combineLatest([
      // Appel de l'api readEvents
      this.productService.readEvents(firstProduct.productId),
      // Appel de l'api readPackagings
      this.productService.readPackagings(user.type, OrderTypeEnum.SPECIALIZED, firstProduct.productId),
    ]).pipe(
      switchMap(([events, packagings]) =>
        // Appel de l'api readPreviews
        // On lui passe le premier événement trouvé, a priori Hors ACOSS
        this.productService
          .readPreviews(firstProduct.productId, user.type, events.items[0])
          .pipe(map((preview) => this.getOrder(cartSummary, firstProduct.productId, events, packagings, preview)))
      )
    );
  }

  private getOrder(
    cartSummary: CartSummaryDto,
    productType: ProductTypeEnum,
    events: Events,
    packagings: Packagings,
    preview: Previews
  ): Order {
    const order = new Order();
    order.id = cartSummary.id;

    order.format = cartSummary.format;
    order.productType = productType;
    order.orderItems = new Array<OrderItem>();

    cartSummary.productItems.forEach((productItem: CartProductItemDto) => {
      const item: OrderItem = this.getOrderItem(productItem, events, packagings, preview, cartSummary.format);
      order.orderItems.push(item);
    });

    return order;
  }

  private getOrderItem(
    productItem: CartProductItemDto,
    returnedEvents: Events,
    returnedPackagings: Packagings,
    returnedPreviews: Previews,
    orderFormat: OrderFormatEnum
  ): OrderItem {
    const item = new OrderItem();
    item.id = productItem.id;
    item.isPersonalizedByBeneficiary = productItem.isPersonalizedByBeneficiary;

    // récupération de l'évent de ce produit
    item.event = returnedEvents.items.find((event) => event.id === productItem.eventId) || new Event();

    // récupération du packaging de ce produit
    item.packaging = returnedPackagings.items.find((packaging) => packaging.id === productItem.packagingId) || new Packaging();

    // récupération de la preview du produit
    item.preview = returnedPreviews.items.find((preview) => preview.id === productItem.preview.id) || new Preview();

    if (productItem.preview && productItem.preview.personalizedMessages) {
      item.message = productItem.preview.personalizedMessages.messageFirstLine;
      item.message2 = productItem.preview.personalizedMessages.messageSecondLine;
    }

    item.orderItemDetails = new Array<OrderItemDetail>();
    let bookletQuantity = 0;
    let pocketQuantity = 0;
    item.beneficiaries = [];
    productItem.groups.forEach((group: OrderGroupDto) => {
      bookletQuantity += group.productsQuantity;
      pocketQuantity += group.pocketsChequeQuantity;

      group.details.forEach((detail) => {
        const cartDetail = new OrderItemDetail();
        cartDetail.amount = detail.voucherValue.amount;
        cartDetail.quantity = detail.voucherQuantity;

        item.orderItemDetails.push(cartDetail);
      });

      if (group.beneficiaries) {
        item.beneficiaries.push(...group.beneficiaries.map(beneficiary => beneficiary.lastName));
      }
    });
    if (OrderFormatEnum.CHEQUE_BOOKLET === orderFormat) {
      item.bookletQuantity = bookletQuantity;
    }

    // TODO : Le test doit se baser sur le CODE_PACKAGING différent de 000
    if (ProductTypeEnum.KCU !== productItem.productId && item.packaging.label.includes('Pochette')) {
      item.pocketQuantity = pocketQuantity;
    }

    return item;
  }
}
