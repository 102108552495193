<!-- Modal Navigation vers l'IDP Bimpli depuis Cadostore -->
<cado-modal [classSurcharge]="'cado-modal-medium'" id="idBimpliAccountModal">
    <cado-modal-header (modalClose)="closeModal()" [isClosable]="true"></cado-modal-header>
    <div class="w-100 d-flex justify-content-center mt-3 mb-3">
        <img alt="Commander vos Bimpli CADO" class="mw-100" src="assets/images/commandez-er-vos-bimpli-cado.png" />
    </div>
    <cado-modal-header [isClosable]="false" [titleModal]="'LOGIN.BIMPLI.TITLE' | translate"></cado-modal-header>

    <div class="w-100 d-flex justify-content-center mt-3 mb-3">
        {{ "LOGIN.BIMPLI.QUESTION" | translate }}
    </div>

    <div class="d-md-flex justify-content-center">
        <cado-button-primary
            (submitted)="redirect()"
            [buttonLabel]="'LOGIN.BIMPLI.BUTTON' | translate"
            class="d-flex justify-content-center order-md-0"
            id="button_clic_bimpli"
        ></cado-button-primary>
    </div>

    <div class="w-75 d-flex-row mx-auto my-3">
        <div [innerHtml]="'LOGIN.BIMPLI.TEXT' | translate"></div>
    </div>
</cado-modal>
