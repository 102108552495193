import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { ToastService } from '@shared/components/toast/toast.service';
import * as LOCAL_STORAGE from '@shared/constants/local-storage.constants';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { User } from '@shared/models/user/user.model';
import { UserStore } from '@shared/stores/user.store';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserToken } from '../../models/user/user-token.model';
import { ErrorService } from '../error/error.service';
import { UserService } from '../user.service';
import { LoaderService } from '@layouts/loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(
    private readonly cartService: CartService,
    private readonly userStore: UserStore,
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly loaderService: LoaderService
  ) {
  }

  openUrlNewTab(url: string): void {
    window.open(url, '_blank');
  }

  redirectToComiteo(): void {
    this.userService
      .readToken('COMITEO')
      .pipe(
        tap((userToken: UserToken) => {
          this.redirectToComiteoWithToken(userToken.token);
        }),
        catchError(() => {
          this.toastService.showInfinitToastWithTitle(this.translateService.instant('RETAILERS.MERCHANTS.COMITEO_ERROR'), 'error');
          return EMPTY;
        })
      )
      .subscribe();
  }

  redirectToComiteoWithToken(token: string): void {
    // Calcul des éventuelles infos pour le site vitrine
    const parameters = `accessToken=${token}`;
    const url = `${environment.urlComiteo}?${parameters}`;
    window.open(url, '_blank');
  }

  redirectToBimpli(): void {
    this.loaderService.startLoading();
    window.open(environment.urlWebFinanceur, '_self');
  }

  redirectToSiteVitrine(page: string, includeUser: boolean): void {
    // Calcul des éventuelles infos pour le site vitrine
    const user = this.userStore.getCurrentConnectedUser();
    const parameters = !user ? 'logout=1' : this.calculateUrlParameters(includeUser);
    const url = `${environment.urlSiteVitrine}${page}?${parameters}`;
    window.open(url, '_self');
  }

  private calculateUrlParameters(includeUser: boolean): string {
    let parameters = '';
    // Ajout des éventuelles infos pour le site vitrine
    const nbItems = this.cartService.getCurrentItemsCount();
    if (nbItems) {
      parameters += `${LOCAL_STORAGE.NB_ITEMS}=${nbItems}`;
    }
    if (includeUser) {
      const user: User = this.userStore.getCurrentConnectedUser();
      if (user) {
        if (nbItems) {
          parameters += '&';
        }
        parameters += `${LOCAL_STORAGE.NOM}=${user.identity.lastName}&`;
        parameters += `${LOCAL_STORAGE.PRENOM}=${user.identity.firstName}&`;
        parameters += `${LOCAL_STORAGE.IS_CONNECTE}=1&`;
        parameters += `${LOCAL_STORAGE.PROFIL}${(user.type === UserTypeEnum.INDIVIDUAL ? '=part' : '=pro')}`;
      }
    }

    return parameters;
  }
}
