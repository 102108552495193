import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { UserStore } from '@shared/stores/user.store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RedirectAuthGuardService implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userStore: UserStore
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userStore.userConnected$().pipe(
      map((user) => {
        if (!!user) {
          if (user.type === UserTypeEnum.INDIVIDUAL) {
            this.router.navigateByUrl('/my-cado');
          } else {
            this.router.navigateByUrl('/my-orders');
          }
          return false;
        } else {
          return true;
        }
      }),
      take(1)
    );
  }
}
