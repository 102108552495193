import { OrderItemDetail } from '@shared/models/create-order/order-item-detail.model';
import { Event } from '@shared/models/product/event.model';
import { Packaging } from '@shared/models/product/packaging.model';
import { Preview } from '@shared/models/product/preview.model';

export class OrderItem {
  id: number;
  event: Event;
  packaging: Packaging;
  reference: string;
  preview: Preview;
  bookletQuantity: number;
  pocketQuantity: number;
  message: string;
  message2: string;
  orderItemDetails: Array<OrderItemDetail>;
  isPersonalizedByBeneficiary: boolean;
  beneficiaries: string[];
}
