import { Component, OnInit } from '@angular/core';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'cado-bimpli-account-modal',
  templateUrl: './bimpli-account-modal.component.html',
  styleUrls: ['./bimpli-account-modal.component.scss']
})
export class BimpliAccountModalComponent implements OnInit {

  constructor(private readonly modalService: ModalCadoService) {
  }

  ngOnInit(): void {
  }

  redirect(): void {
    window.open(environment.urlWebFinanceur, '_blank');
  }

  public closeModal(): void {
    this.modalService.close('idBimpliAccountModal');
  }

}
