import { ParamMap } from '@angular/router';
import { DigitalCourseSeller } from './digital-course-seller.models';

export class DigitalCourseByUser {
  digitalCourses: Map<string, DigitalCourse> = new Map<string, DigitalCourse>();
}

export class DigitalCourse {

  seller: DigitalCourseSeller;

  // Code operation
  operationCode: string;

  // Code promtion
  discountCode: string;

  static buildQueryParamMap(dc: DigitalCourse): any {

    const params = {};

    // Utm_source : établissement du Chargé de clientèle
    if (dc.seller && dc.seller.establishmentCode) {
      params['utm_source'] = dc.seller.establishmentCode;
    }

    // utm_medium : Code operation
    if (dc.operationCode) {
      params['utm_medium'] = dc.operationCode;
    }

    // utm_campaign : Code promotionel
    if (dc.discountCode) {
      params['utm_campaign'] = dc.discountCode;
    }

    // Utm_content : établissement du Vendeur - sans le prefixe inutile que l'on n'a pas conservé
    if (dc.seller && dc.seller.vendorEstablishmentCode) {
      params['utm_content'] = dc.seller.vendorEstablishmentCode;
    }

    // utm_term: matricule/trigramme du Vendeur
    if (dc.seller && dc.seller.vendorCode) {
      params['utm_term'] = dc.seller.vendorCode;
    }

    return params;
  }

  // Parse les query param pour alimenter le digitalCourse
  static parseQueryParamMap(queryParamMap: ParamMap): DigitalCourse {

    // Parametre de routage null --> Aucun parametre
    if (!queryParamMap) {
      return null;
    }

    // utm_source=ce_rhonealpes_344&utm_medium=emailopfetedesparents_C&utm_campaign=FCE21&utm_content=vi_895&utm_term=YYYY
    let digitalCourse = null;

    // Donne lu via l'url
    // Code etablisement
    let establishmentCode = null;
    let operationCode = null;
    let discountCode = null;
    let vendorEstablishmentCode = null;
    let vendorCode = null;

    // Utm_source : établissement du Chargé de clientèle
    // Si utm_source est renseigné,
    // extraire la valeur : par exemple dans ce_rhonealpes_344 : on prend juste la fin (344) pour le code etab
    // alimentation du establishmentCode directement dans l'appel à updateCart
    if (queryParamMap.has('utm_source')) {
      // utm_source=ce_rhonealpes_344 - A decouper sur les _
      const utmSourceParts = queryParamMap.get('utm_source').split('_');
      establishmentCode = utmSourceParts[utmSourceParts.length - 1];
    }

    // utm_medium : Code operation
    if (queryParamMap.has('utm_medium')) {
      // utm_medium=emailopfetedesparents_C
      operationCode = queryParamMap.get('utm_medium');
    }

    // utm_campaign : Code promotionel
    if (queryParamMap.has('utm_campaign')) {
      // utm_campaign=FCE21
      discountCode = queryParamMap.get('utm_campaign');
    }

    // Utm_content : établissement du Vendeur
    // Si Utm_content est renseigné,
    // extraire la valeur : par exemple dans vi_895 : on prend juste la fin (895) pour le code etab
    // alimentation du vendorEstablishmentCode directement dans l'appel à updateCart
    if (queryParamMap.has('utm_content')) {
      // utm_content=vi_895 - A decouper sur les _
      const utmContentParts = queryParamMap.get('utm_content').split('_');
      vendorEstablishmentCode = utmContentParts[utmContentParts.length - 1];
    }

    // Utm_term: matricule/trigramme du Vendeur
    if (queryParamMap.has('utm_term')) {
      digitalCourse = digitalCourse != null ? digitalCourse : new DigitalCourse();
      // utm_term=YYYY
      vendorCode = queryParamMap.get('utm_term');
    }

    // Pour avoir une info parcours digital valide, on doit avoir le code établissement,
    // donc le cas contraire, celle ci ne peuvent être remplis car incomplete
    if (establishmentCode != null) {
      digitalCourse = new DigitalCourse();
      digitalCourse.seller = new DigitalCourseSeller();
      digitalCourse.seller.establishmentCode = establishmentCode;
      // Si on n'a pas recu de code etablisement vendeur, celui ci est remplis par le code etablissement
      digitalCourse.seller.vendorEstablishmentCode = vendorEstablishmentCode != null ? vendorEstablishmentCode : establishmentCode;
      digitalCourse.seller.vendorCode = vendorCode;
    }

    // Discount
    if (discountCode != null) {
      digitalCourse = digitalCourse != null ? digitalCourse : new DigitalCourse();
      digitalCourse.discountCode = discountCode;
    }

    // Code operation
    if (operationCode != null) {
      digitalCourse = digitalCourse != null ? digitalCourse : new DigitalCourse();
      digitalCourse.operationCode = operationCode;
    }

    return digitalCourse;
  }

}
