import { Injectable } from '@angular/core';
import * as LOCAL_STORAGE from '@shared/constants/local-storage.constants';
import { MerchantTypeEnum } from '@shared/enums/merchant-type.enum';
import { PurchaseTypeEnum } from '@shared/enums/purchase-type.enum';
import { RetailersCarouselProductDto } from '@shared/models/retailers/retailers-carousel-product-dto.model';
import { RetailersMerchantCriteriaDto } from '@shared/models/retailers/retailers-merchant-criteria-dto.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RetailersStore {
  merchantsCriteria$: Observable<RetailersMerchantCriteriaDto>;
  totalMerchants$: Observable<number>;
  // Les filtres sélectionnés pour les marchands
  private readonly merchantsCriteriaSubject: BehaviorSubject<RetailersMerchantCriteriaDto>;
  // Le nombre de marchands correspondant au critère
  private readonly totalMerchantsSubject: BehaviorSubject<number>;

  constructor() {
    // Merchant filter
    let initMerchantCriteria: RetailersMerchantCriteriaDto = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE.MERCHANT_FILTERS));
    if (!initMerchantCriteria) {
      initMerchantCriteria = new RetailersMerchantCriteriaDto();

      this.resetMerchantFiltersCriteria(initMerchantCriteria);

      sessionStorage.setItem(LOCAL_STORAGE.MERCHANT_FILTERS, JSON.stringify(initMerchantCriteria));
    }

    this.merchantsCriteriaSubject = new BehaviorSubject<RetailersMerchantCriteriaDto>(initMerchantCriteria);
    this.merchantsCriteria$ = this.merchantsCriteriaSubject.asObservable();

    this.totalMerchantsSubject = new BehaviorSubject<number>(0);
    this.totalMerchants$ = this.totalMerchantsSubject.asObservable();
  }

  setMerchantCriteria(criteria: RetailersMerchantCriteriaDto) {
    sessionStorage.setItem(LOCAL_STORAGE.MERCHANT_FILTERS, JSON.stringify(criteria));
    this.merchantsCriteriaSubject.next(criteria);
  }

  updateMerchantsCriteriaProduct(product: RetailersCarouselProductDto): void {
    const criteria: RetailersMerchantCriteriaDto = this.merchantsCriteriaSubject.value;
    criteria.carouselProduct = product;
    criteria.useCategoryId = product.useCategoryId ? Array.of(product.useCategoryId) : null;
    this.setMerchantCriteria(criteria);
  }

  updateMerchantsCriteriaCategory(category: string, categoryImageUrl: string): void {
    const criteria: RetailersMerchantCriteriaDto = this.merchantsCriteriaSubject.value;
    criteria.categories = category !== '0' ? category : null;
    criteria.categoryImageUrl = category !== '0' ? categoryImageUrl : null;
    this.setMerchantCriteria(criteria);
  }

  updateFilters(merchantType: MerchantTypeEnum, purchaseType: PurchaseTypeEnum, voucherOnly: boolean): void {
    const criteria: RetailersMerchantCriteriaDto = this.merchantsCriteriaSubject.value;
    criteria.merchantType = merchantType;
    criteria.purchaseType = purchaseType;
    criteria.isPurchaseVoucherAcceptedOnly = voucherOnly;
    this.setMerchantCriteria(criteria);
  }

  updateLabelCriteria(merchantLabel: string): void {
    const criteria: RetailersMerchantCriteriaDto = this.merchantsCriteriaSubject.value;
    criteria.label = merchantLabel;
    this.setMerchantCriteria(criteria);
  }

  setTotalMerchants(totalMerchants: number): void {
    this.totalMerchantsSubject.next(totalMerchants);
  }

  private resetMerchantFiltersCriteria(criteria: RetailersMerchantCriteriaDto): void {
    criteria.merchantType = MerchantTypeEnum.NATIONAL;
    criteria.purchaseType = null;
    criteria.isPurchaseVoucherAcceptedOnly = null;
  }

}
