import { OrderCustomerTypeEnum } from '@shared/enums/order-customer-type.enum';
import { OrderDeliveryTypeEnum } from '@shared/enums/order-delivery-type.enum';
import { OrderFormatEnum } from '@shared/enums/order-format.enum';
import { OrderTypeEnum } from '@shared/enums/orders-type.enum';
import { PaymentMethodEnum } from '@shared/enums/payment-method.enum';
import { ExternalIdCreateDto } from './external-id-create-dto.model';
import { ProductItemOrderCreateDto } from './product-item-order-create-dto.model';
import { OrderOriginEnum } from '@shared/enums/order-origin.enum';

export class ManagerOrderCreateDto {
  externalId: ExternalIdCreateDto;
  customerType: OrderCustomerTypeEnum;
  type: OrderTypeEnum;
  deliveryType: OrderDeliveryTypeEnum;
  carrierId: number;
  paymentMethod: PaymentMethodEnum;
  format: OrderFormatEnum;
  productItems: Array<ProductItemOrderCreateDto>;
  origin: OrderOriginEnum;
}
