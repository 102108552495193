import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ManagerService } from '@services/manager.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { User } from '@shared/models/user/user.model';
import { ManagerStore } from '@shared/stores/manager.store';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'cado-check-phone-modal',
  templateUrl: './check-phone-modal.component.html',
  styleUrls: ['./check-phone-modal.component.scss'],
})
export class CheckPhoneModalComponent implements OnInit, OnDestroy {
  manager: ManagerDto;
  user: User;

  refresh$: Observable<boolean>;

  destroy$ = new Subject<boolean>();

  constructor(
    private readonly modalService: ModalCadoService,
    private readonly formBuilder: FormBuilder,
    private readonly managerStore: ManagerStore,
    private readonly managerService: ManagerService
  ) {
  }

  ngOnInit(): void {
    this.refresh$ = this.managerStore.manager$.pipe(
      tap((manager) => {
        this.manager = manager;
      }),
      map(() => true)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  closePopupPhoneCheck(): void {
    this.modalService.close('popupPhoneCheck');
  }

  openPhoneNumberModificationPopup(isPhoneChange: boolean): void {
    // L'utilisateur dit que son numéro n'est pas valide, on ouvre la popup de modification
    this.closePopupPhoneCheck();
    this.managerService.setIsUpdatePhone(isPhoneChange);
    this.modalService.open('popupPhoneInput');
  }
}
