import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, HttpLoaderFactory } from './core/core.module';

registerLocaleData(localeFr, 'fr');

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RecaptchaV3Module,
    NgxMaskModule.forRoot(maskConfig),
    SharedModule
  ],
  providers: [
    // Injection pour recaptcha
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.googleCaptchaPublicKey},
    // Injecte pour locale france cad mettre le symbole monaitaire sur la droite
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    // Monnaie par defaut si non precise
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'}],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
