<cado-modal [classSurcharge]="'cado-cookie'" id="idGtmConsentModal">
    <cado-modal-header (modalClose)="consent(false);closeModal()" [isClosable]="true" [titleModal]="'COOKIE.TITLE' | translate"></cado-modal-header>

    <div class="w-100 d-flex justify-content-center mt-3 mb-3">
        {{ "COOKIE.DESC_1" | translate }} <br/>
        {{ "COOKIE.DESC_2" | translate }}
    </div>

    <div class="modal-footer">
        <cado-button-primary (submitted)="consent(true); closeModal()"
                             [buttonLabel]="'COOKIE.ACCEPT' | translate"></cado-button-primary>
        <cado-button-primary (submitted)="consent(false); closeModal()"
                             [buttonLabel]="'COOKIE.REFUSE' | translate"></cado-button-primary>
    </div>
</cado-modal>
