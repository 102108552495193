import { MerchantTypeEnum } from '@shared/enums/merchant-type.enum';
import { PurchaseTypeEnum } from '@shared/enums/purchase-type.enum';
import { RetailersCarouselProductDto } from '@shared/models/retailers/retailers-carousel-product-dto.model';

export class RetailersMerchantCriteriaDto {
  merchantType: MerchantTypeEnum;
  purchaseType: PurchaseTypeEnum;
  isPurchaseVoucherAcceptedOnly: boolean;
  categories: string;
  categoryImageUrl: string;
  carouselProduct: RetailersCarouselProductDto;
  label: string;
  useCategoryId: number[];
}
