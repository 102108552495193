import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { AuthentificationCadoService } from '@services/authentification-cado.service';
import { ErrorCodeApiEnum } from '@shared/models/api/enums/error-code-api.enum';
import { AuthToken } from '@shared/models/common/authtoken.model';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as SESSIONSTORAGE from '../constants/session-storage.constants';
import { ToastService } from '@shared/components/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorApi } from '@shared/models/api/error/error.model';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { HttpStatusEnum } from '@shared/models/api/enums/http-status.enum';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';

const NOT_FOUND_STANDARD_ERROR_MESSAGE = '<html><head><title>Error</title></head><body>404 - Not Found</body></html>';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  public constructor(
    private readonly authentificationService: AuthentificationCadoService,
    private readonly router: Router,
    private readonly menuDesktopService: MenuDesktopService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalCadoService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqEvent$: Observable<HttpEvent<any>>;
    if (req.url.includes('/CADO') && !req.url.includes('/authorize') && !this.authentificationService.isAuthenticated()) {
      // Si l'on demande une API CADO en mode non connecté
      // Alors on fait d'abord une demande de token puis on envoi la requête
      reqEvent$ = this.authentificationService.getAccessToken$().pipe(
        switchMap((authToken: AuthToken) => {
          // On rajoute les infos du token dans le session storage pour que le isAuthenticated() fonctionne comme prévu
          this.authentificationService.addToSessionStorage(authToken.access_token, authToken.expires_in, authToken.token_type);
          const httpRequest = req.clone({
            setHeaders: {
              Authorization: `${authToken.token_type} ${authToken.access_token}`,
            },
          });
          return next.handle(httpRequest);
        })
      );
    } else {
      reqEvent$ = next.handle(this.getReq(req));
    }

    return reqEvent$.pipe(
      catchError((err) => {
        if (err && err.error && err.error.errors && err.error.errors.length > 0) {
          const apiError: ErrorApi = err.error.errors[0];
          if (apiError.code === ErrorCodeApiEnum.ORDER_FORBIDDEN_TO_INDIVIDUAL_114) {
            this.toastService.showInfinitToastWithTitle(this.translateService.instant('ORDER.ORDER_FORBIDDEN_TO_INDIVIDUAL_114'), 'error');
            this.router.navigate(['/my-cado']);
            return EMPTY;
          }
        } else if (err.status === 401) {
          sessionStorage.clear();
          this.menuDesktopService.hideUserConnected();
          this.router.navigate(['/authent/refreshSession']);
        } else if (HttpStatusEnum.BAD_GATEWAY === err.status
          || HttpStatusEnum.SERVICE_UNAVAILABLE === err.status
          || HttpStatusEnum.GATEWAY_TIMEOUT === err.status) { // BIMCADO-159 : si erreur 502/503/504 on estime que le serveur est en maintenance
          this.modalService.open('idMaintenanceModal');
        } else if (HttpStatusEnum.NOT_FOUND === err.status  // BIMCADO-159 : ou si erreur 404 non renvoyée pas l'API
          && err.error === NOT_FOUND_STANDARD_ERROR_MESSAGE) {
          this.modalService.open('idMaintenanceModal');
        } else if (HttpStatusEnum.INTERNAL_SERVER_ERROR === err.status  // // Si erreur de signature JWT, on vide le cache pour supprimer le token de connexion
          && err.error.includes('SignatureVerificationException')) {
          localStorage.removeItem(LOCALSTORAGE.AUTHENT_TOKEN);
          window.location.reload(); // on force le refresh de la page pour ne plus prendre en compte le token chargé en mémoire
        }

        throw err;
      })
    );

  }

  private getReq(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url.includes('/CADO')) {
      if (!this.authentificationService.isAuthenticated()) {
        this.router.navigate(['/authent']);
      }
      return req.clone({
        setHeaders: {
          Authorization: `${sessionStorage.getItem(SESSIONSTORAGE.ITEM_SCOPE)} ${sessionStorage.getItem(SESSIONSTORAGE.ITEM_TOKEN)}`,
        },
      });
    } else {
      return req;
    }
  }
}
