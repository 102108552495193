import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { AuthentificationCadoService } from '@services/authentification-cado.service';
import { ManagerService } from '@services/manager.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { CardActivationStore } from '@shared/stores/card-activation.store';
import { DigitalCourseStore } from '@shared/stores/digital-course.store';
import { ManagerStore } from '@shared/stores/manager.store';
import { RedirectionStore } from '@shared/stores/redirection.store';
import { UserStore } from '@shared/stores/user.store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authentificationService: AuthentificationCadoService,
    private readonly menuDesktopService: MenuDesktopService,
    private readonly digitalCourseStore: DigitalCourseStore,
    private readonly userStore: UserStore,
    private readonly cardActivationStore: CardActivationStore,
    private readonly redirectStore: RedirectionStore,
    private readonly managerStore: ManagerStore,
    private readonly modalService: ModalCadoService,
    private readonly managerService: ManagerService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.userStore.getCurrentConnectedUser();
    if (user && this.authentificationService.isAuthenticated()) {
      if (user.type === UserTypeEnum.INDIVIDUAL) {
        this.checkUserPhoneNumber(user);
      }
      return true;
    }

    // Si l'utilisateur n'est pas connecté et qu'on a une donnée redirectAfterLogin, on met à jour la redirection
    const redirectUrl = next.data?.redirectAfterLogin;
    if (redirectUrl) {
      this.redirectStore.setRedirection(redirectUrl);
    }

    this.userStore.disconnect();
    this.cardActivationStore.resetCardActivationRequest();
    this.menuDesktopService.hideUserConnected();

    sessionStorage.setItem(SESSIONSTORAGE.TARGET, next.url.join(''));

    // Dans le cas ou le user n'est plus connecté, on le redirige sur la page de connection
    // avec le toastInfinite sinon on affiche pas le toast
    if (user) {
      this.router.navigate(['/authent/refreshSession']);
    } else {
      this.router.navigate(['/authent/connection']);
    }

    return false;
  }

  // On regarde si le numéro du particulier est vérifié, si ce n'est pas le cas on affiche la modal pour vérifier ou ajouter un numéro
  private checkUserPhoneNumber(user) {
    this.managerStore.manager$.pipe(
      tap(manager => {
        if (!manager) {
          this.managerService.readManager(user.managerId).pipe(
            tap(managerFromApi => {
              if (managerFromApi) {
                this.managerStore.setManager(managerFromApi);
              }
            }),
            catchError(() => {
              return of(null as ManagerDto);
            }),
          ).subscribe();
        } else {
          if (!manager.isCellPhoneNumberChecked) {
            // Soit celle pour le saisir s'il n'en a pas
            if (!manager.cellPhoneNumber || manager.cellPhoneNumber.length === 0) {
              this.modalService.open('popupPhoneInput');
            } else {
              // Soit celle pour le confirmer s'il en a un
              this.modalService.open('popupPhoneCheck');
            }
          }
        }
      })
    ).subscribe();
  }
}
