import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoaderService } from '@layouts/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { RetailersService } from '@services/merchant/retailers.service';
import { MerchantControllerService } from '@services/ws/merchant/merchant-controller.service';
import { ToastService } from '@shared/components/toast/toast.service';
import { MerchantAffiliatesDto } from '@shared/models/api/merchant/merchant-affiliates-dto.model';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MerchantResolver implements Resolve<MerchantAffiliatesDto> {
  constructor(
    private readonly router: Router,
    private readonly merchantController: MerchantControllerService,
    private readonly retailersService: RetailersService,
    private readonly loaderService: LoaderService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<MerchantAffiliatesDto> {
    this.loaderService.startLoading();
    const merchantId = route.paramMap.get('merchantId');
    return this.merchantController.readMerchantById(merchantId).pipe(
      tap((merchant) => {
        this.retailersService.setMerchant(merchant);
        this.retailersService.resetAffiliate();
      }),
      catchError((error) => {
        // En cas d'erreur on réinitialise le marchand et ses affiliés quand même
        this.retailersService.setMerchant(null);
        this.retailersService.setMerchantAffiliates(null);
        // On affiche un message
        this.toastService.showInfinitToastWithTitle(this.translateService.instant('USUAL.ERROR_COMMERCANT'), 'error');
        this.loaderService.stopLoading();
        this.router.navigate(['/retailers-all-cado']);
        // Et renvoyer une erreur permet de bloquer l'accès à la page
        throw error;
      }),
      switchMap(() => {
        return this.retailersService.updateMerchantAffiliates$(3, 1, true);
      }),
      catchError(() => {
        // En cas d'erreur on réinitialise les affiliés
        this.retailersService.setMerchantAffiliates(null);
        // On affiche un message
        this.toastService.showInfinitToastWithTitle(this.translateService.instant('USUAL.TECHNICAL_ERROR'), 'error');
        // On a quand même le marchand donc affichons toujours la page avec les infos disponibles
        return of(null);
      }),
      finalize(() => this.loaderService.stopLoading())
    );
  }
}
