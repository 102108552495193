import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { LoaderService } from '@layouts/loader/loader.service';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { ManagerService } from '@services/manager.service';
import { OrderControllerService } from '@services/ws/order/order/order-controller.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { ToastService } from '@shared/components/toast/toast.service';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';
import { UserActiveEnum } from '@shared/enums/user-active.enum';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { UpdatePhoneModalComponent } from '@shared/modals/update-phone-modal/update-phone-modal.component';
import { RoutesEnum } from '@shared/models/common/routes.enum';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { ManagedCustomer } from '@shared/models/user/managed-customer.model';
import { User } from '@shared/models/user/user.model';
import { AuthentificationCadoService } from '@shared/services/authentification-cado.service';
import { ConnectService } from '@shared/services/routing/connect.service';
import { CustomerStore } from '@shared/stores/customer.store';
import { PageStore } from '@shared/stores/page.store';
import { RedirectionStore } from '@shared/stores/redirection.store';
import { UserStore } from '@shared/stores/user.store';
import { AuthentificationService, TokenComponent } from 'nit-angular-lib';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Order } from '@shared/models/create-order/order.model';
import { EntrepriseTypeEnum } from '../../../shared/enums/entreprise-type.enum';

@Component({
  selector: 'cado-token-cado',
  templateUrl: './token-cado.component.html',
  styleUrls: ['./token-cado.component.scss'],
})
export class TokenCadoComponent extends TokenComponent implements OnInit, OnDestroy {
  user: User;
  manager: ManagerDto;
  redirection: string;
  currentPage: PageNameEnum;

  redirectionUrlAllowed = [RoutesEnum.MY_CADO, RoutesEnum.MY_PROFILE, RoutesEnum.MY_ORDERS, RoutesEnum.ORDER, RoutesEnum.RETAILERS_MY_CADO];

  isFromBimpli = false;

  redirection$: Observable<string>;

  destroy$ = new Subject<boolean>();


  @ViewChild(UpdatePhoneModalComponent) updatePhoneModalComponent: UpdatePhoneModalComponent;

  constructor(
    readonly route: ActivatedRoute,
    readonly authentificationService: AuthentificationService,
    private readonly router: Router,
    private readonly authentCadoService: AuthentificationCadoService,
    private readonly menuService: MenuDesktopService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalCadoService,
    private readonly managerService: ManagerService,
    private readonly orderControllerService: OrderControllerService,
    private readonly cartService: CartService,
    private readonly translateService: TranslateService,
    private readonly connectService: ConnectService,
    private readonly loaderService: LoaderService,
    private readonly userStore: UserStore,
    private readonly customerStore: CustomerStore,
    private readonly pageStore: PageStore,
    private readonly redirectionStore: RedirectionStore
  ) {
    super(route, authentificationService);
  }

  public ngOnInit(): void {
    let urlFragment: string;

    this.pageStore.isFromBimpli$
      .pipe(
        tap((isFromBimpli) => {
          this.isFromBimpli = isFromBimpli;
        })
      ).subscribe();


    this.route.fragment
      .pipe(
        map((fragment: string) => {
          urlFragment = fragment;
          return new URLSearchParams(urlFragment);
        }),
        tap((params: URLSearchParams) => {
          if (params.has('redirection')) {
            let redirectionParam = params.get('redirection');
            let redirectionUrl;

            if (redirectionParam.includes('?')) {
              redirectionUrl = redirectionParam.split('?')[0];
            } else if (redirectionParam.includes('#')) {
              redirectionUrl = redirectionParam.split('#')[0];
            } else if (redirectionParam.includes(';')) {
              redirectionUrl = redirectionParam.split(';')[0];
            } else if (redirectionParam.includes('/')) {
              redirectionUrl = redirectionParam.split('/')[0];
            } else {
              redirectionUrl = redirectionParam;
            }

            if (this.redirectionUrlAllowed.includes(redirectionUrl)) {
              if (redirectionUrl === RoutesEnum.RETAILERS_MY_CADO && params.has('categoryId')) {
                redirectionParam = `${redirectionParam}&categoryId=${params.get('categoryId')}`;
              } else { // On prend toute ce qu'il y a après la query param de redirection pour inclure les possibles options
                redirectionParam = urlFragment.split('redirection=')[1];
              }
              this.redirectionStore.setRedirection(redirectionParam);
            }
          }
        })
      )
      .subscribe();

    this.redirection$ = this.redirectionStore.redirection$.pipe(
      tap((redirection) => {
        this.redirection = redirection;
      })
    );

    this.pageStore.currentPage$
      .pipe(
        tap((currentPage) => {
          this.currentPage = currentPage;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    if (!environment.localApi || environment.authentication) {
      this.authentCadoService
        .initSessionStorage(this.accessTokenValue, Number(this.expiresInValue), this.tokenTypeValue)
        .toPromise()
        .then(() => {
          this.initContext();
        });
    } else {
      // Dans le cas du local, on ne fait pas appel à l'api user.
      // Les infos sont déjà dans le session storage
      this.initContext();
    }
  }

  initContext(): void {
    this.user = this.userStore.getCurrentConnectedUser();

    // Si Nombre de Customers == 0 on affiche un toast infinite
    if (this.user.customers === undefined || this.user.customers.length === 0) {
      this.toastService.showInfinitToastWithTitle('La connexion est refusée. Le client associé au compte est inactif. Veuillez contacter le service client', 'error');
      this.userStore.disconnect();
    }

    // Si le client est actif
    if (this.user.active === UserActiveEnum.ACTIVE) {
      localStorage.setItem(LOCALSTORAGE.IS_CONNECTE, '1');
      localStorage.setItem(LOCALSTORAGE.NOM, this.user.identity.lastName);
      localStorage.setItem(LOCALSTORAGE.PRENOM, this.user.identity.firstName);

      this.connectService.initUser();
      this.userStore
        .userNotNull$()
        .pipe(
          tap(() => {
            // loader
            this.loaderService.startLoading();
            // On affiche le nom et prénom de l'utilisateur connecté
            this.menuService.showUserConnectedWithName(`${this.user.identity.firstName} ${this.user.identity.lastName}`);
          }),
          switchMap(() => {
            // Si individuel
            if (this.user.type === UserTypeEnum.INDIVIDUAL) {
              return this.manageUser();
            } else if (this.user.type === UserTypeEnum.PROFESSIONAL) { // si pro
              return this.manageProfessional();
            }
            return EMPTY;
          }),
          take(1),
          finalize(() => {
            this.loaderService.stopLoading();
          })
        )
        .subscribe();
    }
  }

  manageProfessional(): Observable<Order> {
    // Si Nombre de Customers == 1 => Atterrissage page mes-commandes
    if (this.user.customers.length === 1) {
      return this.manageUser();
    } else {
      // Ouverture popUp Sélection d'un compte client
      this.modalService.open('popupCustomerSelection');
      // On retourne un observable null car sinon on ne passe pas dans le finalise et on n'arrete pas le loader
      return of(null);
    }
  }

  manageUser(): Observable<Order> {
    this.loaderService.startLoading();
    const stripDownCustomer = this.user.customers[0];

    this.menuService.showUserConnectedWithName(`${this.user.identity.firstName} ${this.user.identity.lastName}`);
    this.menuService.showListCustomer([]);
    this.customerStore.setCustomerId(stripDownCustomer?.id);

    return this.cartService.mergeLocalCartWithServer$(this.user.managerId, stripDownCustomer.id, this.user).pipe(
      tap((order: Order) => this.redirect(order)),
      finalize(() => this.loaderService.stopLoading())
    );
  }

  closePopupCustomerSelection(): void {
    this.modalService.close('popupCustomerSelection');
  }

  onCustomerClick(customer: ManagedCustomer): void {
    this.loaderService.startLoading();
    this.customerStore.setCustomerId(customer?.id);
    this.menuService.showDropdownWithUser(customer?.id, this.user.customers);
    this.menuService.showUserConnectedWithName(`${this.user.identity.firstName} ${this.user.identity.lastName}`);

    this.cartService
      .mergeLocalCartWithServer$(this.user.managerId, customer.id, this.user).pipe(
      tap((order: Order) => this.redirect(order)),
      tap((_) => {
        // CADO-321
        const commencePar1ou2 = (/^[1-2]/.test(customer.siret));
        const secteurPublic = EntrepriseTypeEnum[customer.type] === EntrepriseTypeEnum.PUBLIC_SECTOR;
        if (secteurPublic && !commencePar1ou2) {
          this.toastService.showInfinitToastWithTitle(this.translateService.instant('TOKEN.CONNECT_PUBLIC_SECTOR'), 'info');
        }
      }),
      take(1),
      finalize(() => this.loaderService.stopLoading())
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  /**
   * Fonction qui redirige un utilisateur sur la bonne page
   * @param cart la liste des commandes
   */
  private redirect(cart: Order): void {
    // Redirection si nécessaire
    if (this.redirection) {
      if (this.redirection !== RoutesEnum.PAYMENT) { // on ne redirige pas si c'est pour la page de Payment pour éviter une double redirection
        this.router.navigateByUrl(this.redirection);
      }
      this.redirectionStore.resetRedirection();
      return;
    }

    // Si currentPage renseigné on vérifie et redirige
    if (this.currentPage) {
      if (this.currentPage === PageNameEnum.MY_CADO_RETAILERS) {
        this.router.navigateByUrl(RoutesEnum.RETAILERS_MY_CADO);
        return;
      }
      if (this.currentPage === PageNameEnum.ALL_CADO_RETAILERS) {
        this.router.navigateByUrl(RoutesEnum.RETAILERS_ALL_CADO);
        return;
      }
      if (this.currentPage === PageNameEnum.PAYMENT) {
        this.router.navigateByUrl(RoutesEnum.PAYMENT);
        return;
      }
      if (this.currentPage === PageNameEnum.CARD_ACTIVATION || this.currentPage === PageNameEnum.CARDS_ACTIVATION) {
        if (this.user.type === UserTypeEnum.INDIVIDUAL) {
          this.router.navigateByUrl(RoutesEnum.CARD_ACTIVATION);
          return;
        } else {
          this.toastService.showInfinitToastWithTitle(this.translateService.instant('ACTIVATION.PRO'), 'info');
          this.router.navigateByUrl('/#');
          return;
        }
      }
    }

    // Si on a trouvé un panier on redirige sur la page de paiement
    if (cart) {
      this.router.navigateByUrl(RoutesEnum.PAYMENT);
    } else { // sinon on redirige
      if (this.user.type === UserTypeEnum.INDIVIDUAL) {
        this.router.navigateByUrl(RoutesEnum.MY_CADO); // Mes CADO en part
      } else {
        this.router.navigateByUrl(RoutesEnum.MY_ORDERS); // Mes commandes en pro
      }
    }
  }
}
