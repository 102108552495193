<cado-modal id="popupPhoneCheck">
    <ng-container *ngIf="refresh$ | async">
        <cado-modal-header (modalClose)="closePopupPhoneCheck()" [ngClass]="'d-flex justify-content-center regularline'"
                           [titleModal]="'TOKEN.PHONE_TITLE' | translate"></cado-modal-header>

        <div *ngIf="manager" class="d-flex mt-4 boldline">
            {{'TOKEN.PHONE_CHECK_INTRO' | translate: {prenom: this.manager.firstName[0].toUpperCase() + this.manager.firstName.substr(1).toLowerCase()} }}
        </div>

        <div *ngIf="manager?.cellPhoneNumber" class="d-flex justify-content-center mt-4">
            <em class="icon-phone mr-2"></em>
            {{manager.cellPhoneNumber | phone}}
        </div>

        <div class="d-flex mt-4 boldline">
            {{'TOKEN.PHONE_LINE1' | translate}}
        </div>
        <div class="d-flex mt-4 regularline">
            {{'TOKEN.PHONE_LINE2' | translate}}
        </div>
        <div class="d-flex mt-4 regularline">
            {{'TOKEN.PHONE_LINE_WARNING' | translate}}
        </div>
        <div class="d-flex mt-4 regularline">
            {{'TOKEN.PHONE_LINE3' | translate}}
        </div>

        <div class="row justify-content-center mt-5">
            <div class="col-auto mb-2 mb-sm-0">
                <cado-button-secondary
                    (click)="openPhoneNumberModificationPopup(true)"
                    [buttonLabel]="'TOKEN.BUTTON_UPDATE_PHONE' | translate"
                    [classSurcharge]="'fill-space'"
                ></cado-button-secondary>
            </div>
            <div class="col-auto">
                <cado-button-primary
                    (click)="openPhoneNumberModificationPopup(false)"
                    [buttonLabel]="'TOKEN.BUTTON_CONFIRM_PHONE' | translate"
                    [classSurcharge]="'fill-space'"
                ></cado-button-primary>
            </div>
        </div>
    </ng-container>
</cado-modal>
