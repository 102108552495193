import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  showStatus = new Subject<boolean>();
  showTitle = new Subject<string>();
  showType = new Subject<string>();
  showCss = new Subject<string>();
  public readonly technicalToastAllowed = environment.environment !== 'PROD';

  constructor(
    private readonly translateService: TranslateService) {

    }
  /**
   * Affiche un toast infini
   * @param title title that you will show on the toast
   * @param type success for success icon and error for error icon
   */
  showInfinitToastWithTitle(title, type : 'error'|'success'|'info', exceptionalTechnicalError = false, timestamp ? : Date): void {
    this.showStatus.next(true);
    this.showTitle.next(title);
    if (exceptionalTechnicalError) {
      this.showType.next('error');
      if (this.technicalToastAllowed) {
        this.showCss.next('toasterror');
      } else {
        const offset = new Date().getTimezoneOffset() * 1000 * 60;
        this.showTitle.next(this.translateService.instant('USUAL_ERROR.UNEXPECTED',  {numero: (new Date(timestamp).getTime() + offset)}));
        this.showCss.next('toast');
      }
    } else {
      this.showType.next(type);
      this.showCss.next('toast');
    }
  }

  /**
   * Affiche un toast d'une durée de 3 secondes
   * @param title le texte à afficher dans le toast
   * @param type le type de toast
   */
  showShortToastWithTitle(title, type): void {
    this.showStatus.next(true);
    this.showTitle.next(title);
    this.showType.next(type);
    setTimeout(() => this.showStatus.next(false), 6000);
  }

  hideToast(): void {
    this.showStatus.next(false);
  }

}
