import { AddressManagerUpdateDto } from '@shared/models/manager/addressManagerUpdate.model';
import { ManagerCustomerUpdateDto } from '@shared/models/manager/manager-customer-update-dto.model';

export class ManagerUpdateDto {
  title: string;
  lastName: string;
  firstName: string;
  birthDate: string;
  phoneNumber: string;
  cellPhoneNumber: string;
  address: AddressManagerUpdateDto;
  email: string;
  mailOptin: boolean;
  smsOptin: boolean;
  isCellPhoneNumberChecked: boolean;
  customers?: Array<ManagerCustomerUpdateDto>;
}
