import { Injectable } from '@angular/core';
import { LoaderService } from '@layouts/loader/loader.service';
import { CustomerService } from '@services/customer.service';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { Customer } from '@shared/models/customer/customer.model';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerStore {
  customerId$: Observable<number>;
  private readonly customerIdSubject: BehaviorSubject<number>;

  private readonly customerSubject = new BehaviorSubject<Customer>(null);
  customer$: Observable<Customer> = this.customerSubject.asObservable();

  constructor(
    private readonly loaderService: LoaderService,
    private readonly customerService: CustomerService
  ) {
    const customerId = parseInt(sessionStorage.getItem(SESSIONSTORAGE.ID_CURRENT_CUSTOMER), 10) || null;
    this.customerIdSubject = new BehaviorSubject<number>(customerId);
    this.customerId$ = this.customerIdSubject.asObservable();
  }

  setCustomerId(customerId: number): void {
    sessionStorage.setItem(SESSIONSTORAGE.ID_CURRENT_CUSTOMER, customerId?.toString());
    this.customerIdSubject.next(customerId);
  }

  loadCustomer(): Observable<boolean> {
    const customerId = this.customerIdSubject.value;
    let load$: Observable<Customer>;
    if (customerId) {
      this.loaderService.startLoading();
      load$ = this.customerService.readCustomer(customerId).pipe(
        catchError(() => {
          // Si on a une erreur, par exemple 403, on réinitialise le customer
          return of(null);
        }),
        finalize(() => this.loaderService.stopLoading())
      );
    } else {
      load$ = of(null);
    }

    return load$.pipe(
      tap((customer) => {
        this.setCustomer$(customer);
      }),
      map(() => true)
    );
  }

  // Cette méthode est privée afin d'éviter les désynchro entre les deux observables.
  // Soit le customer change et on modifie customerId$, ce qui mettra automatiquement

  // d'autant plus que le customer est modifiable par l'utilisateur dans le header de l'application
  getCurrentCustomer(): Customer {
    return this.customerSubject.value;
  }

  // Attention, cette méthode renvoie le customer courant,
  // mais il vaut mieux utiliser directement l'observable pour savoir quand il est modifié,

  // à jour completeCustomer$, soit on veut recharger le completeCustomer et on appelle loadCompleteCustomer
  private setCustomer$(customer: Customer): void {
    this.customerSubject.next(customer);
  }
}
