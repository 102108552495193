import { Injectable } from '@angular/core';
import { PaymentCarrierDto } from '@shared/models/payment/payment-dto.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private readonly paymentMethodProvenanceCheckedSubject = new Subject<boolean>();
  paymentMethodProvenanceCheckedObs: Observable<boolean> = this.paymentMethodProvenanceCheckedSubject.asObservable();
  private readonly paymentMethodCgvCheckedSubject = new Subject<boolean>();
  paymentMethodCgvCheckedObs: Observable<boolean> = this.paymentMethodCgvCheckedSubject.asObservable();
  private readonly launchBtnPayDisableCheckedSub = new Subject<void>();
  launchBtnPayDisableCheckedObs: Observable<void> = this.launchBtnPayDisableCheckedSub.asObservable();
  private readonly selectedCarrierSubject = new BehaviorSubject<PaymentCarrierDto>(null);
  selectedCarrierObs: Observable<PaymentCarrierDto> = this.selectedCarrierSubject.asObservable();
  private readonly orderCreatedSubject = new Subject<boolean>();
  orderCreatedObservable$: Observable<boolean> = this.orderCreatedSubject.asObservable();

  constructor() {
  }

  onClickPaymentMethodProvenance(areChecked: boolean): void {
    this.paymentMethodProvenanceCheckedSubject.next(areChecked);
  }

  onClickPaymentMethodCgv(areChecked: boolean): void {
    this.paymentMethodCgvCheckedSubject.next(areChecked);
  }


  onRelaunchCheckBtnPay(): void {
    // on appel l'observateur dans la page paiement pour qu'il vérifie s'il active ou non le bouton payer
    this.launchBtnPayDisableCheckedSub.next();
  }

  onCarrierIsSelected(carrier: PaymentCarrierDto) {
    this.selectedCarrierSubject.next(carrier);
  }

  onOrderCreated() {
    this.orderCreatedSubject.next(true);
  }

}
