import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { RoutesEnum } from '@shared/models/common/routes.enum';

@Component({
  selector: 'cado-confirm-disconnect-modal',
  templateUrl: './confirm-disconnect-modal.component.html',
  styleUrls: ['./confirm-disconnect-modal.component.scss'],
})
export class ConfirmDisconnectModalComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly modalService: ModalCadoService
  ) {
    // do nothing
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalService.close('idModalConfirmDisconnect');
  }

  onValidate() {
    this.closeModal();
    this.router.navigate([RoutesEnum.LOGOUT]);
  }
}
