import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonActivateComponent } from '@layouts/button-activate/button-activate.component';
import { FooterMobileComponent } from '@layouts/footer-mobile/footer-mobile.component';
import { MainComponent } from '@layouts/main/main.component';
import {
  MenuDesktopDropdownManagerComponent
} from '@layouts/menu-desktop/menu-desktop-dropdown-manager/menu-desktop-dropdown-manager.component';
import { MenuDesktopComponent } from '@layouts/menu-desktop/menu-desktop.component';
import { MenuMobileComponent } from '@layouts/menu-mobile/menu-mobile.component';
import { SousMenuDesktopComponent } from '@layouts/sous-menu-desktop/sous-menu-desktop.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpRequestInterceptor } from '@shared/interceptors/HttpRequestInterceptor';
import { SharedModule } from '@shared/shared.module';
import { TokenCadoComponent } from './tokenCado/token-cado/token-cado.component';
import { BimpliHeaderComponent } from '@layouts/bimpli-header/bimpli-header.component';

@NgModule({
  declarations: [
    MainComponent,
    TokenCadoComponent,
    ButtonActivateComponent,
    MenuDesktopComponent,
    MenuMobileComponent,
    SousMenuDesktopComponent,
    BimpliHeaderComponent,
    FooterMobileComponent,
    MenuDesktopDropdownManagerComponent
  ],
  exports: [
    MainComponent,
    BimpliHeaderComponent
  ],
  imports: [
    CommonModule,
    // ngx-translate and the loader module
    HttpClientModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true}
  ]
})
export class CoreModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
