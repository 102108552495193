import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { UrlService } from '@services/miscellaneous/url.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { ManagedCustomer } from '@shared/models/user/managed-customer.model';
import { User } from '@shared/models/user/user.model';
import { CustomerStore } from '@shared/stores/customer.store';
import { PageStore } from '@shared/stores/page.store';
import { UserStore } from '@shared/stores/user.store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'cado-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent implements OnInit, OnDestroy {
  user: User;
  pageName: PageNameEnum;
  UserTypeEnum = UserTypeEnum;
  EnumPageName = PageNameEnum;

  private readonly subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly menuService: MenuDesktopService,
    private readonly modalService: ModalCadoService,
    private readonly pageStore: PageStore,
    private readonly urlService: UrlService,
    private readonly userStore: UserStore,
    private readonly customerStore: CustomerStore
  ) {
  }

  public ngOnInit(): void {
    this.user = this.userStore.getCurrentConnectedUser();

    const changePageSubscription = this.pageStore.currentPage$
      .pipe(
        tap((pPageName: PageNameEnum) => {
          this.pageName = pPageName;
        })
      ).subscribe();

    this.subscription.add(changePageSubscription);
  }

  public onAccueilClick(): void {
    this.urlService.redirectToSiteVitrine('', true);
  }

  public onProduitCadoClick(): void {
    this.urlService.redirectToSiteVitrine('produits-cado', true);
  }

  public onRetailersClick(): void {
    this.menuService.hideRetailersMenu();
    this.user = this.userStore.getCurrentConnectedUser();
    if (this.user && this.user.type !== UserTypeEnum.PROFESSIONAL) {
      this.router.navigateByUrl('/retailers-my-cado');
    } else {
      this.router.navigateByUrl('/retailers-all-cado');
    }
  }

  public openChangeCustomer(): void {
    // Ouverture de la pop-up de changement de client
    this.modalService.open('selectCustomerModalId');
  }

  public onMyProfileClick(): void {
    this.user = this.userStore.getCurrentConnectedUser();
    // Soit on est connecté et on redirige vers my-orders si PRO, my-cado si PART.
    // Soit on ne l'est pas et on doit s'authentifier
    if (this.user != null) {
      // Si on est authentifié et qu'il y a plusieurs clients rattachés au manager, on affiche la popup de sélection
      const currentCustomer = this.customerStore.getCurrentCustomer();

      const customersListWithoutCurrent: ManagedCustomer[] = [];
      this.user.customers.forEach((element) => {
        if (element.id !== currentCustomer.id) {
          customersListWithoutCurrent.push(element);
        }
      });
      if (customersListWithoutCurrent.length > 0) {
        this.openChangeCustomer();
      } else {
        if (this.isPro()) {
          this.router.navigateByUrl('/my-orders');
        } else {
          this.router.navigateByUrl('/my-cado');
        }
      }
    } else {
      this.router.navigateByUrl('/authent');
    }
  }

  public isPro() {
    this.user = this.userStore.getCurrentConnectedUser();
    if (this.user) {
      return this.user.type === UserTypeEnum.PROFESSIONAL;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  highlightMyProfile(): boolean {
    return (name === '' && this.router.url.includes('/authent'))
      || this.pageName === PageNameEnum.MY_CADO
      || this.pageName === PageNameEnum.MY_PURCHASE_VOUCHER
      || this.pageName === PageNameEnum.MY_ORDERS;
  }

}
