<div *ngIf="refresh$ | async">
    <!-- Menus -->
    <cado-menu-desktop *ngIf="!isFromBimpli" class="d-none d-xl-flex align-content-center cado-menu-desktop"></cado-menu-desktop>
    <cado-menu-mobile *ngIf="!isFromBimpli" class="d-flex d-xl-none cado-menu-mobile"></cado-menu-mobile>
    <cado-sous-menu-desktop
        *ngIf="isSousMenuVisible && !isFromBimpli"
        [isRetailers]="isRetailersSousMenuVisible"
        class="cado-sous-menu-desktop"
    ></cado-sous-menu-desktop>
    <cado-bimpli-header *ngIf="isFromBimpli"></cado-bimpli-header>

    <!-- Selon le menu affiché, on ajoute l'espace nécessaire en haut de la page-->
    <div *ngIf="!isFromBimpli" class="d-none d-xl-flex menu-desktop-place"></div>
    <div *ngIf="!isFromBimpli" class="d-flex d-xl-none menu-mobile-place"></div>
    <!-- Si le sous-menu est affiché, on ajoute l'espace nécessaire en haut de la page-->
    <div *ngIf="isSousMenuVisible && !isFromBimpli" class="sous-menu-place"></div>

    <!-- Loader Cado -->
    <cado-loader></cado-loader>

    <!-- Main de l'application -->
    <div class="main">
        <!-- Toast -->
        <cado-toast></cado-toast>
        <router-outlet></router-outlet>
    </div>

    <ng-container *ngIf="(showMobileFooter$ | async) && !isFromBimpli && !isParcoursDigital">
        <cado-button-activate *ngIf="!isPro" class="d-flex d-xl-none cado-button-activate"></cado-button-activate>
        <cado-footer-mobile class="d-flex d-xl-none cado-footer-mobile"></cado-footer-mobile>
    </ng-container>
</div>

<!-- Modal ouvrable sur n'importe quelle page -->
<cado-check-phone-modal *ngIf="!isPhoneVerified"></cado-check-phone-modal>
<cado-update-phone-modal *ngIf="!isPhoneVerified"></cado-update-phone-modal>
<!-- Popin de Navigation vers l'IDP Bimpli depuis Cadostore -->
<cado-bimpli-account-modal></cado-bimpli-account-modal>
<cado-gtm-consent></cado-gtm-consent>
<cado-maintenance-modal></cado-maintenance-modal>
