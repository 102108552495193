import { DetailOrderGroupCreateDto } from './detail-order-group-create-dto';
import { OrderActivationContactCreateDto } from './order-activation-contact-create-dto.model';
import { OrderBeneficiaryCreateDto } from './order-beneficiary-create-dto.model';
import { OrderDeliveryPointCreateDto } from './order-delivery-point-create-dto.model';

export class OrderGroupCreateDto {
  productsQuantity: number;
  pocketsChequeQuantity: number;
  details: Array<DetailOrderGroupCreateDto>;
  activationContact: OrderActivationContactCreateDto;
  beneficiaries: Array<OrderBeneficiaryCreateDto>;
  deliveryPointId: number;
  delivery: OrderDeliveryPointCreateDto;
}
