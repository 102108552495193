import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ModalCadoService } from './modal-cado.service';

@Component({
  selector: 'cado-modal',
  templateUrl: 'modal-cado.component.html',
  styleUrls: ['./modal-cado.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCadoComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() classSurcharge: string;

  private readonly element: any;

  constructor(
    private readonly modalCadoService: ModalCadoService,
    private readonly el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // add self (this modal instance) to the modal service so it's accessible from controllers

    this.modalCadoService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalCadoService.remove(this.id);
    try {
      this.element.remove();
    } catch (error) {
      console.error('Here is the error message', error);
    }
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('cado-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('cado-modal-open');
  }
}
