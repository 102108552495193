import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ManagerCustomerDto } from '@shared/models/manager/manager-customer-dto.model';
import { ManagerCustomerUpdateDto } from '@shared/models/manager/manager-customer-update-dto.model';
import { ManagerUpdateDto } from '@shared/models/manager/manager-update-dto.model';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private readonly url: string;

  private readonly isUpdatePhone = new BehaviorSubject<boolean>(null);
  isUpdatePhone$ = this.isUpdatePhone.asObservable();

  constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'manager/')}${environment.versionApi}/`;
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/managers/{managerId}
   * qui retourne les infos du manager connecté
   */
  public readManager(managerId: number): Observable<ManagerDto> {
    return this.http.get<ManagerDto>(`${this.url}managers/${managerId}`);
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/managers/{managerId}
   * qui met à jour les infos du manager connecté
   */
  public updateManager(managerId: number, signature: ManagerUpdateDto, strongAuthentication: string): Observable<any> {
    const path = `${this.url}managers/${managerId}`;
    if (strongAuthentication) {
      const headers = new HttpHeaders({strongAuthentication});
      return this.http.patch(path, signature, {headers});
    }
    return this.http.patch(path, signature);
  }

  // Convertit le managerDto en managerUpdateDto
  public managerUpdateDtoFromManagerDto(managerDto: ManagerDto): ManagerUpdateDto {
    const managerUpdateDto: ManagerUpdateDto = new ManagerUpdateDto();
    managerUpdateDto.title = managerDto.title;
    managerUpdateDto.lastName = managerDto.lastName;
    managerUpdateDto.firstName = managerDto.firstName;
    managerUpdateDto.birthDate = managerDto.birthDate;
    managerUpdateDto.address = managerDto.address;
    managerUpdateDto.mailOptin = managerDto.mailOptin;
    managerUpdateDto.smsOptin = managerDto.smsOptin;
    managerUpdateDto.isCellPhoneNumberChecked = managerDto.isCellPhoneNumberChecked;

    managerUpdateDto.customers = new Array<ManagerCustomerUpdateDto>();
    for (const managerCustomer of managerDto.customers) {
      managerUpdateDto.customers.push(this.managerCustomerUpdateDtoFromManagerCustomerDto(managerCustomer));
    }
    return managerUpdateDto;
  }

  // Convertit le ManagerCustomerUpdateDto en ManagerCustomerDto
  public managerCustomerUpdateDtoFromManagerCustomerDto(managerCustomerDto: ManagerCustomerDto): ManagerCustomerUpdateDto {
    const managerCustomerUpdateDto: ManagerCustomerUpdateDto = new ManagerCustomerUpdateDto();
    managerCustomerUpdateDto.customerId = managerCustomerDto.id;
    if (managerCustomerDto.function) {
      managerCustomerUpdateDto.functionId = managerCustomerDto.function.id;
    }
    return managerCustomerUpdateDto;
  }

  public setIsUpdatePhone(isUpdatePhone: boolean) {
    this.isUpdatePhone.next(isUpdatePhone);
  }
}
