import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cado-button-primary',
  templateUrl: './button-primary.component.html',
  styleUrls: ['./button-primary.component.scss']
})
export class ButtonPrimaryComponent implements OnInit {

  @Input() buttonLabel: any;
  @Input() iconToAdd: string;
  @Input() disabled: boolean;
  @Input() classSurcharge: any;
  @Output() submitted = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  submit(): void {
    this.submitted.emit();
  }
}
