export enum RoutesEnum {
  AUTHENT = 'authent',
  LOGOUT = 'logout',
  SIGNUP = 'signup',
  SUCCESS = 'success',
  FORGOTTEN_PASSWORD = 'reinit-password',
  MY_CADO = 'my-cado',
  MY_ORDERS = 'my-orders',
  MY_PROFILE = 'my-profile',
  CARD_ACTIVATION = 'activation',
  CARD_ACTIVATION_KYC = 'card-kyc',
  CARD_ACTIVATION_STATUS = 'card-status',
  ORDER = 'order',
  BENEFICIARIES = 'beneficiaries',
  PAYMENT = 'payment',
  RETAILERS_ALL_CADO = 'retailers-all-cado',
  RETAILERS_MY_CADO = 'retailers-my-cado',
  MY_PURCHASE_VOUCHER = 'my-purchase-voucher',
  LANDING = 'landing-page',
}
