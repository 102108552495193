import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReferentialService } from '@services/referential/referential.service';
import { ActivatedRoute } from '@angular/router';
import { PageStore } from '@shared/stores/page.store';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';

@Component({
  selector: 'cado-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translate: TranslateService,
    private readonly referentialService: ReferentialService,
    private readonly route: ActivatedRoute,
    private readonly pageStore: PageStore
  ) {
    // Met le langague de l'application sur français
    translate.setDefaultLang('fr');
  }

  ngOnInit(): void {
    // Détection de si l'utilisateur vient de Bimpli,
    // Si l'information n'est pas déjà dans le session storage
    // -> Vérification de l'existence du query param 'fromBimpli' dans l'URL
    this.route.queryParams
      .subscribe(params => {
          // Timeout pour éviter une ExpressionChangedAfterItHasBeenCheckedError
          setTimeout(() => {
            this.pageStore.setIsFromBimpli(params.fromBimpli === 'true' || sessionStorage.getItem(SESSIONSTORAGE.FROM_BIMPLI) === 'true');
          }, 0);
        }
      );

    // On réactive le mode parcours digital si on le trouve dans le sessionstorage
    this.pageStore.setIsFromParcoursDigital(sessionStorage.getItem(SESSIONSTORAGE.PARCOURS_DIGITAL) === 'true');

    // Récupération des paramètres de l'application stockés en base
    this.referentialService.getApplicationParameters();
  }
}

