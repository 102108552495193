import { Injectable } from '@angular/core';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor() {
  }

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });
  }

  public changeConsent(consent: boolean) {
    localStorage.setItem(LOCALSTORAGE.COOKIE_ENABLED, String(consent));
    if (consent) {
      gtag('consent', 'update', {
        analytics_storage: 'granted'
      });
    } else {
      gtag('consent', 'update', {
        analytics_storage: 'denied'
      });
    }
  }
}
