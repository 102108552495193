export const CART = 'cart';
export const NB_ITEMS = 'csv2_nbpanier';
export const IS_CONNECTE = 'csv2_connecte';
export const NOM = 'csv2_name';
export const PRENOM = 'csv2_firstname';
export const PROFIL = 'profil';
export const EDITED_ORDER_ITEM_INDEX = 'editedOrderIndexItem';
export const EDITED_ORDER_ITEM_ID = 'editedOrderIndexId';
export const MERCHANT_FILTERS = 'merchantFilters';
export const AFFILIATE_FILTERS = 'affiliateFilters';
export const CURRENT_PAGE_NAME = 'currentPageName';
export const CARD_ACTIVATION_REQUEST = 'cardActivationRequest';
export const REDIRECTION = 'redirection';
export const HAS_CART = 'hasCart';
export const COOKIE_ENABLED = 'cookieEnabled';
export const AUTHENT_TOKEN = 'authentToken';
