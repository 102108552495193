import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'cado-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading$: Observable<boolean>;

  constructor(
    private readonly loadingScreenService: LoaderService
  ) {
  }

  ngOnInit(): void {
    this.loading$ = this.loadingScreenService.loading$;
  }

}
