import { EntrepriseTypeEnum } from '@shared/enums/entreprise-type.enum';
import { OrderTypeEnum } from '@shared/enums/orders-type.enum';

export class Packagings {
  items: Array<Packaging>;
}

export class Packaging {
  id: number;
  label: string;
  productId: number;
  isDefault: boolean;
  imageURL: string;
  size: string;
  customerType: EntrepriseTypeEnum;
  orderType: OrderTypeEnum;
  amount?: number;
  free = true;
  codePack: string;
}
