import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { DigitalCourseSeller } from '@shared/models/order/digital-course-seller.models';
import { DigitalCourse, DigitalCourseByUser } from '@shared/models/order/digital-course.models';
import { User } from '@shared/models/user/user.model';
import { UserStore } from '@shared/stores/user.store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DigitalCourseStore {
  static readonly UNKNOWN_DIGITAL_COURSE = 'unknown'; // clé de la map pour un parcours digital associé à aucun utilisateur
  digitalCourse$: Observable<DigitalCourseByUser>;
  private readonly digitalCourseSubject: BehaviorSubject<DigitalCourseByUser>;
  private user: User;

  constructor(
    private readonly userStore: UserStore
  ) {
    const digitalCourseConnecte = new Map<string, DigitalCourse>(JSON.parse(localStorage.getItem(SESSIONSTORAGE.ITEM_DIGITAL_COURSE)));
    const digitalCourse = new DigitalCourseByUser();
    digitalCourse.digitalCourses = digitalCourseConnecte;
    this.digitalCourseSubject = new BehaviorSubject<DigitalCourseByUser>(digitalCourse);
    this.digitalCourse$ = this.digitalCourseSubject.asObservable();
    this.user = this.userStore.getCurrentConnectedUser();
  }

  setDigitalCourse(digitalCourse: DigitalCourse): void {
    const digitalCourseByUser = new DigitalCourseByUser();
    // Récupération de la Map user -> digitalCourse
    digitalCourseByUser.digitalCourses = new Map<string, DigitalCourse>(
      JSON.parse(localStorage.getItem(SESSIONSTORAGE.ITEM_DIGITAL_COURSE))
    );
    let key;
    if (!this.user?.id) {
      key = DigitalCourseStore.UNKNOWN_DIGITAL_COURSE;
    } else {
      key = this.user.id;
    }
    const digitalCourses = digitalCourseByUser.digitalCourses;
    // On supprime le parcours associé à personne (même si on le recrée potentiellement après)
    digitalCourses.delete(DigitalCourseStore.UNKNOWN_DIGITAL_COURSE);
    digitalCourses.set(key, digitalCourse);
    digitalCourseByUser.digitalCourses = digitalCourses;
    // On transforme la map en tableaux pour que ça sois supporté par le stringify
    localStorage.setItem(SESSIONSTORAGE.ITEM_DIGITAL_COURSE, JSON.stringify(Array.from(digitalCourseByUser.digitalCourses.entries())));
    this.digitalCourseSubject.next(digitalCourseByUser);
  }

  digitalCourseNotNull$(): Observable<DigitalCourseByUser> {
    return this.digitalCourse$.pipe(filter((u) => !!u));
  }

  getCurrentDigitalCourse(): DigitalCourse {
    this.user = this.userStore.getCurrentConnectedUser();
    const digitalCourses = this.digitalCourseSubject.value;
    // Le dernier digitalCourse enregistré hors connexion est en théorie le dernier parcours à jour
    const lastDigitalCourse = digitalCourses?.digitalCourses.get('unknown');
    // Si on a un parcours créé hors connexion on l'applique à l'utilisateur courant si présent
    if (lastDigitalCourse) {
      if (this.user?.id) {
        digitalCourses?.digitalCourses.delete('unknown');
        digitalCourses?.digitalCourses.set(this.user.id, lastDigitalCourse);
      }
      localStorage.setItem(SESSIONSTORAGE.ITEM_DIGITAL_COURSE, JSON.stringify(Array.from(digitalCourses.digitalCourses.entries())));
      return lastDigitalCourse;
    } else if (this.user?.id) {
      return digitalCourses?.digitalCourses.get(this.user.id);
    }
    return null;
  }

  /***
   * parse les information de la route pour determiner les parametres a mettre en cache
   */
  parseQueryParamMap(queryParamMap: Params): DigitalCourse {

    // Parametre de routage null --> Aucun parametre
    if (!queryParamMap) {
      return null;
    }

    // utm_source=ce_rhonealpes_344&utm_medium=emailopfetedesparents_C&utm_campaign=FCE21&utm_content=vi_895&utm_term=YYYY
    let digitalCourse = null;

    // Donne lu via l'url
    // Code etablisement
    let establishmentCode = null;
    let operationCode = null;
    let discountCode = null;
    let vendorEstablishmentCode = null;
    let vendorCode = null;

    // Utm_source : établissement du Chargé de clientèle
    // Si utm_source est renseigné,
    // extraire la valeur : par exemple dans ce_rhonealpes_344 : on prend juste la fin (344) pour le code etab
    // alimentation du establishmentCode directement dans l'appel à updateCart
    if (queryParamMap.has('utm_source')) {
      // utm_source=ce_rhonealpes_344 - A decouper sur les _
      const utmSourceParts = queryParamMap.get('utm_source').split('_');
      establishmentCode = utmSourceParts[utmSourceParts.length - 1];
    }

    // utm_medium : Code operation
    if (queryParamMap.has('utm_medium')) {
      // utm_medium=emailopfetedesparents_C
      operationCode = queryParamMap.get('utm_medium');
    }

    // utm_campaign : Code promotionel
    if (queryParamMap.has('utm_campaign')) {
      // utm_campaign=FCE21
      discountCode = queryParamMap.get('utm_campaign');
    }

    // Utm_content : établissement du Vendeur
    // Si Utm_content est renseigné,
    // extraire la valeur : par exemple dans vi_895 : on prend juste la fin (895) pour le code etab
    // alimentation du vendorEstablishmentCode directement dans l'appel à updateCart
    if (queryParamMap.has('utm_content')) {
      // utm_content=vi_895 - A decouper sur les _
      const utmContentParts = queryParamMap.get('utm_content').split('_');
      vendorEstablishmentCode = utmContentParts[utmContentParts.length - 1];
    }

    // Utm_term: matricule/trigramme du Vendeur
    if (queryParamMap.has('utm_term')) {
      digitalCourse = digitalCourse != null ? digitalCourse : new DigitalCourse();
      // utm_term=YYYY
      vendorCode = queryParamMap.get('utm_term');
    }

    // Pour avoir une info parcours digital valide, on doit avoir le code établissement,
    // donc le cas contraire, celle ci ne peuvent être remplis car incomplete
    if (establishmentCode != null) {
      digitalCourse = new DigitalCourse();
      digitalCourse.seller = new DigitalCourseSeller();
      digitalCourse.seller.establishmentCode = establishmentCode;
      // Si on n'a pas recu de code etablisement vendeur, celui ci est remplis par le code etablissement
      digitalCourse.seller.vendorEstablishmentCode = vendorEstablishmentCode != null ? vendorEstablishmentCode : establishmentCode;
      digitalCourse.seller.vendorCode = vendorCode;
    }

    // Discount
    if (discountCode != null) {
      digitalCourse = digitalCourse != null ? digitalCourse : new DigitalCourse();
      digitalCourse.discountCode = discountCode;
    }

    // Code operation
    if (operationCode != null) {
      digitalCourse = digitalCourse != null ? digitalCourse : new DigitalCourse();
      digitalCourse.operationCode = operationCode;
    }

    return digitalCourse;
  }

}
