import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApplicationParametersDto } from '@shared/models/api/referential/application-parameters-dto.model';
import { CustomerProvenancesDto } from '@shared/models/api/referential/customer-provenances-dto.model';
import { ContactFunctions } from '@shared/models/common/contactFunctions.model';
import { BankAccountDto } from '@shared/models/referential/bank-account-dto.model';
import { CitiesDto } from '@shared/models/referential/cities-dto.model';
import { ReadCitiesSignature } from '@shared/models/referential/signature/read-cities-signature.model';
import { SocioEconomicCategoriesDto } from '@shared/models/referential/signature/socio-economic-categories-dto.model';
import { Observable } from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root',
})
export class ReferentialControllerService {
  private readonly url: string;

  constructor(readonly http: HttpClient) {
    this.url = `/CADO/${(environment.localApi ? '' : 'referential/')}${environment.versionApi}/`;
  }

  public readFunctions(): Observable<ContactFunctions> {
    return this.http.get<ContactFunctions>(this.url + 'functions');
  }

  public readBankAccount(): Observable<BankAccountDto> {
    return this.http.get<BankAccountDto>(this.url + 'bankAccount');
  }

  public readCustomerProvenances(): Observable<CustomerProvenancesDto> {
    return this.http.get<CustomerProvenancesDto>(this.url + 'customerProvenances');
  }

  public readCities(signature: ReadCitiesSignature): Observable<CitiesDto> {
    let currentUrl = this.url;
    currentUrl += 'cities';

    if (signature.city || signature.postalCode || signature.sort || signature.type) {
      currentUrl += '?';
    }
    if (signature.city) {
      currentUrl += '&city=' + signature.city;
    }
    if (signature.postalCode) {
      currentUrl += '&postalCode=' + signature.postalCode;
    }
    if (signature.sort) {
      currentUrl += '&sort=' + signature.sort;
    }
    if (signature.type) {
      currentUrl += '&type=' + signature.type;
    }

    return this.http.get<CitiesDto>(currentUrl);
  }

  public readSocioProfessionalCategories(): Observable<SocioEconomicCategoriesDto> {
    return this.http.get<SocioEconomicCategoriesDto>(this.url + 'socioProfessionalCategories');
  }

  public readApplicationParameters(): Observable<ApplicationParametersDto> {
    return this.http.get<ApplicationParametersDto>(this.url + 'applicationParameters');
  }
}
