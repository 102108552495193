import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { CartService } from '@services/cart.service';
import { UrlService } from '@services/miscellaneous/url.service';
import { User } from '@shared/models/user/user.model';
import { UserStore } from '@shared/stores/user.store';
import { AuthentificationService } from 'nit-angular-lib';
import { Observable, Subscription } from 'rxjs';
import { MenuMobileService } from './menu-mobile.service';
import { tap } from 'rxjs/operators';
import { PageStore } from '@shared/stores/page.store';
import { UserTypeEnum } from '@shared/enums/user-type.enum';

@Component({
  selector: 'cado-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnInit {
  backUrl$: Observable<string>;
  pageName: PageNameEnum;
  PageNameEnum = PageNameEnum;
  UserTypeEnum = UserTypeEnum;
  name = '';
  nameSubscription: Subscription;
  user: User;
  cartServiceSubscription: Subscription;
  cartItemsCount: number;
  isParcoursDigital = false;
  showMenuButtons$: Observable<boolean>;

  constructor(
    private readonly cartService: CartService,
    private readonly authenticationService: AuthentificationService,
    private readonly menuService: MenuDesktopService,
    private readonly router: Router,
    private readonly urlService: UrlService,
    private readonly userStore: UserStore,
    private readonly menuMobileService: MenuMobileService,
    private readonly pageStore: PageStore,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.pageStore.isParcoursDigital$
      .pipe(
        tap((isParcoursDigital) => {
          this.isParcoursDigital = isParcoursDigital;
        }),
      ).subscribe();

    this.userStore.userConnected$()
      .pipe(
        tap(user => this.user = user)
      ).subscribe();

    this.pageStore.currentPage$
      .pipe(
        tap((pageName) => {
          this.pageName = pageName;
        })
      ).subscribe();

    this.backUrl$ = this.menuMobileService.backUrl$;

    this.nameSubscription = this.menuService.nameValue.subscribe((value) => {
      this.name = value;
    });

    if (this.authenticationService.getToken() && this.user) {
      this.menuService.showUserConnectedWithName(`${this.user.identity.firstName} ${this.user.identity.lastName}`);
    }

    this.cartItemsCount = 0;
    // Souscription à la mise à jour du Panier : on met à jour le nombre d'items du panier
    this.cartServiceSubscription = this.cartService.cart$.subscribe(
      (cartElement) => {
        if (!cartElement) {
          // On recalcule le nombre d'articles dans le panier
          this.cartItemsCount = 0;
        } else {
          // On recalcule le nombre d'articles dans le panier
          this.cartItemsCount = cartElement.itemsCount;
          // On met à jour le local storage avec le panier
        }
      },
      (error) => {
        // Traitement des erreurs
      },
      () => {
        // Traitement de fin
      }
    );

    this.showMenuButtons$ = this.menuService.showMenuButtons$();
  }

  onMyCadoClick(): void {
    this.router.navigateByUrl('/my-cado');
  }

  onMyProfileClick(): void {
    this.router.navigateByUrl('/my-profile');
  }

  onMyOrdersClick(): void {
    this.router.navigateByUrl('/my-orders');
  }

  goToOrder(): void {
    // Si l'utilisateur a déjà un panier en base, on passe directement au paiement
    // Si ce n'est pas le cas, on redirige vers la composition du panier
    if (sessionStorage.getItem('hasCart') === '1') {
      this.router.navigate(['payment']);
    } else if (this.cartService.hasCurrentCart()) {
      this.router.navigate(['order']);
    } else if (this.isParcoursDigital === true) {
      if (this.pageName !== PageNameEnum.LANDING_PAGE) {
        this.router.navigate(['landing-page']);
      }
    } else {
      this.redirectWpCart();
    }
  }

  redirectHome(): void {
    this.urlService.redirectToSiteVitrine('', true);
  }

  redirectProduits(): void {
    this.urlService.redirectToSiteVitrine('produits-cado', true);
  }

  redirectWpCart(): void {
    const user = this.userStore.getCurrentConnectedUser();
    const page = user ? 'panier-vide-connecte' : 'panier-non-connecte';
    this.urlService.redirectToSiteVitrine(page, true);
  }
}
