import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cado-button-activate',
  templateUrl: './button-activate.component.html',
  styleUrls: ['./button-activate.component.scss']
})
export class ButtonActivateComponent implements OnInit {

  constructor(
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
  }

  activation(): void {
    this.router.navigateByUrl('/activation');
  }
}
