import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { StrongAuthentication } from '@shared/models/api/strong-authentication/strong-authentication.model';
import { Observable } from 'rxjs';
import { CheckStrongAuthenticationRequest } from './signatures/check-strong-authentication-request.model';
import { CheckStrongAuthenticationResponse } from './signatures/check-strong-authentication-response.model';
import { StrongAuthenticationCreate } from './signatures/strong-authentication-create.model';
import { StrongAuthentOriginEnum } from '@shared/models/api/enums/strong-authent-origin.enum';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';

@Injectable({
  providedIn: 'root',
})
export class StrongAuthenticationControllerService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'userIDP/')}${environment.versionApi}`;
  }

  createStrongAuthentication$(body: StrongAuthenticationCreate, strongAuthentOrigin: StrongAuthentOriginEnum): Observable<StrongAuthentication> {
    const isSudo = 'true' === sessionStorage.getItem(SESSIONSTORAGE.IS_SUDO);
    const path = `${this.url}/strongAuthentications?strongAuthentOrigin=${strongAuthentOrigin}${isSudo ? '&isSudo=true' : ''}`;
    return this.http.post<StrongAuthentication>(path, body);
  }

  checkStrongAuthentication$(strongAuthenticationId: number,
                             body: CheckStrongAuthenticationRequest, isAuthent?: boolean): Observable<CheckStrongAuthenticationResponse> {
    let path = `${this.url}/strongAuthentications/${strongAuthenticationId}/verifications`;
    if (isAuthent) {
      path = path.concat('?isAuthent=true');
    }
    return this.http.post<CheckStrongAuthenticationResponse>(path, body);
  }

}
