<cado-modal id="popupPhoneInput">
    <ng-container *ngIf="refresh$ | async">
        <cado-modal-header
            (modalClose)="close()"
            [ngClass]="'d-flex justify-content-center regularline'"
            [titleModal]="'TOKEN.PHONE_TITLE' | translate"
        ></cado-modal-header>
        <div *ngIf="!strongAuthentication && !isPhoneEdition" class="card-title-secondary boldline d-flex mt-4">
            {{ "TOKEN.PHONE_ADD" | translate }}
        </div>
        <div *ngIf="!strongAuthentication && isPhoneEdition" class="card-title-secondary boldline d-flex mt-4">
            {{ "TOKEN.PHONE_UPDATE" | translate }}
        </div>
        <div *ngIf="strongAuthentication" class="card-title-secondary boldline d-flex mt-4">
            {{ "TOKEN.PHONE_VERIFY" | translate }}
        </div>
        <form [formGroup]="phoneInfosForm" class="mt-4">
            <div class="d-flex">
                <div>
                    <input
                        (keypress)="numericPhoneOnly($event)"
                        formControlName="phone"
                        id="phone"
                        maxlength="10"
                        name="phone"
                        placeholder="{{this.managerPhoneDto?.cellPhoneNumber || this.managerPhoneDto?.phoneNumber
                                       ? this.managerPhoneDto?.cellPhoneNumber || this.managerPhoneDto?.phoneNumber
                                       : 'TOKEN.PHONE_INPUT' | translate}}"
                        type="tel"
                    />
                </div>
                <div class="ml-2">
                    <cado-button-primary
                        (submitted)="sendOTP()"
                        *ngIf="phoneInfosForm.get('phone').enabled"
                        [buttonLabel]="'BUTTON.CHECK' | translate"
                        [classSurcharge]="'fill-space'"
                        [disabled]="phoneInfosForm.get('phone').invalid"
                    ></cado-button-primary>
                    <cado-button-primary
                        (submitted)="resetForm()"
                        *ngIf="phoneInfosForm.get('phone').disabled"
                        [buttonLabel]="'BUTTON.EDIT' | translate"
                        [classSurcharge]="'fill-space'"
                        [disabled]="phoneInfosForm.get('phone').invalid"
                    ></cado-button-primary>
                </div>
            </div>
        </form>
        <div class="d-flex mt-4 boldline font-weight-bold">
            {{ "TOKEN.PHONE_LINE1" | translate }}
        </div>
        <div class="d-flex mt-4 regularline">
            {{ "TOKEN.PHONE_LINE2" | translate }}
        </div>
        <div class="d-flex mt-4 regularline">
            {{'TOKEN.PHONE_LINE_WARNING' | translate}}
        </div>
        <div class="d-flex mt-4 mb-4 regularline">
            {{ "TOKEN.PHONE_LINE3" | translate }}
        </div>

        <form (ngSubmit)="savePhone()" *ngIf="strongAuthentication" [formGroup]="otpForm">
            <div class="d-flex flex-column justify-content-center">
                <div class="regularline mb-4">
                    <span>
                        {{ "TOKEN.PHONE_LINE4" | translate }}
                        <a (click)="createStrongAuthentication(StrongAuthentOriginEnum.TEL_UPDATE_MAIL)" class="link cado-link"
                           role="button">{{ "TOKEN.PHONE_LINE_HERE" | translate }}</a>
                        {{ "TOKEN.PHONE_LINE5" | translate }}
                    </span>
                </div>
                <input (input)="numericPhoneOnly($event)" [showMaskTyped]="true" formControlName="phoneCode" id="idInputPhoneCode"
                       mask="0 0 0 0 0 0" placeHolderCharacter="x"/>
                <div *ngIf="(otpForm.get('phoneCode').dirty || otpForm.get('phoneCode').touched) &&
                        otpForm.get('phoneCode').errors" class="error-container">
                    <small *ngIf="otpForm.get('phoneCode').errors.minlength || otpForm.get('phoneCode').errors.maxlength"
                           class="error">
                        {{ "RETAILER.PURCHASE_VOUCHER.ERROR_PHONE_CODE_LENGTH" | translate }}
                    </small>
                    <small *ngIf="otpForm.get('phoneCode').errors.invalidCode" class="error">
                        {{ "RETAILER.PURCHASE_VOUCHER.ERROR_PHONE_CODE_INVALID" | translate }}
                    </small>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-2">
                {{ "RETAILER.PURCHASE_VOUCHER.CODE_NOT_RECEIVE" | translate }}
            </div>

            <div class="d-flex justify-content-center mb-4">
                <a href=""></a>
                <a (click)="createStrongAuthentication(StrongAuthentOriginEnum.TEL_UPDATE)" class="link cado-link"
                   role="button">{{ "RETAILER.PURCHASE_VOUCHER.RESEND_SMS" | translate }}</a>
            </div>
            <cado-button-primary
                [buttonLabel]="'BUTTON.SAVE' | translate"
                [classSurcharge]="'fixed-size mb-4'"
                [disabled]="otpForm.invalid">
            </cado-button-primary>
        </form>
        <cado-button-secondary
            (click)="close()"
            *ngIf="isPhoneEdition"
            [buttonLabel]="'BUTTON.CANCEL' | translate"
            [classSurcharge]="'fixed-size'"
        ></cado-button-secondary>
        <cado-button-secondary
            (click)="back()"
            *ngIf="!isPhoneEdition && updateMode"
            [buttonLabel]="'BUTTON.RETURN' | translate"
            [classSurcharge]="'fixed-size'"
        ></cado-button-secondary>
    </ng-container>
</cado-modal>
