import { Injectable } from '@angular/core';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManagerStore {
  private readonly managerSubject = new BehaviorSubject<ManagerDto>(null);
  manager$: Observable<ManagerDto> = this.managerSubject.asObservable();

  constructor() {
  }

  setManager(manager: ManagerDto) {
    this.managerSubject.next(manager);
  }
}
