import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cado-link-two-rows',
  templateUrl: './link-two-rows.component.html',
  styleUrls: ['./link-two-rows.component.scss']
})
export class LinkTwoRowsComponent implements OnInit {

  @Input() row1: string;
  @Input() row2: string;
  @Output() submitted = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  submit(): void {
    this.submitted.emit();
  }

}
