import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PurchaseTypeEnum } from '@shared/enums/purchase-type.enum';
import { AffiliateSummariesDto } from '@shared/models/api/merchant/affiliate-summaries-dto.model';
import { AffiliationProductsDto } from '@shared/models/api/merchant/affiliation-products-dto.model';
import { CategoriesDto } from '@shared/models/api/merchant/categories-dto.model';
import { MerchantAffiliateDto } from '@shared/models/api/merchant/merchant-affiliate-dto.model';
import { MerchantAffiliatesDto } from '@shared/models/api/merchant/merchant-affiliates-dto.model';
import { MerchantDto } from '@shared/models/api/merchant/merchant-dto';
import { MerchantSummariesDto } from '@shared/models/api/merchant/merchant-summaries-dto.model';
import { RetailersAffiliateCriteriaDto } from '@shared/models/retailers/retailers-affiliate-criteria-dto.model';
import { RetailersMerchantCriteriaDto } from '@shared/models/retailers/retailers-merchant-criteria-dto.model';
import { Observable } from 'rxjs';
import { ReadMerchantsByCriteriaInput } from './signatures/read-merchants-by-criteria-input';

@Injectable({
  providedIn: 'root',
})
export class MerchantControllerService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'merchant/')}${environment.versionApi}`;
  }

  /** retourne true si il y a au moins un critère */
  static checkReadMerchantsAtLeastOneCriterion(criteres: ReadMerchantsByCriteriaInput): boolean {
    return !!(
      criteres &&
      (criteres.merchantType ||
        criteres.purchaseType ||
        criteres.isPurchaseVoucherAcceptedOnly !== undefined ||
        criteres.categories ||
        criteres.productId ||
        criteres.label ||
        criteres.pageSize ||
        criteres.pageNumber ||
        criteres.sort)
    );
  }

  public readMerchantCategories(): Observable<CategoriesDto> {
    let currentUrl = this.url;
    currentUrl += '/categories';

    return this.http.get<CategoriesDto>(currentUrl);
  }

  public readAffiliationProducts(): Observable<AffiliationProductsDto> {
    let currentUrl = this.url;
    currentUrl += '/affiliationProducts';

    return this.http.get<AffiliationProductsDto>(currentUrl);
  }

  public readMerchantsByCriteria(signature: ReadMerchantsByCriteriaInput): Observable<MerchantSummariesDto> {
    let currentUrl = this.url;
    currentUrl += '/merchants';

    // on vérifie si il y a des critères
    let params = new HttpParams();
    if (MerchantControllerService.checkReadMerchantsAtLeastOneCriterion(signature)) {
      if (signature.merchantType) {
        params = params.set('merchantType', signature.merchantType);
      }
      if (signature.purchaseType) {
        params = params.set('purchaseType', signature.purchaseType);
      }
      // On veut ajouter ce paramètre si le booléen est true ou false, pas s'il est null ou undefined, or null == undefined
      if (signature.isPurchaseVoucherAcceptedOnly !== undefined) {
        params = params.set('isPurchaseVoucherAcceptedOnly', String(!!signature.isPurchaseVoucherAcceptedOnly));
      }
      if (signature.categories) {
        params = params.set('categories', signature.categories);
      }
      if (signature.productId) {
        params = params.set('productId', signature.productId);
      }
      if (signature.label) {
        params = params.set('label', encodeURIComponent(signature.label));
      }
      if (signature.useCategoryId?.length > 0) {
        signature.useCategoryId.forEach((useCategory) => (params = params.set('useCategoryId', String(useCategory))));
      }
      if (signature.pageSize) {
        params = params.set('pageSize', String(signature.pageSize));
      }
      if (signature.pageNumber) {
        params = params.set('pageNumber', String(signature.pageNumber));
      }
      if (signature.sort) {
        params = params.set('sort', signature.sort);
      }
    }

    return this.http.get<MerchantSummariesDto>(currentUrl, {params});
  }

  readMerchantById(merchantId: string): Observable<MerchantDto> {
    const currentUrl = `${this.url}/merchants/${merchantId}`;
    return this.http.get<MerchantDto>(currentUrl);
  }

  readMerchantAffiliates(merchantId: string, pageSize: number, pageNumber: number): Observable<MerchantAffiliatesDto> {
    const currentUrl = `${this.url}/merchants/${merchantId}/affiliates`;
    let params = new HttpParams().set('pageSize', String(pageSize));
    params = params.set('pageNumber', String(pageNumber));
    return this.http.get<MerchantAffiliatesDto>(currentUrl, {params});
  }

  readMerchantAffiliate(merchantId: string, affiliateId: string): Observable<MerchantAffiliateDto> {
    const currentUrl = `${this.url}/merchants/${merchantId}/affiliates/${affiliateId}`;
    return this.http.get<MerchantAffiliateDto>(currentUrl);
  }

  readAffiliateByCriteria(
    merchantCriteria: RetailersMerchantCriteriaDto,
    affiliateCriteria: RetailersAffiliateCriteriaDto
  ): Observable<AffiliateSummariesDto> {
    const currentUrl = `${this.url}/affiliates`;

    let params = new HttpParams();
    if (merchantCriteria.merchantType) {
      params = params.set('merchantType', merchantCriteria.merchantType);
    }
    if (merchantCriteria.purchaseType) {
      params = params.set('purchaseOnline', Boolean(merchantCriteria.purchaseType === PurchaseTypeEnum.INTERNET).toString());
    }
    if (merchantCriteria.categories) {
      params = params.set('categories', merchantCriteria.categories);
    }
    if (merchantCriteria.carouselProduct) {
      params = params.set('productId', merchantCriteria.carouselProduct.productFamily);
    }
    if (merchantCriteria.label) {
      params = params.set('label', merchantCriteria.label);
    }
    if (affiliateCriteria.localization) {
      params = params.set('place', affiliateCriteria.localization);
    }
    if (affiliateCriteria.pageSize) {
      params = params.set('pageSize', affiliateCriteria.pageSize.toString());
    }
    if (affiliateCriteria.pageNumber) {
      params = params.set('pageNumber', affiliateCriteria.pageNumber.toString());
    }
    if (affiliateCriteria.sort) {
      params = params.set('sort', affiliateCriteria.sort);
    }
    return this.http.get<AffiliateSummariesDto>(currentUrl, {params});
  }
}
