import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuMobileService {

  // URL sur laquelle retourner, spécifiée depuis la page en cours
  // et purgée à la destruction du composant
  private readonly backUrlSubject = new BehaviorSubject<string>(null);
  backUrl$ = this.backUrlSubject.asObservable();

  // URL ayant amené à la page actuelle
  // Cette URL doit être renseignée avant la redirection vers la page actuelle
  private readonly originatesFromSubject = new BehaviorSubject<string>(null);
  originatesFrom$ = this.originatesFromSubject.asObservable();

  setBackUrl(urlToGoBack: string) {
    this.backUrlSubject.next(urlToGoBack);
  }

  resetBackUrl() {
    this.backUrlSubject.next(null);
  }

  setOriginatesFrom(originUrl: string) {
    this.originatesFromSubject.next(originUrl);
  }

  getOriginatesFrom(): string {
    return this.originatesFromSubject.value;
  }

  resetOriginatesFrom() {
    this.originatesFromSubject.next(null);
  }

}
