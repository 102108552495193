<a (click)="submit()" *ngIf="!internalLinkUrl && !externalLinkUrl" [class.disabled]="disabled"
   class="d-flex link cado-link align-items-center">
    <em *ngIf="iconToAddBefore" [ngClass]="iconToAddBefore" class="icon-link before"></em>
    <span class="d-flex justify-content-center align-items-center">{{linkLabel}}</span>
    <em *ngIf="iconToAddAfter" [ngClass]="iconToAddAfter" class="icon-link after"></em>
</a>

<a *ngIf="externalLinkUrl" [class.disabled]="disabled" [href]="externalLinkUrl" [target]="newTab ? '_blank' : '_self'"
   class="d-flex link cado-link align-items-center">
    <em *ngIf="iconToAddBefore" [ngClass]="iconToAddBefore" class="icon-link before"></em>
    <span class="d-flex justify-content-center align-items-center">{{linkLabel}}</span>
    <em *ngIf="iconToAddAfter" [ngClass]="iconToAddAfter" class="icon-link after"></em>
</a>

<a *ngIf="internalLinkUrl" [class.disabled]="disabled" [routerLink]="internalLinkUrl" class="d-flex link cado-link align-items-center">
    <em *ngIf="iconToAddBefore" [ngClass]="iconToAddBefore" class="icon-link before"></em>
    <span class="d-flex justify-content-center align-items-center">{{linkLabel}}</span>
    <em *ngIf="iconToAddAfter" [ngClass]="iconToAddAfter" class="icon-link after"></em>
</a>
