<div *ngIf="refresh$ | async" class="d-flex sous-menu">
    <div class="div-btn d-flex">
        <ng-container *ngIf="!isRetailers">
            <div
                (click)="onMyCadoClick();scroll('cartes')"
                *ngIf="user && user.type === UserTypeEnum.INDIVIDUAL"
                [ngClass]="{ pageSelected: pageName === PageNameEnum.MY_CADO }"
                class="btn d-flex" id="cartes"
            >
                <span class="justify-content-center align-items-center">{{ "SOUS_MENU.MY_CADO" | translate }} </span>
            </div>

            <div
                (click)="goToMyPurchaseVoucher();scroll('voucher')"
                *ngIf="user && user.type === UserTypeEnum.INDIVIDUAL"
                [ngClass]="{ pageSelected: pageName === PageNameEnum.MY_PURCHASE_VOUCHER }"
                class="btn d-flex" id="voucher"
            >
                <span class="justify-content-center align-items-center">{{ "SOUS_MENU.MY_PURCHASE_VOUCHER" | translate }}</span>
            </div>

            <!-- On ne montre le menu myOrders qu'aux Professionels ou au particuliers avec commande -->
            <div
                (click)="onMyOrdersClick();scroll('commande')"
                *ngIf="user && (user.type === UserTypeEnum.PROFESSIONAL || this.showPartMyOrdersMenu)"
                [ngClass]="{ pageSelected: pageName === PageNameEnum.MY_ORDERS }"
                class="btn d-flex" id="commande"
            >
                <span class="justify-content-center align-items-center">{{ "SOUS_MENU.MY_ORDERS" | translate }}</span>
            </div>

            <div (click)="onMyProfileClick();scroll('profil')" [ngClass]="{ pageSelected: pageName === PageNameEnum.MY_PROFILE }"
                 class="btn d-flex" id="profil">
                <span class="justify-content-center align-items-center">{{ "SOUS_MENU.MY_PROFILE" | translate }}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="isRetailers">
            <div
                (click)="onAllCadoRetailersClick()"
                [ngClass]="{ pageSelected: pageName === PageNameEnum.ALL_CADO_RETAILERS }"
                class="btn d-flex"
            >
                <em class="icon-cart-stroke icon-menu justify-content-center align-items-center"></em>
                <span class="justify-content-center align-items-center"> {{ "SOUS_MENU.ALL_CADO" | translate }}</span>
            </div>

            <div
                (click)="onMyCadoRetailersClick()"
                *ngIf="!isPro()"
                [ngClass]="{ pageSelected: pageName === PageNameEnum.MY_CADO_RETAILERS }"
                class="btn d-flex"
            >
                <em class="icon-gift-stroke icon-menu justify-content-center align-items-center"></em>
                <span class="justify-content-center align-items-center">{{ "SOUS_MENU.MY_CADO" | translate }}</span>
            </div>
        </ng-container>
    </div>
</div>
