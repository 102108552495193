import { OrderDeliveryTypeEnum } from '@shared/enums/order-delivery-type.enum';
import { AbstractOrderData } from '../abstract-order-data.model';
import { ProductItemOrderEstimate } from './product-item-order-estimate.model';

export class OrderData extends AbstractOrderData {
  deliveryType: OrderDeliveryTypeEnum;
  productItems: Array<ProductItemOrderEstimate>;
  carrierId: number;
  discountVoucher: string;
}
