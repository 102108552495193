import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@services/cart.service';
import { ToastService } from '@shared/components/toast/toast.service';
import { Order } from '@shared/models/create-order/order.model';
import { DigitalCourse } from '@shared/models/order/digital-course.models';
import { DigitalCourseStore } from '@shared/stores/digital-course.store';
import { Observable } from 'rxjs/internal/Observable';
import { take, tap } from 'rxjs/operators';
import { ProductTypeEnum } from '../../enums/product-type.enum';
import { Cart } from '../../models/create-order/cart.model';
import { ConnectService } from './connect.service';

@Injectable({providedIn: 'root'})
export class OrderResolver implements Resolve<Order> {

  constructor(
    private readonly connectService: ConnectService,
    private readonly cartService: CartService,
    private readonly digitalCourseStore: DigitalCourseStore,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return this.cartService.cart$.pipe(
      take(1),
      tap((cart: Cart) => {
        let paramproduit = !!route.queryParams.productcode ? route.queryParams.productcode : route.queryParams.productCode;

        //  Recuper les parametres parcour digitaux present dans l'URL
        let digitalCourse = DigitalCourse.parseQueryParamMap(route.queryParamMap);

        // Si on n'a pas un parcours digital dans l'URL, on récupère celui du local storage
        if (digitalCourse === null) {
          digitalCourse = this.digitalCourseStore.getCurrentDigitalCourse();
        }

        // Stockage de l'info
        this.digitalCourseStore.setDigitalCourse(digitalCourse);

        // On récupère le produit du cart si le cart n'est pas vide, ou si aucun produit n'a été spécifié
        if (!!cart && !!cart.order && (cart.itemsCount > 0 || !paramproduit)) {
          const cartProduct = ProductTypeEnum[cart.order.productType]?.toLowerCase();
          if ((!!paramproduit && cartProduct === paramproduit) || cart.itemsCount > 0) {
            paramproduit = cartProduct;
          }
        }
        this.connectService.initUser(route.queryParams.profil, paramproduit);
      })
    );
  }
}
