import { Order } from '@shared/models/create-order/order.model';
import { UserTypeEnum } from '../../enums/user-type.enum';

// Un panier, c'est une commande + son nombre d'items + son montant total
// et son type utilisateur de création ...
export class Cart {
  order: Order;
  itemsCount: number;
  cartAmount: number;
  isEditionMode: boolean;
  typeUserHC: UserTypeEnum;

}
