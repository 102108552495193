import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CardsActivationRequestIdDto } from '@shared/models/api/card/cards-activation-request-id-dto.model';
import { PurchaseVoucherId } from '@shared/models/api/purchase-voucher/purchase-voucher-id.model';
import { PurchaseVoucherReservationCreate } from '@shared/models/api/purchase-voucher/purchase-voucher-reservation-create.model';
import { PurchaseVoucherReservationId } from '@shared/models/api/purchase-voucher/purchase-voucher-reservation-id.model';
import { PurchaseVoucher } from '@shared/models/api/purchase-voucher/purchase-voucher.model';
import { PurchaseVouchers } from '@shared/models/api/purchase-voucher/purchase-vouchers.model';
import { Observable } from 'rxjs';
import { ReadIndividualPurchaseVoucherInput } from './signatures/read-individual-purchase-voucher-input.model';

@Injectable({
  providedIn: 'root',
})
export class PurchaseVoucherControllerService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'purchaseVoucher/')}${environment.versionApi}`;
  }

  readIndividualPurchaseVouchers(input: ReadIndividualPurchaseVoucherInput): Observable<PurchaseVouchers> {
    const path = `${this.url}/individuals/${input.individualId}/purchaseVouchers`;
    let params = new HttpParams();
    if (input.pageSize) {
      params = params.set('pageSize', String(input.pageSize));
    }
    if (input.pageNumber) {
      params = params.set('pageNumber', String(input.pageNumber));
    }
    if (input.sort) {
      params = params.set('sort', input.sort);
    }
    return this.http.get<PurchaseVouchers>(path, {params});
  }

  initPurchaseVoucher(
    signature: PurchaseVoucherReservationCreate,
    individualId: number | string
  ): Observable<PurchaseVoucherReservationId> {
    const path = `${this.url}/individuals/${individualId}/purchaseVoucherReservations`;
    return this.http.post<PurchaseVoucherReservationId>(path, signature);
  }

  readPurchaseVoucherDocument(purchaseVoucherId: number): Observable<Blob> {
    const path = `${this.url}/purchaseVouchers/${purchaseVoucherId}/document`;

    return this.http.get(path, {responseType: 'blob'});
  }

  confirmPurchaseVoucher(
    individualId: number | string,
    purchaseVoucherReservationId: number,
    strongAuthentication?: string
  ): Observable<PurchaseVoucherId> {
    const path = `${this.url}/individuals/${individualId}/purchaseVoucherReservations/${purchaseVoucherReservationId}`;
    if (strongAuthentication) {
      const headers = new HttpHeaders({strongAuthentication});
      return this.http.put<CardsActivationRequestIdDto>(path, {}, {headers});
    }
    return this.http.put<PurchaseVoucherId>(path, {});
  }

  readPurchaseVoucher$(purchaseVoucherId: string): Observable<PurchaseVoucher> {
    const path = `${this.url}/purchaseVouchers/${purchaseVoucherId}`;
    return this.http.get<PurchaseVoucher>(path);
  }
}
