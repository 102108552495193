import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LoaderService } from '@layouts/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MerchantControllerService } from '@services/ws/merchant/merchant-controller.service';
import { ReadMerchantsByCriteriaInput } from '@services/ws/merchant/signatures/read-merchants-by-criteria-input';
import * as LOCAL_STORAGE from '@shared/constants/local-storage.constants';
import { UserCardDto } from '@shared/models/api/card/user-card-dto.model';
import { AffiliateSummariesDto } from '@shared/models/api/merchant/affiliate-summaries-dto.model';
import { CategoriesDto } from '@shared/models/api/merchant/categories-dto.model';
import { CategoryDto } from '@shared/models/api/merchant/category-dto.model';
import { MerchantAffiliateDto } from '@shared/models/api/merchant/merchant-affiliate-dto.model';
import { MerchantAffiliatesDto } from '@shared/models/api/merchant/merchant-affiliates-dto.model';
import { MerchantDto } from '@shared/models/api/merchant/merchant-dto';
import { MerchantSummariesDto } from '@shared/models/api/merchant/merchant-summaries-dto.model';
import { MerchantSummaryDto } from '@shared/models/api/merchant/merchant-summary-dto.model';
import { RetailersAffiliateCriteriaDto } from '@shared/models/retailers/retailers-affiliate-criteria-dto.model';
import { RetailersMerchantCriteriaDto } from '@shared/models/retailers/retailers-merchant-criteria-dto.model';
import { RetailersStore } from '@shared/stores/retailers.store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RetailersService {
  affiliateCriteria$: Observable<RetailersAffiliateCriteriaDto>;
  // Les catégories, avec l'url de la bannière
  private readonly categoriesSubject = new BehaviorSubject<CategoryDto[]>(null);
  categories$: Observable<CategoryDto[]> = this.categoriesSubject.asObservable();
  // Les filtres sélectionnés, avec la pagination
  private readonly affiliateCriteriaSubject: BehaviorSubject<RetailersAffiliateCriteriaDto>;

  // la liste des affiliés à afficher
  private readonly affiliateListSubject = new BehaviorSubject<AffiliateSummariesDto>(null);
  affiliateList$: Observable<AffiliateSummariesDto> = this.affiliateListSubject.asObservable();

  // le marchand à afficher
  private readonly merchantSubject = new BehaviorSubject<MerchantDto>(null);
  merchant$: Observable<MerchantDto> = this.merchantSubject.asObservable();

  // les affiliés du marchant
  private readonly merchantAffiliatesSubject = new BehaviorSubject<MerchantAffiliateDto[]>(null);
  merchantAffiliates$: Observable<MerchantAffiliateDto[]> = this.merchantAffiliatesSubject.asObservable();

  // l'affilié à afficher
  private readonly affiliateSubject = new BehaviorSubject<MerchantAffiliateDto>(null);
  affiliate$: Observable<MerchantAffiliateDto> = this.affiliateSubject.asObservable();

  // observable sur la selection des produits éligibles
  private readonly cardSelectedSubject = new BehaviorSubject<UserCardDto[]>([]);
  cardSelected$: Observable<UserCardDto[]> = this.cardSelectedSubject.asObservable();

  constructor(
    private readonly merchantController: MerchantControllerService,
    private readonly translateService: TranslateService,
    private readonly retailersStore: RetailersStore,
    private readonly loaderService: LoaderService
  ) {
    // Affiliate filter
    let initAffiliateCriteria: RetailersAffiliateCriteriaDto = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE.AFFILIATE_FILTERS));
    if (!initAffiliateCriteria) {
      initAffiliateCriteria = new RetailersAffiliateCriteriaDto();
      this.resetAffiliateCriteriaPagination(initAffiliateCriteria);
    }
    this.affiliateCriteriaSubject = new BehaviorSubject<RetailersAffiliateCriteriaDto>(initAffiliateCriteria);
    this.affiliateCriteria$ = this.affiliateCriteriaSubject.asObservable();
  }

  // Actions sur les catégories

  static isJackpot(merchant: MerchantDto): boolean {
    return !!merchant?.id?.includes('JP_');
  }

  initializeCategories(): Observable<CategoriesDto> {
    return this.merchantController.readMerchantCategories().pipe(
      tap((categoriesDto: CategoriesDto) => {
        const categoryAll = this.getCategoryAll();

        const categories = new Array<CategoryDto>();
        categories.push(categoryAll);
        categories.push(...categoriesDto.items);
        this.categoriesSubject.next(categories);
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  getCategoryImageUrl(categoryId: number): string {
    const categoriesFound = this.categoriesSubject.value.filter((category: CategoryDto) => category.id === categoryId);
    return categoriesFound && categoriesFound.length ? categoriesFound[0].imageURL : null;
  }

  getBannerUrl(merchant: MerchantDto | MerchantSummaryDto, categoryImageUrl?: string): string {
    if (merchant.bannerUrl) {
      // Case 1: the merchant has a personnalized banner, display it (first choice)
      return merchant.bannerUrl;
    } else {
      // récupérer la catégorie actuelle
      if (categoryImageUrl) {
        return categoryImageUrl;
      } else {
        if (merchant.categories && merchant.categories.length > 0) {
          const index = merchant.categories.findIndex(category => category.isMain);
          return this.getCategoryImageUrl(merchant.categories[index !== -1 ? index : 0].id);
        } else if (merchant.spotlightCategories && merchant.spotlightCategories.length > 0) {
          return this.getCategoryImageUrl(merchant.spotlightCategories[0].id);
        } else {
          return 'assets/images/bimpli/bimpli-card-background.png';
        }
      }
    }
  }

  setAffiliateCriteria(criteria: RetailersAffiliateCriteriaDto) {
    sessionStorage.setItem(LOCAL_STORAGE.AFFILIATE_FILTERS, JSON.stringify(criteria));
    this.affiliateCriteriaSubject.next(criteria);
  }

  // Actions sur les filtres et la pagination

  updateLocalizationCriteria(localization: string): void {
    const criteria: RetailersAffiliateCriteriaDto = this.affiliateCriteriaSubject.value;
    criteria.localization = localization;
    this.resetAffiliateCriteriaPagination(criteria);
    this.setAffiliateCriteria(criteria);
  }

  increaseAffiliatesCriteriaPageSize(toAdd: number): void {
    const criteria: RetailersAffiliateCriteriaDto = this.affiliateCriteriaSubject.value;
    criteria.pageSize += toAdd;
    this.setAffiliateCriteria(criteria);
  }

  resetAffiliateCriteriaPagination(criteria: RetailersAffiliateCriteriaDto): void {
    criteria.pageNumber = 1;
    criteria.pageSize = +environment.pageSizeVar;
  }

  // Action sur la liste des marchants
  readMerchantByCriteria$(
    criteria: RetailersMerchantCriteriaDto,
    pageSize?: number,
    pageNumber?: number,
    sort?: string
  ): Observable<MerchantSummariesDto> {
    const readMerchantInput = new ReadMerchantsByCriteriaInput(criteria);
    readMerchantInput.pageSize = pageSize;
    readMerchantInput.pageNumber = pageNumber;
    readMerchantInput.sort = sort;

    return this.merchantController.readMerchantsByCriteria(readMerchantInput).pipe(
      tap((merchantSummaries) => {
        this.retailersStore.setTotalMerchants(merchantSummaries.page.totalElements);
      }),
      catchError((error) => {
        this.retailersStore.setTotalMerchants(0);
        throw error;
      })
    );
  }

  getTotalMerchantsForCriteria$(criteria: RetailersMerchantCriteriaDto): Observable<number> {
    const readMerchantInput = new ReadMerchantsByCriteriaInput(criteria);
    readMerchantInput.pageSize = 1;
    readMerchantInput.pageNumber = 1;

    return this.merchantController.readMerchantsByCriteria(readMerchantInput).pipe(
      map((merchantSummaries) => {
        return merchantSummaries.page.totalElements;
      })
    );
  }

  readMerchantLabels$(criteria: RetailersMerchantCriteriaDto, nbMax: number): Observable<string[]> {
    const readMerchantInput = new ReadMerchantsByCriteriaInput(criteria);
    // On peut avoir des labels en double, donc on demande plus de marchands que nécessaire
    readMerchantInput.pageSize = nbMax * 2;
    readMerchantInput.pageNumber = 1;

    return this.merchantController.readMerchantsByCriteria(readMerchantInput).pipe(
      catchError(() => of(null as MerchantSummariesDto)),
      map((merchantsSummaries) => {
        // On ne veut que les labels, et le passage par un Set assure l'unicité
        return [...new Set(merchantsSummaries?.items.map((merchant) => merchant.label.toUpperCase()))]
        // .filter((merchantLabel) => merchantLabel.toLowerCase().startsWith(criteria.label.toLowerCase()))
        .slice(0, nbMax);
      })
    );
  }

  // Action sur la liste des affiliés
  updateAffiliateList$(
    merchantCriteria: RetailersMerchantCriteriaDto,
    affiliateCriteria: RetailersAffiliateCriteriaDto
  ): Observable<AffiliateSummariesDto> {
    // On ne renseigne les affiliés que si la localisation est renseignée
    if (!!affiliateCriteria.localization) {
      this.loaderService.startLoading();
      return this.merchantController.readAffiliateByCriteria(merchantCriteria, affiliateCriteria).pipe(
        catchError(() => of(null as AffiliateSummariesDto)),
        tap((affiliateSummaries) => {
          this.affiliateListSubject.next(affiliateSummaries);
        }),
        finalize(() => {
          this.loaderService.stopLoading();
        })
      );
    } else {
      this.affiliateListSubject.next(null);
      return of(null as AffiliateSummariesDto);
    }
  }

  // Actions sur le marchant
  setMerchant(merchant: MerchantDto): void {
    this.merchantSubject.next(merchant);
  }

  // Actions sur les affiliés
  setMerchantAffiliates(affiliates: MerchantAffiliateDto[]): void {
    this.merchantAffiliatesSubject.next(affiliates);
  }

  updateMerchantAffiliates$(pageSize: number, pageNumber: number, replace = false): Observable<MerchantAffiliatesDto> {
    const merchant: MerchantDto = this.merchantSubject.value;
    return this.merchantController.readMerchantAffiliates(merchant.id, pageSize, pageNumber).pipe(
      tap((affiliatesDto) => {
        if (replace) {
          this.merchantAffiliatesSubject.next(affiliatesDto.items);
        } else {
          const affiliates = this.merchantAffiliatesSubject.value;
          affiliates.push(...affiliatesDto.items);
          this.merchantAffiliatesSubject.next(affiliates);
        }
      })
    );
  }

  // Actions sur l'affilié à afficher
  updateAffiliate$(merchantId: string, affiliateId: string): Observable<MerchantAffiliateDto> {
    return this.merchantController.readMerchantAffiliate(merchantId, affiliateId).pipe(
      tap((affiliate) => {
        this.affiliateSubject.next(affiliate);
      })
    );
  }

  resetAffiliate(): void {
    this.affiliateSubject.next(null);
  }

  addSelectedCard(cardChecked: UserCardDto) {
    let cardsSelected = this.cardSelectedSubject.value;
    // On supprime la carte de la liste avant de la rajouter au cas où elle soit déjà présente
    cardsSelected = cardsSelected.filter((card) => cardChecked.id !== card.id);
    cardsSelected.push(cardChecked);
    this.cardSelectedSubject.next(cardsSelected);
  }

  removeSelectedCard(cardChecked: UserCardDto) {
    let cardsSelected = this.cardSelectedSubject.value;
    cardsSelected = cardsSelected.filter((card) => cardChecked.id !== card.id);
    this.cardSelectedSubject.next(cardsSelected);
  }

  resetSelectedCards() {
    this.cardSelectedSubject.next([]);
  }

  private getCategoryAll(): CategoryDto {
    const categoryAll = new CategoryDto();
    categoryAll.id = 0;
    categoryAll.label = this.translateService.instant('USUAL.ALL');
    return categoryAll;
  }
}
