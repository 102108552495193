import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { AppModule } from './app/app.module';
import * as MOCKS from './mocks';

if (environment.production) {
  enableProdMode();
}

if (environment.localApi && !environment.authentication) {
  // const items = MOCKS.TOKENS[0];
  const items = MOCKS.TOKENS[1];
  sessionStorage.setItem(SESSIONSTORAGE.ITEM_USER, JSON.stringify(items.ITEM_USER));
  const current = new Date();
  // Le temps d'expiration de la gateway est en seconde (il faut ajouter 1000 pour avoir le temps en milliseconde)
  sessionStorage.setItem(SESSIONSTORAGE.ITEM_EXPIRES, (current.getTime() + 10000000).toString());
  sessionStorage.setItem(SESSIONSTORAGE.ITEM_SCOPE, items.ITEM_SCOPE);
  sessionStorage.setItem(SESSIONSTORAGE.ITEM_TOKEN, items.ITEM_TOKEN);
  sessionStorage.setItem(SESSIONSTORAGE.ID_CURRENT_CUSTOMER, items.ID_CURRENT_CUSTOMER?.toString());
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
