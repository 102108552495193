<div class="container d-none d-lg-flex desktop-header align-items-center my-5">
    <div class="d-flex justify-content-start">
        <img alt="logo bimpli" src="assets/images/bimpli/bimpli.png"/>
    </div>
    <div class="d-flex w-100 justify-content-end">
        <cado-link (click)="redirecToBimpli()" [iconToAddBefore]="'bi bi-x'"
                   [linkLabel]="'BUTTON.EXIT' | translate"></cado-link>
    </div>
</div>
<div class="d-flex d-lg-none mobile-header align-items-center mb-4">
    <div class="d-flex justify-content-start">
        <img alt="logo bimpli" src="assets/images/bimpli/bimpli.png"/>
    </div>
    <div class="d-flex w-100 justify-content-end">
        <cado-link (click)="redirecToBimpli()" [iconToAddBefore]="'bi bi-x'"
                   [linkLabel]="'BUTTON.EXIT' | translate"></cado-link>
    </div>
</div>
