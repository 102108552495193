import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { LoaderService } from '@layouts/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '@services/error/error.service';
import { ManagerService } from '@services/manager.service';
import { PurchaseVoucherService } from '@services/purchase-voucher.service';
import { StrongAuthenticationControllerService } from '@services/ws/strong-authentication/strong-authentication-controller.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { ToastService } from '@shared/components/toast/toast.service';
import { ErrorCodeApiEnum } from '@shared/models/api/enums/error-code-api.enum';
import { StrongAuthentication } from '@shared/models/api/strong-authentication/strong-authentication.model';
import { ManagerDto } from '@shared/models/manager/manager.model';
import { User } from '@shared/models/user/user.model';
import { ManagerStore } from '@shared/stores/manager.store';
import { UserStore } from '@shared/stores/user.store';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManagerUpdateDto } from '@shared/models/manager/manager-update-dto.model';
import { StrongAuthentOriginEnum } from '@shared/models/api/enums/strong-authent-origin.enum';

@Component({
  selector: 'cado-update-phone-modal',
  templateUrl: './update-phone-modal.component.html',
  styleUrls: ['./update-phone-modal.component.scss'],
})
export class UpdatePhoneModalComponent implements OnInit, OnDestroy {
  updateMode: boolean;

  @Input() isPhoneEdition = false;

  phoneInfosForm: FormGroup;
  otpForm: FormGroup;
  managerPhoneDto: ManagerUpdateDto = new ManagerUpdateDto();
  user: User;
  requestId: string;
  strongAuthentication: StrongAuthentication;
  strongToken: string;

  refresh$: Observable<boolean>;

  destroy$ = new Subject<boolean>();

  // On enregistre le vieux numéro si jamais on annule l'opération
  oldPhoneNumber: string;
  protected readonly StrongAuthentOriginEnum = StrongAuthentOriginEnum;

  constructor(
    private readonly modalService: ModalCadoService,
    private readonly formBuilder: FormBuilder,
    private readonly managerStore: ManagerStore,
    private readonly managerService: ManagerService,
    private readonly userStore: UserStore,
    private readonly loaderService: LoaderService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly errorService: ErrorService,
    private readonly strongAuthenticationController: StrongAuthenticationControllerService,
    private readonly purchaseVoucherService: PurchaseVoucherService
  ) {
  }

  ngOnInit(): void {
    this.phoneInfosForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0][6-7][0-9]{8}$/)]],
    });

    this.otpForm = this.formBuilder.group({
      phoneCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)], [this.validatePhoneCode()]],
    });

    const manager$ = this.managerStore.manager$.pipe(
      tap((manager) => {
        this.managerPhoneDto.cellPhoneNumber = manager?.cellPhoneNumber;
        this.oldPhoneNumber = this.managerPhoneDto.cellPhoneNumber;
      })
    );

    const user$ = this.userStore.userConnected$().pipe(
      tap((user) => {
        this.user = user;
      })
    );

    this.refresh$ = combineLatest([user$, manager$]).pipe(map(() => true));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  close(): void {
    this.phoneInfosForm.reset({phone: ''});
    this.resetForm();
    this.modalService.close('popupPhoneInput');
  }

  sendOTP(): void {
    if (this.isPhoneEdition && this.managerPhoneDto?.cellPhoneNumber === this.phoneInfosForm.get('phone').value) {
      this.toastService.showInfinitToastWithTitle(this.translateService.instant('UPDATE_PHONE_MODAL.SAME_NUMBER'), 'error');
      return;
    }
    this.lockPhone();
    // On enregistre le numéro de téléphone et le fait que celui-ci est désormais vérifié.
    this.managerPhoneDto.cellPhoneNumber = this.phoneInfosForm.get('phone').value;

    this.loaderService.startLoading();
    this.managerService
      .updateManager(this.user.managerId, this.managerPhoneDto, null)
      .pipe(catchError(error => {
          // Retour arrière sur le tel
          this.managerPhoneDto.cellPhoneNumber = this.oldPhoneNumber;
          const errorApi = this.errorService.getFirstErrorApi(error);
          if (errorApi.code === ErrorCodeApiEnum.STRONG_AUTHENTIFICATION_NEEDED_084) {
            this.toastService.showInfinitToastWithTitle(this.translateService.instant('UPDATE_PHONE_MODAL.INFO'), 'info');
            this.requestId = errorApi.additionalInformation.requestId;
            this.createStrongAuthentication(StrongAuthentOriginEnum.TEL_UPDATE);
            return EMPTY;
          } else if (errorApi.code === ErrorCodeApiEnum.SECURITY_UNAUTHORIZED_CELLPHONE_NUMBER_MODIFICATION_108) {
            this.toastService.showInfinitToastWithTitle(this.translateService.instant('UPDATE_PHONE_MODAL.ERROR_FRAUD'), 'info');
          } else if (errorApi.code === ErrorCodeApiEnum.AUTH_OTP_PHONE_FORBIDDEN_111) {
            this.toastService.showInfinitToastWithTitle(this.translateService.instant('UPDATE_PHONE_MODAL.FORBIDDEN_PHONE_NUMBER'), 'error');
          } else if (error.attribute === 'sendSms') {
            this.toastService.showInfinitToastWithTitle(this.translateService.instant('UPDATE_PHONE_MODAL.ERROR'), 'error');
          } else {
            this.errorService.errorHandling(error);
          }
          this.resetForm();
          throw error;
        }),
        finalize(() => this.loaderService.stopLoading())
      ).subscribe();
  }


  createStrongAuthentication(origin: StrongAuthentOriginEnum): void {
    this.loaderService.startLoading();
    this.purchaseVoucherService
      .createStrongAuthentication$(this.requestId, origin)
      .pipe(
        tap((strongAuthentication) => {
          this.strongAuthentication = strongAuthentication;
        }),
        catchError((errors) => {
          this.errorService.errorHandling(errors);
          throw errors;
        }),
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.stopLoading())
      )
      .subscribe();
  }

  savePhone(): void {
    // On enregistre le numéro de téléphone et le fait que celui-ci est désormais vérifié.
    this.managerPhoneDto.cellPhoneNumber = this.phoneInfosForm.get('phone').value;
    this.loaderService.startLoading();
    this.managerService
      .updateManager(this.user.managerId, this.managerPhoneDto, this.strongToken)
      .pipe(
        tap(() => {
          this.toastService.showInfinitToastWithTitle(this.translateService.instant('UPDATE_PHONE_MODAL.SUCCESS_VALID'), 'success');
        }),
        catchError(error => {
          this.errorService.errorHandling(error);
          throw error;
        }),
        switchMap(() => {
          if (!!this.user) {
            // Appel de l'api ReadManager pour mettre à jour le manager
            return this.managerService.readManager(this.user.managerId).pipe(
              catchError(() => {
                return of(null as ManagerDto);
              }),
              tap((manager) => {
                this.managerStore.setManager(manager);
              })
            );
          } else {
            return of(null as ManagerDto);
          }
        }),
        finalize(() => {
          // On reset la modal
          this.resetForm();
          // On ferme la popup
          this.close();
          this.loaderService.stopLoading();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  validatePhoneCode(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.loaderService.startLoading();
      return this.purchaseVoucherService.checkStrongAuthentication$(this.strongAuthentication?.id, control.value).pipe(
        tap((strongToken) => {
          this.strongToken = strongToken;
          this.otpForm.get('phoneCode').markAsTouched();
        }),
        map(() => null),
        catchError(() => of({invalidCode: true})),
        finalize(() => this.loaderService.stopLoading())
      );
    };
  }

  numericPhoneOnly(event): boolean {
    const patt = /^([0-9])$/;
    return patt.test(event.key);
  }

  lockPhone() {
    this.phoneInfosForm.get('phone').disable();
  }

  resetForm() {
    this.phoneInfosForm.get('phone').enable();
    this.otpForm.get('phoneCode').reset();
    this.strongAuthentication = null;
    this.strongToken = null;
  }

  back(): void {
    this.close();
    this.modalService.open('popupPhoneCheck');
  }
}
