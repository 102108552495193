import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/components/toast/toast.service';
import { ErrorApi } from '@shared/models/api/error/error.model';
import { HttpStatusEnum } from '../../models/api/enums/http-status.enum';
import { ErrorCodeApiEnum } from '../../models/api/enums/error-code-api.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
  ) {
  }

  /**
   * Méthode permettant de gérer les erreurs de façon générique
   * @param err l'erreur à gérer
   */
  public errorHandling(err: Error | HttpErrorResponse): string {
    // TODO: Étoffer les différents cas
    if (err instanceof HttpErrorResponse) {
      // Gestion des erreurs serveur
      if (err.error && err.error.errors) {
        // Gestion des erreurs de type ErrorApi
        return this.handleMultipleErrors(err);
      } else if (err.error && err.error.error) {
        // Gestion des erreurs de type HttpErrorResponse
        return this.handleSingleError(err);
      } else if (err.error && err.status === HttpStatusEnum.INTERNAL_SERVER_ERROR) {
        return this.handleError500(err.error);
      } else if (!err.error && err.status === HttpStatusEnum.FORBIDDEN) {
        // Ne devrait pas se produire en contexte de PROD
        this.toastService.showInfinitToastWithTitle(err.message, 'error', true);
      }

    } else if (err instanceof Error) {
      // Gestion des erreurs client
      return err.message ? err.message : err.toString();
    }
    return this.translateService.instant('USUAL_ERROR.DEFAULT') + err;
  }

  /**
   * Méthode permettant de récupérer la première erreur dans la stack des ErrorApi
   * @param err l'erreur contenant les ErrorApi
   */
  public getFirstErrorApi(err): ErrorApi {
    if (!err.error || !err.error.errors) {
      return null;
    }

    return err.error.errors.length > 0 ? err.error.errors[0] : null;
  }

  /**
   * Méthode permettant de la ou les erreurs dans la stack des ErrorApi
   * Si aucune erreur, liste vide
   * @param err l'erreur contenant les ErrorApi
   */
  public getListErrorApi(err: HttpErrorResponse): Array<ErrorApi> {
    let listeErrors = new Array<ErrorApi>();
    if (!!err.error && !!err.error.errors) {
      listeErrors = err.error.errors;
    }
    return listeErrors;
  }

  /**
   * Méthode permettant de gérer des erreurs de type HttpErrorResponse non gérées par les autres handlers
   * @param err l'erreur à gérer
   * @private
   */
  public handleHttpErrorResponse(err: HttpErrorResponse): string {
    const errorMessage = `${err.status}-${err.message}`;
    this.toastService.showInfinitToastWithTitle(errorMessage, 'error', true);
    return errorMessage;
  }

  /**
   * Méthode permettant de gérer des erreurs de type ErrorApi
   * @param err les erreurs à gérer
   * @private
   */
  private handleMultipleErrors(err: HttpErrorResponse): string {
    let errorMessage;
    const errors: ErrorApi[] = err.error.errors;

    if (errors.length === 1) {
      errorMessage = errors[0].message;
      if (!!errors[0].additionalInformation?.message) {
        if (errors[0].code === ErrorCodeApiEnum.UNEXPECTED_004) {
          this.handleError500(errors[0]);
        } else {
          errorMessage = errors[0].additionalInformation.message;
          this.toastService.showShortToastWithTitle(errorMessage, 'error');
        }
      } else {
        if (errors[0].code === ErrorCodeApiEnum.UNEXPECTED_004) {
          this.handleError500(errors[0]);
        } else {
          this.toastService.showShortToastWithTitle(this.translateService.instant('USUAL_ERROR.DEFAULT'), 'error');
        }
      }
    }

    return errorMessage;
  }

  /**
   * Méthode permettant de gérer des erreurs de type HttpErrorResponse
   * @param err l'erreur à gérer
   * @private
   */
  private handleSingleError(err: HttpErrorResponse): string {
    const errorMessage = `${err.error.status} - ${err.error.path} - ${err.error.error}`;

    this.toastService.showShortToastWithTitle(this.translateService.instant('USUAL_ERROR.DEFAULT'), 'error');

    return errorMessage;
  }

  /**
   * Méthode permettant de gérer des erreurs 500 Affichage hors PROD
   * @param error l'erreur à gérer
   * @private
   */
  private handleError500(error: ErrorApi): string {
    let title = `<h4>${this.translateService.instant('USUAL_ERROR.DEFAULT')}</h4>`;
    title += `<h4>${error.code} : ${error.message}</h4>`;
    const offset = new Date().getTimezoneOffset() * 1000 * 60;
    title += `<h5>Cf table des Logs : ${(new Date(error.timestamp).getTime() + offset)}</h5>`;
    const addInf = error.additionalInformation;
    if (addInf && addInf.message) {
      title += `<h4>${addInf.message}</h4>`;
    }
    if (error.stackCado) {
      title += '<pre><ul>';
      error.stackCado.forEach(sc => {
        title += `<li>${sc.className}.${sc.methodName}(${sc.fileName}:${sc.lineNumber})</li>`;
      });
      title += '</ul></pre>';
    }
    this.toastService.showInfinitToastWithTitle(title, 'error', true, error.timestamp);
    return title;
  }
}
