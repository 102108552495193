import { Component, OnInit } from '@angular/core';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { GoogleTagManagerService } from '@services/google-tag-manager.service';

@Component({
  selector: 'cado-gtm-consent',
  templateUrl: './gtm-consent.component.html',
  styleUrls: ['./gtm-consent.component.scss']
})
export class GtmConsentComponent implements OnInit {

  constructor(private readonly modalService: ModalCadoService,
              private readonly googleTagManagerService: GoogleTagManagerService) {
  }

  ngOnInit(): void {
  }

  public closeModal(): void {
    this.modalService.close('idGtmConsentModal');
  }

  consent(consent: boolean) {
    this.googleTagManagerService.changeConsent(consent);
  }
}
