import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  // nombre d'octets dans un megaoctet
  public numberOfOctet = 1048576;

  constructor() {
  }

  getSelectedFilesTotalSize(files: File[]): string {
    let totalSize = 0;
    files.forEach((file) => {
      totalSize += file.size;
    });
    return this.octetsToSize(totalSize);
  }

  octetsToSize(octets: number): string {
    const sizes = ['o', 'Ko', 'Mo', 'Go', 'To'];
    if (octets === 0) {
      return '0 o';
    }
    const i = Math.floor(Math.log(octets) / Math.log(1024));
    return `${Math.round(octets / Math.pow(1024, i))} ${sizes[i]}`;
  }

  megaoctetsToOctets(megaoctets: number): number {
    return megaoctets * this.numberOfOctet;
  }

  octetsToMegaoctets(octets: number): number {
    return octets / this.numberOfOctet;
  }
}
