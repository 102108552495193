import { Injectable } from '@angular/core';
import { ApplicationParametersDto } from '@shared/models/api/referential/application-parameters-dto.model';
import { BehaviorSubject, Observable } from 'rxjs';

const DEFAULT_KYC_THRESHOLD = 150;

@Injectable({
  providedIn: 'root',
})
export class ReferentialStore {

  applicationParameters$: Observable<ApplicationParametersDto>;

  private readonly applicationParametersSubject: BehaviorSubject<ApplicationParametersDto>;

  constructor() {
    this.applicationParametersSubject = new BehaviorSubject<ApplicationParametersDto>(ReferentialStore.getDefaultApplicationParameters());
    this.applicationParameters$ = this.applicationParametersSubject.asObservable();
  }

  /**
   * Méthode permettant d'initialiser les paramètres de l'application avec des valeurs par défaut
   * @private
   */
  private static getDefaultApplicationParameters(): ApplicationParametersDto {
    return {
      voucherValueLimitKYC: DEFAULT_KYC_THRESHOLD
    };
  }

  /**
   * Méthode permettant de mettre à jour les paramètres de l'application
   * @param applicationParameters les nouveaux paramètres
   */
  public setApplicationParameters(applicationParameters: ApplicationParametersDto): void {
    this.applicationParametersSubject.next(applicationParameters);
  }
}
