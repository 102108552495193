<header *ngIf="!isFromBimpli" class="site-header navbar-static-top navbar-light" role="banner">
    <nav class="navbar navbar-expand-xl p-0 justify-content-between">
        <div class="navbar-brand">
            <div (click)="redirectHome()" *ngIf="!isParcoursDigital">
                <img alt="Cadostore" class="menu-logo" id="logo_store" src="assets/images/cado-store-logo.png">
            </div>
            <div *ngIf="isParcoursDigital">
                <img alt="Cadostore" class="menu-logo" id="logo_store" src="assets/images/cado-store-logo.png">
            </div>
        </div>
        <ng-container *ngIf="showMenuButtons$ | async">
            <div *ngIf="!isParcoursDigital" class="menu-dropdown collapse navbar-collapse justify-content-end" id="main-nav">
                <ul class="navbar-nav" id="menu-menu-principal">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-46 nav-item" itemscope="itemscope">
                        <a (click)="redirectHome()" [ngClass]="{ pageSelected: !pageName }" class="nav-link" role="button">{{
                            "MENU.LINKS.HOME" | translate
                            }}</a>
                    </li>

                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-45 nav-item" itemscope="itemscope" *ngIf="!user || user.type === UserTypeEnum.PROFESSIONAL">
                        <a (click)="redirectProduits()" class="nav-link" role="button">{{ "MENU.LINKS.PRODUCTS" | translate }}</a>
                    </li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-47 nav-item" itemscope="itemscope">
                        <a
                            [ngClass]="{
                                pageSelected:
                                    (pageName === PageNameEnum.MY_CADO_RETAILERS || pageName === PageNameEnum.ALL_CADO_RETAILERS) &&
                                    !highlightMyProfile()
                            }"
                            [routerLink]="name && !isPro() ? '/retailers-my-cado' : '/retailers-all-cado'"
                            class="nav-link"
                        >{{ "MENU.LINKS.WHERE" | translate }}</a
                        >
                    </li>
                    <li *ngIf="!user || user.type === UserTypeEnum.INDIVIDUAL"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-48 nav-item" itemscope="itemscope">
                        <a (click)="activation()"
                           [ngClass]="{
                                pageSelected: pageName === PageNameEnum.CARD_ACTIVATION && !highlightMyProfile()
                            }" class="nav-link" role="button">{{ "MENU.BUTTONS.ACTIVATION" | translate }}</a>
                    </li>

                </ul>
            </div>
            <div class="secondary_menu d-flex align-items-center">

                <cado-button-secondary
                    (submitted)="connexion()"
                    *ngIf="(pageName !== PageNameEnum.LANDING_PAGE || !isParcoursDigital) && name === ''"
                    [buttonLabel]="'MENU.BUTTONS.CONNEXION' | translate"
                    [classSurcharge]="(highlightMyProfile() ? 'pageSelected ' : '') + 'white-text bimpli-btn header-btn'"
                ></cado-button-secondary>

                <cado-menu-desktop-dropdown-manager *ngIf="name" [buttonLabel]="name"></cado-menu-desktop-dropdown-manager>

                <div class="d-flex align-items-center cart-wrapper" *ngIf="!user || user.type === UserTypeEnum.PROFESSIONAL">
                    <cado-button-primary
                        (submitted)="goToOrder()"
                        [buttonLabel]="'MENU.BUTTONS.ORDER' | translate"
                        [classSurcharge]="'cart-btn bimpli-btn header-btn'"
                    >
                    </cado-button-primary>

                    <div
                        *ngIf="cartItemsCount > 0 && cartItemsCount < 10"
                        class="cart-count cart-count-small d-flex align-items-center justify-content-center"
                    >
                        {{ cartItemsCount }}
                    </div>

                    <div
                        *ngIf="cartItemsCount >= 10 && cartItemsCount < 100"
                        class="cart-count cart-count-medium d-flex align-items-center justify-content-center"
                    >
                        {{ cartItemsCount }}
                    </div>

                    <div *ngIf="cartItemsCount >= 100" class="cart-count cart-count-large d-flex align-items-center justify-content-center">
                        {{ cartItemsCount }}
                    </div>
                </div>

                <div *ngIf="name" class="d-flex align-items-center ml-0" title="{{ 'USUAL.DECONNEXION' | translate }}">
                    <em (click)="disconnect()" class="bi bi-box-arrow-right menu-icon"></em>
                </div>
            </div>
        </ng-container>
    </nav>
</header>

<cado-confirm-disconnect-modal></cado-confirm-disconnect-modal>
