import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ProductItemOrderCreateDto } from '@services/ws/order/order/signatures/createManagerOrder/product-item-order-create-dto.model';
import { ReadManagerOrderCriteriaDto } from '@services/ws/order/order/signatures/readManagerOrder/read-manager-order-criteria-dto.model';
import { CardPaymentStatusDto } from '@shared/models/api/card/card-payment-status-dto.model';
import { CartSummaryDto } from '@shared/models/api/order/cart/cart-summary-dto.model';
import { OrderExpensesDto } from '@shared/models/api/order/estimateOrderExpenses/order-expenses-dto.model';
import { OrderDto } from '@shared/models/api/order/order-dto.model';
import { OrderHolderCardsDto } from '@shared/models/api/order/order-holder-cards-dto.model';
import { ManagerOrder } from '@shared/models/order/manager-order.model';
import { CardPaymentDto } from '@shared/models/payment/card-payment-dto';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { ManagerOrderCreateDto } from './signatures/createManagerOrder/manager-order-create-dto.model';
import { PaymentCreate } from './signatures/createManagerOrderPayment/payment-create.model';
import { OrderData } from './signatures/estimatedOrderExpenses/order-data.model';
import { OrderPackagingSummary } from './signatures/estimatedPackagingExpenses/order-packaging-summary.model';
import { BeneficiariesDataToImport } from './signatures/importBeneficiaries/beneficiaries-data-to-import.model';
import { BeneficiaryImportedDataResultDto } from './signatures/importBeneficiaries/beneficiary-imported-data-result-dto.model';
import { CartUpdateDto } from './signatures/updateCart/cart-update-dto.model';
import { ProductItemIdDto } from './signatures/updateCartProductItem/product-item-id-dto.model';

@Injectable({
  providedIn: 'root',
})
export class OrderControllerService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'order/')}${environment.versionApi}`;
  }

  downloadFacture(bilingId: string, orderId: number): Observable<any> {
      return this.readOrderBilling(bilingId).pipe(
        tap((data) => {
          const blob = new Blob([data], {type: 'application/pdf'});

          const downloadURL = (window.URL || URL || window.webkitURL).createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = `Facture_${orderId}.pdf`;
          link.click();
        })
      );
  }

  public readManagerCart(managerId: number, customerId: number): Observable<CartSummaryDto> {
    let currentUrl = this.url;

    currentUrl += '/managers/';
    currentUrl += managerId;
    currentUrl += '/customers/';
    currentUrl += customerId;
    currentUrl += '/cart';

    return this.http.get<CartSummaryDto>(currentUrl);
  }

  public createManagerOrder(managerOrderCreate: ManagerOrderCreateDto, managerId: number, customerId: number): Observable<any> {
    let currentUrl = this.url;

    currentUrl += '/managers/';
    currentUrl += managerId;
    currentUrl += '/customers/';
    currentUrl += customerId;
    currentUrl += '/orders';

    return this.http.post(currentUrl, managerOrderCreate);
  }

  public estimatedPackagingExpenses(orderPackagingData: OrderPackagingSummary): Observable<any> {
    return this.http.post(this.url + '/estimatedPackagingExpenses', orderPackagingData);
  }

  public estimatedOrderExpenses(signature: OrderData): Observable<OrderExpensesDto> {
    return this.http.post<OrderExpensesDto>(this.url + '/estimatedOrderExpenses', signature);
  }

  public updateCart(cartId: number, signature: CartUpdateDto): Observable<any> {
    let currentUrl = this.url;

    currentUrl += '/carts/';
    currentUrl += cartId;

    return this.http.put(currentUrl, signature);
  }

  public initCartForCardPayment(cartId: number): Observable<CardPaymentDto> {
    let currentUrl = this.url;

    currentUrl += '/carts/';
    currentUrl += cartId;
    currentUrl += '/cardPayments';

    return this.http.post<CardPaymentDto>(currentUrl, {});
  }

  public readCartForCardPayment(orderId: number, transId: number): Observable<CardPaymentStatusDto> {
    const currentUrl = `${this.url}/carts/${orderId}/cardPayments/${transId}`;
    return this.http.get<CardPaymentStatusDto>(currentUrl);
  }

  public simulationCyberPlus(orderId: number, transId: number): Observable<any> {
    const currentUrl = `${this.url}/simulatedCardPayment/${orderId}/cardPayments/${transId}`;
    return this.http.post<any>(currentUrl, {});
  }

  /**
   * Fonction qui permet de faire appel à l'API /carts/{cartId}/productItems/{productItemId}
   * qui efface un article du panier en base
   */
  public deleteOrderProductItem(cartId: number, productItemId: number): Observable<any> {
    let currentUrl = this.url;

    currentUrl += '/carts/';
    currentUrl += cartId;
    currentUrl += '/productItems/';
    currentUrl += productItemId;
    return this.http.delete(currentUrl);
  }

  /**
   * Fonction qui permet de faire appel à l'API /carts/{cartId}/productItems/
   * qui ajoute un article au panier en base
   */
  public addOrderProductItem(cartId: number, productItemOrderCreateDto: ProductItemOrderCreateDto): Observable<any> {
    let currentUrl = this.url;
    currentUrl += '/carts/';
    currentUrl += cartId;
    currentUrl += '/productItems';
    return this.http.post(currentUrl, productItemOrderCreateDto);
  }

  /**
   * Fonction qui permet de faire appel à l'API /carts/{cartId}/productItems/{productItemId}
   * qui met à jour un article du panier en base
   */
  public updateOrderProductItem(
    cartId: number,
    productItemToUpdateId: number,
    productItemOrderCreateDto: ProductItemOrderCreateDto
  ): Observable<ProductItemIdDto> {
    let currentUrl = this.url;
    currentUrl += '/carts/';
    currentUrl += cartId;
    currentUrl += '/productItems/';
    currentUrl += productItemToUpdateId;
    return this.http.put<ProductItemIdDto>(currentUrl, productItemOrderCreateDto);
  }

  public createManagerOrderPayment(signature: PaymentCreate, orderId: number): Observable<any> {
    let currentUrl = this.url;

    currentUrl += '/orders/';
    currentUrl += orderId;
    currentUrl += '/payments';

    return this.http.post(currentUrl, signature);
  }

  public readOrder(orderId: number): Observable<OrderDto> {
    let currentUrl = this.url;

    currentUrl += '/orders/';
    currentUrl += orderId;

    return this.http.get<OrderDto>(currentUrl);
  }

  public readOrderBilling(billingId: string): Observable<Blob> {
    let currentUrl = this.url;

    currentUrl += '/billings/' + billingId;
    return this.http.get(currentUrl, {responseType: 'blob'});
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/managers/{managerId}/customers/{customerId}/orders
   * Returns the orders list of a manager for one of its managed customers
   */
  public readManagerOrders(signature: ReadManagerOrderCriteriaDto): Observable<ManagerOrder> {
    let currentUrl = this.url;
    currentUrl += `/managers/${signature.managerId}/customers/${signature.customerId}/orders?pageSize=${signature.pageSize}&pageNumber=${signature.pageNumber}`;
    return this.http.get<ManagerOrder>(currentUrl);
  }

  public generateBeneficiariesNamesPreformatedFile(cartId: number, productItemId: number): Observable<HttpResponse<Blob>> {
    let currentUrl = this.url;

    currentUrl += `/carts/${cartId}/beneficiariesPreformatedFile`;

    const params = new HttpParams().set('productItemId', String(productItemId));

    return this.http.get(currentUrl, {responseType: 'blob', params, observe: 'response'});
  }

  public importBeneficiariesDataFromPreformatedFile(cartId: number,
                                                    productItemId: number,
                                                    fileB64: string): Observable<BeneficiaryImportedDataResultDto> {

    let currentUrl = this.url;
    currentUrl += `/carts/${cartId}/beneficiariesPreformatedFile`;

    const body: BeneficiariesDataToImport = new BeneficiariesDataToImport();
    body.productItemId = productItemId;
    body.beneficiariesNamesFile = fileB64;

    return this.http.post<BeneficiaryImportedDataResultDto>(currentUrl, body);
  }

  readOrderCardHolders(orderId: number, pageSize: number, pageNumber: number): Observable<OrderHolderCardsDto> {
    let currentUrl = this.url;
    currentUrl += `/orders/${orderId}/cards?pageSize=${pageSize}&pageNumber=${pageNumber}`;

    return this.http.get<OrderHolderCardsDto>(currentUrl);
  }


  generateOrderCardInfos(orderId: number): Observable<any> {
    let currentUrl = this.url;
    currentUrl += `/orders/${orderId}/cardInfosFile`;
    return this.http.get(currentUrl, {responseType: 'blob', observe: 'response'}).pipe(
      tap((apiResponse) => {
        const blob = new Blob([apiResponse.body], {type: 'application/vnd.ms-excel'});

        const downloadURL = (window.URL || URL || window.webkitURL).createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        // Récupération du nom du fichier dans le header
        link.download = apiResponse.headers.get('content-disposition').split('filename=')[1];
        link.click();
      }),
    );
  }
}
