<div (click)="submit()" class="link-two-row-div">

    <table class="link-two-row-table">

        <tr>

            <td class="link-two-row-row1">{{row1}}</td>
            <td rowspan="2">
                <i class="icon-arrow-right"></i>
            </td>

        </tr>

        <tr>

            <td class="link-two-row-row2">{{row2}}</td>

        </tr>

    </table>

</div>
