import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LoaderService } from '@layouts/loader/loader.service';
import { RetailersService } from '@services/merchant/retailers.service';
import { CategoriesDto } from '@shared/models/api/merchant/categories-dto.model';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoriesResolver implements Resolve<CategoriesDto> {
  constructor(
    private readonly retailersService: RetailersService,
    private readonly loaderService: LoaderService
  ) {
  }

  resolve(): Observable<CategoriesDto> {
    this.loaderService.startLoading();
    return this.retailersService.categories$.pipe(
      switchMap((categories) => {
        if (!categories) {
          return this.retailersService.initializeCategories();
        } else {
          return of(null);
        }
      }),
      take(1),
      finalize(() => this.loaderService.stopLoading())
    );
  }
}
