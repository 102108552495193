import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { CartService } from '@services/cart.service';
import { UrlService } from '@services/miscellaneous/url.service';
import { ModalCadoService } from '@shared/components/modal/modal-cado/modal-cado.service';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { ApplicationParametersDto } from '@shared/models/api/referential/application-parameters-dto.model';
import { User } from '@shared/models/user/user.model';
import { PageStore } from '@shared/stores/page.store';
import { ReferentialStore } from '@shared/stores/referential.store';
import { UserStore } from '@shared/stores/user.store';
import { AuthentificationService } from 'nit-angular-lib';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { MenuDesktopService } from './menu-desktop.service';

@Component({
  selector: 'cado-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
})
export class MenuDesktopComponent implements OnInit, OnDestroy {
  pageName: PageNameEnum;
  name = '';
  cartItemsCount: number;
  user: User;
  isFromBimpli = false;
  isParcoursDigital = false;

  PageNameEnum = PageNameEnum;
  UserTypeEnum = UserTypeEnum;

  showMenuButtons$: Observable<boolean>;

  private readonly subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthentificationService,
    private readonly menuService: MenuDesktopService,
    private readonly cartService: CartService,
    private readonly urlService: UrlService,
    private readonly pageStore: PageStore,
    private readonly userStore: UserStore,
    private readonly modalService: ModalCadoService,
    private readonly referentialStore: ReferentialStore,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(this.pageStore.isFromBimpli$
      .pipe(
        tap((isFromBimpli) => {
          this.isFromBimpli = isFromBimpli;
        })
      ).subscribe());

    this.subscription.add(this.pageStore.isParcoursDigital$
      .pipe(
        tap((isParcoursDigital) => {
          this.isParcoursDigital = isParcoursDigital;
        }),
      ).subscribe());

    const changePageSub = this.pageStore.currentPage$
      .pipe(
        tap((pageName) => {
          this.pageName = pageName;
        })
      ).subscribe();

    this.subscription.add(changePageSub);

    const nameSubscription = this.menuService.nameValue.subscribe((value) => {
      this.name = value;
    });

    this.subscription.add(nameSubscription);

    this.userStore.userConnected$()
      .pipe(
        tap(user => this.user = user)
      ).subscribe();

    if (this.authenticationService.getToken() && this.user) {
      this.menuService.showUserConnectedWithName(`${this.user.identity.firstName} ${this.user.identity.lastName}`);
    }

    this.cartItemsCount = 0;
    // Souscription à la mise à jour du Panier : on met à jour le nombre d'items du panier
    const cartServiceSubscription = this.cartService.cart$
      .pipe(
        tap((cartElement) => {
          if (!cartElement) {
            // On recalcule le nombre d'articles dans le panier
            this.cartItemsCount = 0;
          } else {
            // On recalcule le nombre d'articles dans le panier
            this.cartItemsCount = cartElement.itemsCount;
            // On met à jour le panier local
          }
        })
      )
      .subscribe();

    this.subscription.add(cartServiceSubscription);

    this.showMenuButtons$ = this.menuService.showMenuButtons$();

    const referentialStoreSubscription = this.referentialStore.applicationParameters$
      .pipe(
        filter((applicationParameters: ApplicationParametersDto) => !!applicationParameters?.versionNumber),
        tap((applicationParameters: ApplicationParametersDto) => {
          console.log('FEIC ', environment.icVersion, environment.icScmTag, environment.icScmBranch);
          console.log('BEIC ', applicationParameters.versionNumber, applicationParameters.scmTag, applicationParameters.scmBranch);
        })
      ).subscribe();

    this.subscription.add(referentialStoreSubscription);
  }

  public activation(): void {
    this.router.navigateByUrl('/activation');
  }

  public connexion(): void {
    this.router.navigateByUrl('/authent');
  }

  public goToOrder(): void {
    // Si l'utilisateur a déjà un panier en base, on passe directement au paiement
    // Si ce n'est pas le cas, on redirige vers la composition du panier
    if (sessionStorage.getItem('hasCart') === '1') {
      this.router.navigate(['payment']);
    } else if (this.cartService.hasCurrentCart()) {
      this.router.navigate(['order']);
    } else if (this.isParcoursDigital === true) {
      if (this.pageName !== PageNameEnum.LANDING_PAGE) {
        this.router.navigate(['landing-page']);
      }
    } else {
      this.redirectWpCart();
    }
  }

  public redirectHome(): void {
    this.urlService.redirectToSiteVitrine('', true);
  }

  public redirectProduits(): void {
    this.urlService.redirectToSiteVitrine('produits-cado', true);
  }

  public redirectWpCart(): void {
    const user = this.userStore.getCurrentConnectedUser();
    const page = user ? 'panier-vide-connecte' : 'panier-non-connecte';
    this.urlService.redirectToSiteVitrine(page, true);
  }

  /**
   * Gestion de la déconnexion
   */
  public disconnect() {
    this.modalService.open('idModalConfirmDisconnect');
  }

  public isPro() {
    this.user = this.userStore.getCurrentConnectedUser();
    if (this.user) {
      return this.user.type === UserTypeEnum.PROFESSIONAL;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  highlightMyProfile(): boolean {
    return this.name === '' && this.router.url.includes('/authent');
  }
}
