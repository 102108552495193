import { Injectable } from '@angular/core';
import { ReferentialControllerService } from '@services/ws/referential/referential-controller.service';
import { ApplicationParametersDto } from '@shared/models/api/referential/application-parameters-dto.model';
import { ReferentialStore } from '@shared/stores/referential.store';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferentialService {

  constructor(
    private readonly referentialControllerService: ReferentialControllerService,
    private readonly referentialStore: ReferentialStore
  ) {
  }

  public getApplicationParameters(): void {
    this.referentialControllerService.readApplicationParameters()
      .pipe(
        tap((applicationParameters: ApplicationParametersDto) => {
          this.referentialStore.setApplicationParameters(applicationParameters);
        }),
        catchError(() => {
          console.log('An error occurred while retrieving the application parameters');
          return EMPTY;
        })
      ).subscribe();
  }
}
