<div class="menu-footer">

    <div [class]="isPro() ? 'div-gauche-menu-footer-no-activate' : 'div-gauche-menu-footer'" class="d-flex">

        <div (click)="onAccueilClick()" [ngClass]="{'pageSelected' : !pageName }"
             class="btn btn-square">
            <i class="icon-home-stroke footer-icon"></i>
            <span>{{'FOOTER.HOME' | translate}}</span>
        </div>

        <div (click)="onProduitCadoClick()" class="btn btn-square" *ngIf="!user || user.type === UserTypeEnum.PROFESSIONAL">
            <i class="icon-cart-stroke footer-icon"></i>
            <span>{{'FOOTER.PRODUCTS' | translate}}</span>
        </div>

    </div>

    <div [class]="isPro() ? 'div-droite-menu-footer-no-activate' : 'div-droite-menu-footer'" class="d-flex">

        <div (click)="onRetailersClick()"
             [ngClass]="{'pageSelected' : (pageName === EnumPageName.MY_CADO_RETAILERS || pageName === EnumPageName.ALL_CADO_RETAILERS) && !highlightMyProfile() }"
             class="btn btn-square">
            <i [ngClass]="(pageName === EnumPageName.MY_CADO_RETAILERS || pageName === EnumPageName.ALL_CADO_RETAILERS) && !highlightMyProfile() ? 'icon-map-pin-fill' : 'icon-map-pin-stroke'"
               class="footer-icon"></i>
            <span>{{'FOOTER.WHERE' | translate}}</span>
        </div>


        <div (click)="onMyProfileClick()"
             [ngClass]="{'pageSelected' : pageName === EnumPageName.MY_PROFILE || highlightMyProfile() }"
             class="btn btn-square">
            <i [ngClass]="pageName === EnumPageName.MY_PROFILE || highlightMyProfile() ? 'icon-profil-fill' : 'icon-profil-stroke'"
               class="footer-icon"></i>
            <span>{{'FOOTER.ACCOUNT' | translate}}</span>
        </div>


    </div>

</div>

<!-- Popup de choix du customer -->
<cado-select-customer-modal [user]="user"></cado-select-customer-modal>
