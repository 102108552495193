import { MerchantTypeEnum } from '@shared/enums/merchant-type.enum';
import { PurchaseTypeEnum } from '@shared/enums/purchase-type.enum';
import { RetailersMerchantCriteriaDto } from '@shared/models/retailers/retailers-merchant-criteria-dto.model';

export class ReadMerchantsByCriteriaInput {
  merchantType: MerchantTypeEnum;
  purchaseType: PurchaseTypeEnum;
  isPurchaseVoucherAcceptedOnly: boolean;
  categories: string;
  productId: string;
  label: string;
  useCategoryId: number[];
  pageSize: number;
  pageNumber: number;
  sort: string;

  constructor(criteria: RetailersMerchantCriteriaDto) {
    this.merchantType = criteria?.merchantType;
    this.purchaseType = criteria?.purchaseType;
    this.isPurchaseVoucherAcceptedOnly = criteria?.isPurchaseVoucherAcceptedOnly;
    this.categories = criteria?.categories;
    this.productId = criteria?.carouselProduct?.productFamily;
    this.label = criteria?.label;
    this.useCategoryId = criteria?.useCategoryId;
  }
}
