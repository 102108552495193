import { ProductPreviewOrderableEnum } from '@shared/enums/product-preview-orderable.enum';
import { ProductPreviewPersonalizationEnum } from '@shared/enums/product-preview-personalization.enum';

export class Previews {
  items: Array<Preview>;
}

export class Preview {
  id: number;
  imageURL: string;
  isDefault: boolean;
  label: string;
  orderable: ProductPreviewOrderableEnum;
  personalization: ProductPreviewPersonalizationEnum;
}
