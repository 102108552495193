<button
    (click)="submit()"
    [disabled]="disabled"
    [ngClass]="classSurcharge"
    class="btn primary_btn d-flex"
>

    <i *ngIf="iconToAdd" [ngClass]="iconToAdd" class="icon"></i>

    <span class="d-flex justify-content-center align-items-center">{{buttonLabel}}</span>

</button>
