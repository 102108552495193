import { Component, OnInit } from '@angular/core';
import { UrlService } from '@services/miscellaneous/url.service';

@Component({
  selector: 'cado-bimpli-header',
  templateUrl: './bimpli-header.component.html',
  styleUrls: ['./bimpli-header.component.scss']
})
export class BimpliHeaderComponent implements OnInit {

  constructor(
    private readonly urlService: UrlService,
  ) {
  }

  ngOnInit(): void {
  }

  redirecToBimpli() {
    this.urlService.redirectToBimpli();
  }
}
