import { UseCategory } from '@shared/models/product/usecategory.model';

export class Events {
  items: Array<Event>;

  constructor(items: Array<Event>) {
    this.items = items;
  }
}

export class Event {
  id: number;
  label: string;
  useCategories: Array<UseCategory>;
}
