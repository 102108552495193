import { EntrepriseTypeEnum } from '@shared/enums/entreprise-type.enum';

export const USER = {
  ITEM_EXPIRES: '1800',
  ITEM_SCOPE: 'Bearer',
  ITEM_TOKEN: '7ofI3n8yhpwb7a2mB1W2uMNlwrF159b1L2BLwWew0bpaT7lvxtDt5s',
  ID_CURRENT_CUSTOMER: 1453337,
  ITEM_USER: {
    id: 1435774,
    login: 'adopart01@cado.fr',
    identity: {
      firstName: 'Adrien',
      lastName: 'OLIVEIRA',
    },
    type: 'INDIVIDUAL',
    customers: [
      {
        id: 1453337,
        company: {
          name: 'CELAD',
        },
        type: EntrepriseTypeEnum.ENTERPRISE,
      },
      {
        id: 1453338,
        company: {
          name: 'NATIXIS',
        },
        type: EntrepriseTypeEnum.ENTERPRISE,
      },
    ],
    managerId: 7383861,
    active: 'ACTIVE',
  },
};

export const USER_AGODET = {
  ITEM_EXPIRES: '1800',
  ITEM_SCOPE: 'Bearer',
  ITEM_TOKEN: '7ofI3n8yhpwb7a2mB1W2uMNlwrF159b1L2BLwWew0bpaT7lvxtDt5s',
  ID_CURRENT_CUSTOMER: 93057,
  ITEM_USER: {
    id: 1311639,
    login: 'AGODET@JEANLAIN.COM',
    identity: {
      firstName: 'AURELIE',
      lastName: 'GODET',
    },
    type: 'PROFESSIONAL',
    customers: [
      {
        id: 93057,
        company: {
          name: 'XXAN LAIN AUTOMOBILES',
        },
        type: 'ENTERPRISE',
      },
      {
        id: 93058,
        company: {
          name: 'XXAN LAIN AUTOSPORT',
        },
        type: 'ENTERPRISE',
      },
      {
        id: 93059,
        company: {
          name: 'XXAN LAIN ENTREPRISES',
        },
        type: 'ENTERPRISE',
      },
    ],
    managerId: 6879022,
    active: 'ACTIVE',
  },
};

export const USER_INFLUENT = {
  ITEM_EXPIRES: '1800',
  ITEM_SCOPE: 'Bearer',
  ITEM_TOKEN: '7ofI3n8yhpwb7a2mB1W2uMNlwrF159b1L2BLwWew0bpaT7lvxtDt5s',
  ID_CURRENT_CUSTOMER: 1653537,
  ITEM_USER: {
    id: 1637354,
    login: 'thomas@linfluent.com',
    identity: {
      firstName: 't',
      lastName: 's',
    },
    type: 'PROFESSIONAL',
    customers: [
      {
        id: 1653537,
        company: {
          name: 'linfluent',
        },
        type: EntrepriseTypeEnum.ENTERPRISE,
      },
    ],
    managerId: 8591611,
    active: 'ACTIVE',
  },
};

export const TOKENS = [USER, USER_AGODET, USER_INFLUENT];

export const PURCHASE_VOUCHERS = {
  items: [
    {
      id: 1,
      creationDate: new Date('2021-10-01T15:25:43.511Z'),
      expirationDate: new Date('2030-10-01T15:25:43.511Z'),
      amount: {
        amount: 50,
        currency: 'EUR',
      },
      status: 'ACTIVATED',
      merchant: {
        id: '501',
        label: 'Mock merchant',
        logoUrl: 'https://cadostore.uat.mycloud.intranatixis.com/CADO/referential/v1/images/MERCHANT_LOGO-3433',
        purchaseVoucherParameter: {
          isOnlineAccepted: true,
          isStoreAccepted: true,
          shippingDelayInHours: 2,
          expirationDelayInDays: 48,
        },
        acceptationText: 'Texte acceptation',
      },
    },
    {
      id: 2,
      creationDate: new Date('2021-10-02T15:25:43.511Z'),
      amount: {
        amount: 30,
        currency: 'EUR',
      },
      status: 'PENDING',
      merchant: {
        id: '502',
        label: 'Mock merchant 2',
        logoUrl: 'https://cadostore.uat.mycloud.intranatixis.com/CADO/referential/v1/images/MERCHANT_LOGO-3989',
        purchaseVoucherParameter: {
          isOnlineAccepted: true,
          isStoreAccepted: false,
          shippingDelayInHours: 1,
          expirationDelayInDays: 48,
        },
      },
    },
    {
      id: 3,
      creationDate: new Date('2021-10-03:25:43.511Z'),
      expirationDate: new Date('2030-10-03T15:25:43.511Z'),
      amount: {
        amount: 25,
        currency: 'EUR',
      },
      status: 'EXPIRED',
      merchant: {
        id: '503',
        label: 'Mock merchant 3',
        logoUrl: 'https://cadostore.uat.mycloud.intranatixis.com/CADO/referential/v1/images/MERCHANT_LOGO-4882',
        purchaseVoucherParameter: {
          isOnlineAccepted: false,
          isStoreAccepted: true,
          shippingDelayInHours: 0,
          expirationDelayInDays: 48,
        },
        acceptationText: 'Texte acceptation 3',
      },
    },
    {
      id: 4,
      creationDate: new Date('2021-10-04T15:25:43.511Z'),
      expirationDate: new Date('2030-10-04T15:25:43.511Z'),
      amount: {
        amount: 40,
        currency: 'EUR',
      },
      status: 'ACTIVATED',
      merchant: {
        id: '504',
        label: 'Mock merchant 4',
        purchaseVoucherParameter: {
          isOnlineAccepted: true,
          isStoreAccepted: true,
          shippingDelayInHours: 14,
          expirationDelayInDays: 48,
        },
        acceptationText: 'Texte acceptation 4',
      },
    },
    {
      id: 5,
      creationDate: new Date('2021-10-5:25:43.511Z'),
      expirationDate: new Date('2030-10-05T15:25:43.511Z'),
      amount: {
        amount: 55,
        currency: 'EUR',
      },
      status: 'PENDING',
      merchant: {
        id: '505',
        label: 'Mock merchant 5',
        logoUrl: 'https://cadostore.uat.mycloud.intranatixis.com/CADO/referential/v1/images/MERCHANT_LOGO-4882',
        purchaseVoucherParameter: {
          isOnlineAccepted: true,
          isStoreAccepted: true,
          shippingDelayInHours: 24,
          expirationDelayInDays: 48,
        },
        acceptationText: 'Texte acceptation 5',
      },
    },
  ],
  page: {
    pageSize: 5,
    pageNumber: 1,
    totalElements: 7,
    totalPages: 2,
  },
};
