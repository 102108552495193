import { OrderFormatEnum } from '@shared/enums/order-format.enum';
import { ProductTypeEnum } from '@shared/enums/product-type.enum';
import { OrderItem } from '@shared/models/create-order/order-item.model';

export class Order {
  id: number;
  format: OrderFormatEnum;
  productType: ProductTypeEnum;
  orderItems: Array<OrderItem>;
}
