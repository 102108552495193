import { Injectable } from '@angular/core';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { ManagedCustomer } from '@shared/models/user/managed-customer.model';
import { PageStore } from '@shared/stores/page.store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuDesktopService {
  nameValue = new BehaviorSubject<string>('');
  listCustomer = new Subject<Array<ManagedCustomer>>();
  showDropdown = new Subject<boolean>();
  hasRetailersMenu = new BehaviorSubject<boolean>(false);

  nameValue$: Observable<string> = this.nameValue.asObservable();
  showRetailersMenu$: Observable<boolean> = this.hasRetailersMenu.asObservable();

  constructor(
    private readonly pageStore: PageStore
  ) {
  }

  /**
   * Affiche l'utilisateur connecté
   * @param title title that you will show on the toast
   * @param type success for success icon and error for error icon
   */
  showUserConnectedWithName(name): void {
    this.nameValue.next(name);
  }

  /**
   * Affiche la liste des customers
   * @param pListCustomer la liste des clients
   */
  showListCustomer(pListCustomer: ManagedCustomer[]): void {
    this.listCustomer.next(pListCustomer);
  }

  /**
   * Affiche un bouton pour que l'utilisateur se connecte
   */
  hideUserConnected(): void {
    this.nameValue.next('');
  }

  showDropdownManager(pShowDropdown): void {
    this.showDropdown.next(pShowDropdown);
  }

  showRetailersMenu(): void {
    this.hasRetailersMenu.next(true);
  }

  hideRetailersMenu(): void {
    this.hasRetailersMenu.next(false);
  }

  /**
   * Affiche la liste des clients
   * @param customerId l'id du client courant
   * @param listCustomer la liste des clients
   */
  showDropdownWithUser(customerId: number, listCustomer: ManagedCustomer[]): void {
    const customersListWithoutCurrent: ManagedCustomer[] = [];
    listCustomer.forEach((element) => {
      if (element.id !== customerId) {
        customersListWithoutCurrent.push(element);
      }
    });

    this.showListCustomer(customersListWithoutCurrent);

    if (customersListWithoutCurrent.length > 0) {
      this.showDropdownManager(true);
    } else {
      this.showDropdownManager(false);
    }
  }

  showMenuButtons$(): Observable<boolean> {
    return this.pageStore.currentPage$.pipe(
      map((page) => {
        return page !== PageNameEnum.CARDS_ACTIVATION;
      })
    );
  }

  showMobileFooter$(): Observable<boolean> {
    return this.pageStore.currentPage$.pipe(
      map((page) => {
        return page !== PageNameEnum.CARDS_ACTIVATION && page !== PageNameEnum.BENEFICIARIES && page !== PageNameEnum.SIGNUP;
      })
    );
  }
}
