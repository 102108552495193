import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private currentLoading = 0;

  private readonly loadingStatus = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingStatus.asObservable();

  startLoading(): void {
    this.currentLoading++;

    if (this.currentLoading > 0) {
      this.loadingStatus.next(true);
    }
  }

  stopLoading(): void {
    this.currentLoading--;
    this.currentLoading = Math.max(this.currentLoading, 0);

    if (this.currentLoading <= 0) {
      this.loadingStatus.next(false);
    }
  }

}

