import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import { CartService } from '@services/cart.service';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { RoutesEnum } from '@shared/models/common/routes.enum';
import { Customer } from '@shared/models/customer/customer.model';
import { ManagedCustomer } from '@shared/models/user/managed-customer.model';
import { User } from '@shared/models/user/user.model';
import { CustomerStore } from '@shared/stores/customer.store';
import { PageStore } from '@shared/stores/page.store';
import { UserStore } from '@shared/stores/user.store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'cado-menu-desktop-dropdown-manager',
  templateUrl: './menu-desktop-dropdown-manager.component.html',
  styleUrls: ['./menu-desktop-dropdown-manager.component.scss'],
})
export class MenuDesktopDropdownManagerComponent implements OnInit, OnDestroy {
  @Input() buttonLabel: any;

  user: User;
  currentCustomer: Customer;
  customersListWithoutCurrent: Array<ManagedCustomer> = [];
  showDropdown: boolean;
  pageName: PageNameEnum;

  PageNameEnum = PageNameEnum;

  onMenu = false;
  onDropdown = false;

  private readonly subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly menuService: MenuDesktopService,
    private readonly cartService: CartService,
    private readonly userStore: UserStore,
    private readonly pageStore: PageStore,
    private readonly customerStore: CustomerStore
  ) {
  }

  public ngOnInit(): void {
    const changePageSubscription = this.pageStore.currentPage$
      .pipe(
        tap((pageName) => {
          this.pageName = pageName;
        })
      )
      .subscribe();

    this.subscription.add(changePageSubscription);

    const currentCustomerSubscription = this.customerStore.customer$
      .pipe(
        tap((currentCustomer) => {
          this.currentCustomer = currentCustomer;
        })
      )
      .subscribe();

    this.subscription.add(currentCustomerSubscription);

    const customersListWithoutCurrentSubscription = this.menuService.listCustomer
      .pipe(
        tap((listCustomer) => {
          this.customersListWithoutCurrent = listCustomer;
        })
      )
      .subscribe();

    this.subscription.add(customersListWithoutCurrentSubscription);

    const showDropdownSubscription = this.menuService.showDropdown
      .pipe(
        tap((showDropdown) => {
          this.showDropdown = showDropdown;
        })
      )
      .subscribe();

    this.subscription.add(showDropdownSubscription);

    this.user = this.userStore.getCurrentConnectedUser();
    const customer = this.customerStore.getCurrentCustomer();
    if (customer) {
      this.menuService.showDropdownWithUser(customer.id, this.user.customers);
    }
  }

  onProfilClick(): void {
    if (this.isPro()) {
      this.router.navigateByUrl('/my-orders');
    } else {
      this.router.navigateByUrl('/my-cado');
    }
  }

  public onCustomerClick(customer): void {
    this.hideOnDropdown();
    this.hideOnMenu();

    this.user = this.userStore.getCurrentConnectedUser();
    this.customerStore.setCustomerId(customer?.id);
    this.menuService.showDropdownWithUser(customer, this.user.customers);

    // On récupère le panier en base
    this.cartService.loadCartFromServer$(this.user.managerId, customer.id, this.user).subscribe();

    let urlActive = this.router.url;

    // Si on est à l'étape de paiement, il faut rediriger vers la page de commande puisque le panier est vide
    if (urlActive === '/payment') {
      urlActive = RoutesEnum.ORDER;
    } else if (urlActive.includes('/' + RoutesEnum.ORDER)) {
      // TODO : à corriger le jour où on gèrera la redirection en fonction des products correctement
      urlActive = RoutesEnum.ORDER;
    }

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([urlActive]));
  }

  public showOnMenu(): void {
    this.onMenu = true;
  }

  public hideOnMenu(): void {
    // On delai la fermeture le temps d'aller dans le menu
    setTimeout(() => {
      this.onMenu = false;
    }, 250);
  }

  public showOnDropdown(): void {
    this.onDropdown = true;
  }

  public hideOnDropdown(): void {
    this.onDropdown = false;
  }

  isPro(): boolean {
    if (this.user) {
      return this.user.type === UserTypeEnum.PROFESSIONAL;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
