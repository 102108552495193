import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomerUpdateDto } from '@shared/models/customer/customer-update-dto.model';
import { DeliveryPointCreateDto } from '@shared/models/customer/delivery-point-create-dto.model';
import { DeliveryPointUpdateDto } from '@shared/models/customer/delivery-point-update-dto.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Customer } from '../models/customer/customer.model';
import { DeliveryPoints } from '../models/customer/delivery-points.model';


@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'customer/')}${environment.versionApi}/`;
  }

  public readCustomerDeliveryPoints(customerId): Observable<DeliveryPoints> {
    return this.http.get<DeliveryPoints>(`${this.url}customers/${customerId}/deliveryPoints`);
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/customers/{customerId}
   * qui retourne les infos du client
   */
  public readCustomer(customerId): Observable<Customer> {
    return this.http
      .get<Customer>(`${this.url}customers/${customerId}`)
      .pipe(map((customer: Customer) => Object.assign(new Customer(), customer)));
  }

  public createCustomerDeliveryPoint(customerId: number, deliveryPoint: DeliveryPointCreateDto): Observable<any> {
    const url = `${this.url}customers/${customerId}/deliveryPoints`;
    return this.http.post(url, deliveryPoint);
  }

  public updateCustomerDeliveryPoint(customerId: number, deliveryPointId: number, signature: DeliveryPointUpdateDto): Observable<any> {
    const url = `${this.url}customers/${customerId}/deliveryPoints/${deliveryPointId}`;
    return this.http.patch(url, signature);
  }

  /**
   * Fonction qui permet de faire appel à l'API /v1/customers/{customerId}
   * qui met à jour les infos du client
   */
  public updateCustomer(customerId: number, signature: CustomerUpdateDto): Observable<any> {
    const currentUrl = `${this.url}customers/${customerId}`;
    return this.http.put(currentUrl, signature);
  }
}
