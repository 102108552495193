import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LoaderService } from '@layouts/loader/loader.service';
import { MenuDesktopService } from '@layouts/menu-desktop/menu-desktop.service';
import { CartService } from '@services/cart.service';
import { UrlService } from '@services/miscellaneous/url.service';
import * as LOCAL_STORAGE from '@shared/constants/local-storage.constants';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { AuthToken } from '@shared/models/common/authtoken.model';
import { User } from '@shared/models/user/user.model';
import { CardActivationStore } from '@shared/stores/card-activation.store';
import { UserStore } from '@shared/stores/user.store';
import { AuthentificationService } from 'nit-angular-lib';
import { Observable } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UserIDPService } from './userIDP.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationCadoService {

  readonly REPONSE_TYPE = 'token';

  private readonly clientIdGateway: string;

  public constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly menuDesktopService: MenuDesktopService,
    private readonly urlService: UrlService,
    private readonly loaderService: LoaderService,
    private readonly cartService: CartService,
    private readonly userStore: UserStore,
    private readonly cardActivationStore: CardActivationStore,
    private readonly authentificationService: AuthentificationService,
    private readonly userIDPService: UserIDPService,
    private readonly router: Router
  ) {
    this.clientIdGateway = environment.clientIdGateway;
  }

  public initSessionStorage(tokenValue: string, expiresIn: number, typeToken: string): Observable<any> {
    this.addToSessionStorage(tokenValue, expiresIn, typeToken, true);

    return this.userService.findMe().pipe(
      tap((data: User) => {
        this.userStore.setUser(data);
      })
    );
  }

  /**
   * Permet de récupérer un token valide en mode client_credentials
   */
  public getAccessToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.isAuthenticated()) {
        const url = '/api/oauth/token';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        };
        const body = new URLSearchParams();
        body.set('grant_type', 'client_credentials');

        this.http
          .post<AuthToken>(url, body.toString(), httpOptions)
          .toPromise()
          .then(
            (authToken) => {
              const user = this.userStore.getCurrentConnectedUser();
              if (!user) {
                this.addToSessionStorage(authToken.access_token, authToken.expires_in, authToken.token_type, true);
              }
              resolve();
            },
            (err) => {
              throw new Error('REVOKE TOKEN FAILED : ' + err);
            }
          );
      } else {
        resolve();
      }
    });
  }

  /**
   * Permet de révoker un toKen lorsque l'utilisateur se déconnecte
   * @param clientIdGateway
   * @param logoutURL
   */
  public revokeToken(clientIdGateway) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http.post('/api/oauth/revoke', `token=${sessionStorage.getItem('token')}&token_type_hint=access_token`, httpOptions);
  }

  /**
   * Fonction qui permet de déconnecter un utilisateur en révoquant son token et en redirigeant sur la page d'accueil du site vitrine
   */
  public logout(): Observable<any> {
    this.loaderService.startLoading();

    // Ici on ne met pas de stopLoading car le site vitrine met très longtemps à charger,
    // et une fois qu'il aura chargé il détruira l'appli et donc le loader en même temps
    return this.revokeToken(environment.clientIdGateway)
      .pipe(
        catchError((error) => {
          throw new Error('REVOKE TOKEN FAILED : ' + error);
        }),
        finalize(() => {
          this.resetStoresAndStorages();
          // En local on ne redirige pas vers le site vitrine
          if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
            this.router.navigateByUrl('/authent');
            this.loaderService.stopLoading();
          } else {
            this.urlService.redirectToSiteVitrine('', false);
          }
        })
      );
  }

  /**
   * Permet de savoir si on est connecté à l'application Cadostore
   */
  public isAuthenticated(): boolean {
    // vérifie que l'on a le token en session storage
    const token = sessionStorage.getItem(SESSIONSTORAGE.ITEM_TOKEN);
    const dateExpiration = new Date(Number(sessionStorage.getItem(SESSIONSTORAGE.ITEM_EXPIRES)));
    if (token === null || token.length === 0 || dateExpiration === null) {
      return false;
    } else {
      // on vérifie que le token est toujours valide
      const time = new Date();
      return time <= dateExpiration;
    }
  }

  /**
   * Permet de récupérer un token valide en mode client_credentials
   */
  public getAccessToken$(): Observable<AuthToken> {
    const url = '/api/oauth/token';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');

    return this.http.post<AuthToken>(url, body.toString(), httpOptions).pipe(
      catchError((err) => {
        throw new Error('REVOKE TOKEN FAILED : ' + err);
      })
    );
  }

  getIssuerSamlId$(): Observable<string> {
    return this.authentificationService.authentificate(this.clientIdGateway, this.REPONSE_TYPE).pipe(
      switchMap((html: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        // @ts-ignore
        const samlRequest = doc.getElementsByName('SAMLRequest')[0].value;
        // @ts-ignore
        const appNameEncoded = doc.getElementsByName('appNameEncoded')[0].value;
        return this.userIDPService.extractSAMLRequest(samlRequest, appNameEncoded);
      }),
      map((retour) => retour.id)
    );
  }

  /**
   * Fonction qui permet d'ajouter les informations dans le sessionStorage
   */
  public addToSessionStorage(tokenValue: string, expiresIn: number, typeToken: string, forced?: boolean) {
    if (this.isAuthenticated() && !forced) {
      return;
    }
    sessionStorage.setItem(SESSIONSTORAGE.ITEM_TOKEN, tokenValue);
    const current = new Date();
    // Le temps d'expiration de la gateway est en seconde (il faut ajouter 1000 pour avoir le temps en milliseconde)
    sessionStorage.setItem(SESSIONSTORAGE.ITEM_EXPIRES, (current.getTime() + expiresIn * 1000).toString());
    sessionStorage.setItem(SESSIONSTORAGE.ITEM_SCOPE, typeToken);
  }

  /**
   * Méthode permettant de détruire les informations stockées dans les différents stores et le browser storage
   * @private
   */
  private resetStoresAndStorages(): void {
    this.userStore.disconnect();
    this.cardActivationStore.resetCardActivationRequest();
    this.cartService.resetCart();
    this.menuDesktopService.hideUserConnected();

    sessionStorage.clear();
    localStorage.removeItem(LOCAL_STORAGE.IS_CONNECTE);
    localStorage.removeItem(LOCAL_STORAGE.NOM);
    localStorage.removeItem(LOCAL_STORAGE.PRENOM);
  }
}
