import { Injectable } from '@angular/core';
import { PageNameEnum } from '@layouts/sous-menu-desktop/page-name.enum';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageStore {
  currentPage$: Observable<PageNameEnum>;
  isFromBimpli$: Observable<boolean>;
  isParcoursDigital$: Observable<boolean>;
  private readonly changePageSubject: BehaviorSubject<PageNameEnum>;
  private readonly previousPageSubject = new BehaviorSubject<PageNameEnum>(null);
  readonly previousPage$ = this.previousPageSubject.asObservable();
  private readonly isFromBimpliSubject: BehaviorSubject<boolean>;
  private readonly isParcoursDigitalSubject: BehaviorSubject<boolean>;

  constructor() {
    this.changePageSubject = new BehaviorSubject<PageNameEnum>(PageStore.getPageNameOnLocalStorage());
    this.currentPage$ = this.changePageSubject.asObservable();

    this.isFromBimpliSubject = new BehaviorSubject<boolean>(false);
    this.isFromBimpli$ = this.isFromBimpliSubject.asObservable();

    this.isParcoursDigitalSubject = new BehaviorSubject<boolean>(false);
    this.isParcoursDigital$ = this.isParcoursDigitalSubject.asObservable();
  }

  private static getPageNameOnLocalStorage(): PageNameEnum {
    switch (localStorage.getItem(LOCALSTORAGE.CURRENT_PAGE_NAME)) {
      case PageNameEnum[PageNameEnum.MY_ORDERS]:
        return PageNameEnum.MY_ORDERS;
      case PageNameEnum[PageNameEnum.MY_CADO]:
        return PageNameEnum.MY_CADO;
      case PageNameEnum[PageNameEnum.MY_PROFILE]:
        return PageNameEnum.MY_PROFILE;
      case PageNameEnum[PageNameEnum.MY_REPORTS]:
        return PageNameEnum.MY_REPORTS;
      case PageNameEnum[PageNameEnum.ALL_CADO_RETAILERS]:
        return PageNameEnum.ALL_CADO_RETAILERS;
      case PageNameEnum[PageNameEnum.MY_CADO_RETAILERS]:
        return PageNameEnum.MY_CADO_RETAILERS;
      case PageNameEnum[PageNameEnum.RETAILER]:
        return PageNameEnum.RETAILER;
      case PageNameEnum[PageNameEnum.CARD_ACTIVATION]:
        return PageNameEnum.CARD_ACTIVATION;
      case PageNameEnum[PageNameEnum.CARDS_ACTIVATION]:
        return PageNameEnum.CARDS_ACTIVATION;
      case PageNameEnum[PageNameEnum.PAYMENT]:
        return PageNameEnum.PAYMENT;
      case PageNameEnum[PageNameEnum.GEOLOC]:
        return PageNameEnum.GEOLOC;
      case PageNameEnum[PageNameEnum.ORDER]:
        return PageNameEnum.ORDER;
      case PageNameEnum[PageNameEnum.BENEFICIARIES]:
        return PageNameEnum.BENEFICIARIES;
      case PageNameEnum[PageNameEnum.UPDATE_PASSWORD]:
        return PageNameEnum.UPDATE_PASSWORD;
      case PageNameEnum[PageNameEnum.LANDING_PAGE]:
        return PageNameEnum.LANDING_PAGE;
      default:
        return PageNameEnum.MY_ORDERS;
    }
  }

  public changePage(pPageName): void {
    localStorage.setItem(LOCALSTORAGE.CURRENT_PAGE_NAME, PageNameEnum[pPageName]);
    this.changePageSubject.next(pPageName);
  }

  public setIsFromBimpli(isFromBimpli: boolean): void {
    sessionStorage.setItem(SESSIONSTORAGE.FROM_BIMPLI, String(isFromBimpli));
    this.isFromBimpliSubject.next(isFromBimpli);
  }

  public setIsFromParcoursDigital(isParcoursDigital: boolean): void {
    sessionStorage.setItem(SESSIONSTORAGE.PARCOURS_DIGITAL, String(isParcoursDigital));
    this.isParcoursDigitalSubject.next(isParcoursDigital);
  }

  public setPreviousPage(page: PageNameEnum) {
    this.previousPageSubject.next(page);
  }

  public resetPreviousPage() {
    this.previousPageSubject.next(null);
  }
}
