export enum ProductTypeEnum {
  // CADO Carte
  CTS = 9,
  // CADO Chèque
  KDO = 1,
  // CADO Chèque Culture
  KCU = 2,
  // CADO Chèque Sélection
  KSE = 3
}
