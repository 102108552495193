import { Pipe, PipeTransform } from '@angular/core';

/*
 * Formatter for the phone numbers
 */
@Pipe({name: 'phone'})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    let retour = '';

    for (let i = 0; i < value.length; i++) {
      // On ajoute un . tous les deux caractères, sauf à la fin
      if (i % 2 > 0 && i !== value.length - 1) {
        retour += value.charAt(i) + ' ';
      } else {
        retour += value.charAt(i);
      }
    }
    return retour;
  }
}
