<div class="dropdown cado-menu-dropdown">

    <!-- Cas du Part ou Pro mono client -->
    <cado-button-secondary (submitted)="onProfilClick()"
                           *ngIf="!showDropdown" [buttonLabel]="buttonLabel"
                           [classSurcharge]="(pageName === PageNameEnum.MY_CADO || pageName === PageNameEnum.MY_ORDERS || pageName === PageNameEnum.MY_PROFILE ? 'pageSelected ' : '') + 'white-text bimpli-btn header-btn'">
    </cado-button-secondary>

    <!-- Cas du manager avec dropdown -->
    <cado-button-secondary (mouseleave)="hideOnMenu()"
                           (mouseover)="showOnMenu()" (submitted)="onProfilClick()" *ngIf="showDropdown" [buttonLabel]="buttonLabel"
                           [classSurcharge]="(pageName === PageNameEnum.MY_CADO || pageName === PageNameEnum.MY_ORDERS || pageName === PageNameEnum.MY_PROFILE ? 'pageSelected ' : '') + 'white-text bimpli-btn header-btn'"
                           aria-expanded="false" aria-haspopup="true"
                           data-toggle="dropdown"
                           id="dropdownMenuButton">
    </cado-button-secondary>

    <div (mouseleave)="hideOnDropdown()" (mouseover)="showOnDropdown()" *ngIf="showDropdown"
         [ngClass]="onMenu || onDropdown ? 'show': ''" aria-labelledby="dropdownMenuButton" class="dropdown-menu">

        <p *ngIf="currentCustomer != null" class="title">{{currentCustomer.company?.name}}</p>

        <cado-button-secondary (submitted)="onProfilClick()"
                               [buttonLabel]="'MENU.DROPDOWN_MANAGER.BTN_GERER_COMPTE' | translate"></cado-button-secondary>

        <div class="dropdown-divider"></div>

        <div *ngIf="customersListWithoutCurrent">
            <div *ngFor="let customer of customersListWithoutCurrent">
                <cado-link-two-rows (submitted)="onCustomerClick(customer)"
                                    [row1]="customer.companyName"
                                    [row2]="'CUSTOMER_TYPE_ENUM.'+customer.type | translate"></cado-link-two-rows>
            </div>
        </div>

    </div>

</div>
