import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { LoaderComponent } from '@layouts/loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AffiliateDisplayComponent } from '@shared/components/affiliate-display/affiliate-display.component';
import { AffiliateListDisplayComponent } from '@shared/components/affiliate-list-display/affiliate-list-display.component';
import { ButtonVerticalComponent } from '@shared/components/button-vertical/button-vertical.component';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { LinkComponent } from '@shared/components/link/link.component';
import { NamePipe } from '@shared/directives/name.pipe';
import { PhonePipe } from '@shared/directives/phone.pipe';
import { SecurePipe } from '@shared/directives/secure.pipe';
import { CheckPhoneModalComponent } from '@shared/modals/check-phone-modal/check-phone-modal.component';
import { ConfirmDisconnectModalComponent } from '@shared/modals/confirm-disconnect-modal/confirm-disconnect-modal.component';
import { EditBillingAddressModalComponent } from '@shared/modals/edit-billing-address-modal/edit-billing-address-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { NitAngularLibModule } from 'nit-angular-lib';
import { ButtonBackToTopComponent } from './components/button-back-to-top/button-back-to-top.component';
import { ButtonPrimaryComponent } from './components/button-primary/button-primary.component';
import { ButtonQuaternaryComponent } from './components/button-quaternary/button-quaternary.component';
import { ButtonSecondaryComponent } from './components/button-secondary/button-secondary.component';
import { CropComponent } from './components/crop/crop.component';
import { LinkTwoRowsComponent } from './components/link-two-rows/link-two-rows.component';
import { MerchantDisplayComponent } from './components/merchant-display/merchant-display.component';
import { MerchantListDisplayComponent } from './components/merchant-list-display/merchant-list-display.component';
import { HeaderModalCadoComponent } from './components/modal/modal-cado/header-modal-cado/header-modal-cado/header-modal-cado.component';
import { ModalCadoComponent } from './components/modal/modal-cado/modal-cado.component';
import { PaginationCadoComponent } from './components/pagination-cado/pagination-cado.component';
import { SearchAffiliateLocalizationComponent } from './components/search-affiliate-localization/search-affiliate-localization.component';
import { SearchMerchantLabelComponent } from './components/search-merchant-label/search-merchant-label.component';
import { ToastComponent } from './components/toast/toast.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { AddressPipe } from './directives/address.pipe';
import { CropPipe } from './directives/crop.pipe';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { MarkAsteriskDirective } from './directives/mark-asterisk.directive';
import { BimpliAccountModalComponent } from './modals/bimpli-account-modal/bimpli-account-modal.component';
import { BimpliPaymentInfoModalComponent } from './modals/bimpli-payment-info-modal/bimpli-payment-info-modal.component';
import { CardsActivationModalComponent } from './modals/cards-activation-modal/cards-activation-modal.component';
import { ConfirmCardActivationModalComponent } from './modals/confirm-card-activation-modal/confirm-card-activation-modal.component';
import { ConfirmCardDetachmentModalComponent } from './modals/confirm-card-detachment-modal/confirm-card-detachment-modal.component';
import { ConfirmOppositionModalComponent } from './modals/confirm-opposition-modal/confirm-opposition-modal.component';
import { DisplayCardTransactionsModalComponent } from './modals/display-card-transactions-modal/display-card-transactions-modal.component';
import { EditDeliveryAddressModalComponent } from './modals/edit-delivery-address-modal/edit-delivery-address-modal.component';
import { PurchaseVoucherInfoModalComponent } from './modals/purchase-voucher-info-modal/purchase-voucher-info-modal.component';
import { RetailersFilterModalComponent } from './modals/retailers-filter-modal/retailers-filter-modal.component';
import { SelectCustomerModalComponent } from './modals/select-customer-modal/select-customer-modal.component';
import { UpdateCompanyDataModalComponent } from './modals/update-company-data-modal/update-company-data-modal.component';
import { UpdatePhoneModalComponent } from './modals/update-phone-modal/update-phone-modal.component';
import { LuhnValidatorDirective } from './validators/luhn-validator.directive';
import { GtmConsentComponent } from './modals/gtm-consent/gtm-consent.component';
import { ConfirmExceededAmountModalComponent } from '@shared/modals/confirm-exceeded-amount-modal/confirm-exceeded-amount-modal';
import { MaintenanceModalComponent } from '@shared/modals/maintenance-modal/maintenance-modal.component';

@NgModule({
  declarations: [
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    ButtonVerticalComponent,
    ButtonBackToTopComponent,
    LinkComponent,
    LinkTwoRowsComponent,
    ToastComponent,
    ModalCadoComponent,
    EditDeliveryAddressModalComponent,
    EditBillingAddressModalComponent,
    HeaderModalCadoComponent,
    LoaderComponent,
    NamePipe,
    PhonePipe,
    SecurePipe,
    MarkAsteriskDirective,
    DebounceClickDirective,
    TooltipComponent,
    ButtonQuaternaryComponent,
    SelectCustomerModalComponent,
    LuhnValidatorDirective,
    ConfirmOppositionModalComponent,
    ConfirmCardActivationModalComponent,
    ConfirmCardDetachmentModalComponent,
    DisplayCardTransactionsModalComponent,
    RetailersFilterModalComponent,
    CropPipe,
    CropComponent,
    AddressPipe,
    SearchMerchantLabelComponent,
    SearchAffiliateLocalizationComponent,
    MerchantDisplayComponent,
    MerchantListDisplayComponent,
    FileUploadComponent,
    AffiliateDisplayComponent,
    AffiliateListDisplayComponent,
    CardsActivationModalComponent,
    UpdateCompanyDataModalComponent,
    ConfirmDisconnectModalComponent,
    ConfirmExceededAmountModalComponent,
    UpdatePhoneModalComponent,
    CheckPhoneModalComponent,
    PurchaseVoucherInfoModalComponent,
    PaginationCadoComponent,
    BimpliAccountModalComponent,
    BimpliPaymentInfoModalComponent,
    GtmConsentComponent,
    MaintenanceModalComponent
  ],
  exports: [
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    ButtonVerticalComponent,
    ButtonBackToTopComponent,
    LinkComponent,
    LinkTwoRowsComponent,
    ToastComponent,
    ModalCadoComponent,
    HeaderModalCadoComponent,
    TranslateModule,
    FormsModule,
    LoaderComponent,
    NamePipe,
    PhonePipe,
    SecurePipe,
    MarkAsteriskDirective,
    DebounceClickDirective,
    TooltipComponent,
    EditDeliveryAddressModalComponent,
    EditBillingAddressModalComponent,
    ButtonQuaternaryComponent,
    SelectCustomerModalComponent,
    ConfirmOppositionModalComponent,
    ConfirmCardActivationModalComponent,
    ConfirmCardDetachmentModalComponent,
    DisplayCardTransactionsModalComponent,
    RetailersFilterModalComponent,
    CropPipe,
    CropComponent,
    AddressPipe,
    SearchMerchantLabelComponent,
    SearchAffiliateLocalizationComponent,
    MerchantDisplayComponent,
    MerchantListDisplayComponent,
    FileUploadComponent,
    AffiliateDisplayComponent,
    AffiliateListDisplayComponent,
    CardsActivationModalComponent,
    UpdateCompanyDataModalComponent,
    ConfirmDisconnectModalComponent,
    ConfirmExceededAmountModalComponent,
    UpdatePhoneModalComponent,
    CheckPhoneModalComponent,
    PurchaseVoucherInfoModalComponent,
    PaginationCadoComponent,
    BimpliAccountModalComponent,
    BimpliPaymentInfoModalComponent,
    GtmConsentComponent,
    MaintenanceModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NitAngularLibModule.forRoot(environment),
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxMaskModule
  ],
})
export class SharedModule {
}
