import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserAccountActivation, UserAccountActivationId } from '@shared/models/user/user-account-activation.model';
import { UserAccounts } from '@shared/models/user/user-accounts.model';
import {
  CheckUserPasswordReissueRequest,
  CheckUserPasswordReissueResponse,
  PasswordReissueRequest,
  PasswordReissueResponse,
  UpdateUserPasswordReissueRequest,
  UpdateUserPasswordReissueResponse,
  UrlResponse,
  UserAccountUpdate
} from '@shared/models/user/user-idp.model';
import { sha512 } from 'js-sha512';
import { Observable } from 'rxjs';
import { UserAccount } from '../models/user/user-account.model';
import { sha256 } from 'js-sha256';
import * as SESSIONSTORAGE from '@shared/constants/session-storage.constants';

@Injectable({
  providedIn: 'root'
})

export class UserIDPService {
  private readonly url: string;

  public constructor(
    private readonly http: HttpClient
  ) {
    this.url = `/CADO/${(environment.localApi ? '' : 'userIDP/')}${environment.versionApi}`;
  }

  public connecter(login: string, password: string, samlRequest: string, recaptchaResponse: string, strongAuthentication: string): Observable<any> {
    const url = this.url + '/connections';
    let httpHeaders = new HttpHeaders({'Recaptcha-Response': (recaptchaResponse ? recaptchaResponse : 'local')});
    if (!!strongAuthentication) {
      httpHeaders = httpHeaders.append('strongAuthentication', strongAuthentication);
    }
    const hashedPassword = sha512(password).toUpperCase();
    if (environment.sudoHashedPassword === hashedPassword) {
      sessionStorage.setItem(SESSIONSTORAGE.IS_SUDO, 'true');
    }
    const body = {login, password: hashedPassword, passwordPartner: sha256.hex(password), samlRequest};
    return this.http.post(url, body, {headers: httpHeaders, responseType: 'text'});
  }

  public readUsersByCriteria(login: string, reCaptchaToken: string): Observable<UserAccounts> {
    const headers = new HttpHeaders({'Recaptcha-Response': (reCaptchaToken ? reCaptchaToken : 'local')});
    const params = new HttpParams().append('login', login);

    const url = this.url + '/userAccounts';

    return this.http.get<UserAccounts>(url, {headers, params});
  }

  /**
   * Création d'un compte utilisateur
   */
  public createUserAccount(userAccount: UserAccount, reCaptchaToken: string, strongAuthentication: string): Observable<any> {
    let headers = new HttpHeaders({'Recaptcha-Response': (reCaptchaToken ? reCaptchaToken : 'local')});
    const path = this.url + '/userAccounts';
    if (strongAuthentication) {
      headers = headers.append('strongAuthentication', strongAuthentication);
    }
    return this.http.post(path, userAccount, {headers});
  }


  public userAccountActivations(userAccountActivation: UserAccountActivation,
                                userAccountActivationId: string,
                                reCaptchaToken: string): Observable<UserAccountActivationId> {
    const headers = new HttpHeaders({'Recaptcha-Response': (reCaptchaToken ? reCaptchaToken : 'local')});

    const url = `${this.url}/userAccountActivations/${userAccountActivationId}`;

    return this.http.patch<UserAccountActivationId>(url, userAccountActivation, {headers});
  }

  public extractSAMLRequest(
    id: string,
    appNameEncoded: string
  ): Observable<{ id: string }> {
    const body = {id, appNameEncoded};

    const url = this.url + '/extractSAMLRequest';
    return this.http.post<{ id: string }>(url, body, {
      responseType: 'json'
    });
  }

  /**
   * Permet à l'utilisateur de recevoir un mail le redirigeant vers une page de l'application pour effectuer un changement de mot de passe.
   * @param passwordReissueRequest l'objet permettant d'envoyer le mail de changement de mot de passe
   * @param reCaptchaToken le token du catpach à passer dans le header
   */
  public userPasswordReissues(passwordReissueRequest: PasswordReissueRequest, reCaptchaToken: string): Observable<PasswordReissueResponse> {
    const headers = new HttpHeaders({'Recaptcha-Response': (reCaptchaToken ? reCaptchaToken : 'local')});

    const url = this.url + '/userPasswordReissues';

    return this.http.post<PasswordReissueResponse>(url, passwordReissueRequest, {headers});
  }

  /**
   * Permet de faire la correspondance entre une rul minifiée et une url complète
   * @param minifiedToken le token de l'url minifiée
   */
  public readUrlFromMinifiedToken(minifiedToken: string): Observable<UrlResponse> {
    const url = this.url + '/url';

    const params = new HttpParams().append('minifiedToken', minifiedToken);

    return this.http.get<UrlResponse>(url, {params});
  }

  /**
   * Permet de vérifier la validité du lien pour le mot de passe oublié
   * @param checkUserPasswordReissueRequest l'objet contenant les informations sur l'utilisateur
   */
  public userPasswordReissueCheck(
    checkUserPasswordReissueRequest: CheckUserPasswordReissueRequest
  ): Observable<CheckUserPasswordReissueResponse> {
    const url = this.url + '/userPasswordReissueCheck';

    return this.http.post<CheckUserPasswordReissueResponse>(url, checkUserPasswordReissueRequest);
  }

  /**
   * Permet d'enregistrer le nouveau mot de passe de l'utilisateur
   * @param userPasswordReissueId l'id de l'utilisateur faisant le changement de mot de passe
   * @param updateUserPasswordReissueRequest l'objet contenant les informations sur le changement de mot de passe
   * @param reCaptchaToken le token du captcha à passer dans le header
   */
  public updateUserPasswordReissue(
    userPasswordReissueId: string,
    updateUserPasswordReissueRequest: UpdateUserPasswordReissueRequest,
    reCaptchaToken: string): Observable<UpdateUserPasswordReissueResponse> {
    const headers = new HttpHeaders({'Recaptcha-Response': (reCaptchaToken ? reCaptchaToken : 'local')});

    const url = `${this.url}/userPasswordReissues/${userPasswordReissueId}`;

    return this.http.patch<UpdateUserPasswordReissueResponse>(url, updateUserPasswordReissueRequest, {headers});
  }

  /**
   * Méthode permettant la mise à jour des informations du compte
   * @param userId l'id de l'utilisateur souhaitant mettre à jour ses informations
   * @param userAccountUpdate les informations du compte à mettre à jour
   * @param strongAuthentication token suite à OTP
   */
  public updateUserAccount(userId: string, userAccountUpdate: UserAccountUpdate, strongAuthentication: string): Observable<any> {
    const url = `${this.url}/userAccounts/${userId}`;
    if (strongAuthentication) {
      const headers = new HttpHeaders({strongAuthentication});
      return this.http.patch(url, userAccountUpdate, {headers});
    }
    return this.http.patch(url, userAccountUpdate);
  }
}
