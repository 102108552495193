export enum ErrorCodeApiEnum {
  AUTHENTICATION_FAILURE_001 = 'AUTHENTICATION_FAILURE_001',
  INVALID_FORMAT_002 = 'INVALID_FORMAT_002',
  FORBIDDEN_ACTION_003 = 'FORBIDDEN_ACTION_003',
  UNEXPECTED_004 = 'UNEXPECTED_004',
  NOT_FOUND_005 = 'NOT_FOUND_005',
  MISSING_MANDATORY_ATTRIBUTE_006 = 'MISSING_MANDATORY_ATTRIBUTE_006',
  MISSING_MANDATORY_HEADER_006 = 'MISSING_MANDATORY_HEADER_006',
  INCORRECT_DATA_007 = 'INCORRECT_DATA_007',
  MISSING_MANDATORY_ATTRIBUTE_008 = 'MISSING_MANDATORY_ATTRIBUTE_008',
  NOT_FOUND_009 = 'NOT_FOUND_009',
  NOT_FOUND_010 = 'NOT_FOUND_010',
  NOT_FOUND_011 = 'NOT_FOUND_011',
  NOT_FOUND_012 = 'NOT_FOUND_012',
  IMPOSSIBLE_ACTION_013 = 'IMPOSSIBLE_ACTION_013',
  NOT_FOUND_020 = 'NOT_FOUND_020',
  ALREADY_EXIST_022 = 'ALREADY_EXIST_022',
  IMPOSSIBLE_ACTION_024 = 'IMPOSSIBLE_ACTION_024',
  IMPOSSIBLE_ACTION_025 = 'IMPOSSIBLE_ACTION_025',
  ALREADY_EXIST_026 = 'ALREADY_EXIST_026',
  NOT_FOUND_023 = 'NOT_FOUND_023',
  NOT_FOUND_027 = 'NOT_FOUND_027',
  NOT_FOUND_028 = 'NOT_FOUND_028',
  CARD_PAN_INVALID_030 = "CARD_PAN_INVALID_030",
  CARD_ACTIVATION_STATUS_INVALID_032 = 'CARD_ACTIVATION_STATUS_INVALID_032',
  CARD_NOT_LOADED_033 = 'CARD_NOT_LOADED_033',
  KYC_NEEDED_034 = 'KYC_NEEDED_034',
  ACTIVATION_CODE_INVALID_035 = 'ACTIVATION_CODE_INVALID_035',
  INVALID_ACTIVATION_CODE_035 = 'INVALID_ACTIVATION_CODE_035',
  NOT_FOUND_037 = 'NOT_FOUND_037',
  INVALID_VOUCHER_VALUE_039 = 'INVALID_VOUCHER_VALUE_039',
  INVALID_BOOKLET_QUANTITY_040 = 'INVALID_BOOKLET_QUANTITY_040',
  INVALID_CARRIER_041 = 'INVALID_CARRIER_041',
  INVALID_ORDER_PRODUCT_042 = 'INVALID_ORDER_PRODUCT_042',
  INVALID_PRODUCT_FAMILY_043 = 'INVALID_PRODUCT_FAMILY_043',
  INVALID_EVENT_044 = 'INVALID_EVENT_044',
  INVALID_USE_CATEGORY_045 = 'INVALID_USE_CATEGORY_045',
  NOT_FOUND_PARAMETER_046 = 'NOT_FOUND_PARAMETER_046',
  NOT_FOUND_048 = 'NOT_FOUND_048',
  NOT_FOUND_049 = 'NOT_FOUND_049',
  NOT_FOUND_051 = 'NOT_FOUND_051',
  INVALID_ESTABLISHMENT_CODE_054 = 'INVALID_ESTABLISHMENT_CODE_054',
  INVALID_DISCOUNT_CODE_055 = 'INVALID_DISCOUNT_CODE_055',
  CARD_ALREADY_ATTACHED_062 = 'CARD_ALREADY_ATTACHED_062',
  SMONEY_ERROR_065 = 'SMONEY_ERROR_065',
  CARD_PLATEFORM_ERROR_066 = 'CARD_PLATEFORM_ERROR_066',
  IMPOSSIBLE_ACTION_067 = 'IMPOSSIBLE_ACTION_067',
  IDENTITY_ALREADY_VALIDATED_069 = 'IDENTITY_ALREADY_VALIDATED_069',
  NOT_FOUND_075 = 'NOT_FOUND_075',
  AUTH_JWT_EXPIRED_096 = 'AUTH_JWT_EXPIRED_096',
  ALREADY_PENDING_KYC_076 = 'ALREADY_PENDING_KYC_076',
  FORBIDDEN_083 = 'FORBIDDEN_083',
  STRONG_AUTHENTIFICATION_NEEDED_084 = 'STRONG_AUTHENTIFICATION_NEEDED_084',
  NOT_FOUND_085 = 'NOT_FOUND_085',
  NO_CARD_TO_ACTIVATE_086 = 'NO_CARD_TO_ACTIVATE_086 ',
  MISSING_POSTAL_ADDRESS_088 = 'MISSING_POSTAL_ADDRESS_088',
  AUTH_OTP_EXPIRED_101 = 'AUTH_OTP_EXPIRED_101',
  STRONG_AUTHENTICATION_FAILURE_105 = 'STRONG_AUTHENTICATION_FAILURE_105',
  SECURITY_UNAUTHORIZED_CELLPHONE_NUMBER_MODIFICATION_108 = 'SECURITY_UNAUTHORIZED_CELLPHONE_NUMBER_MODIFICATION_108',
  AUTH_OTP_PHONE_FORBIDDEN_111 = 'AUTH_OTP_PHONE_FORBIDDEN_111',
  CELL_PHONE_RECENTLY_UPDATED_112 = 'CELL_PHONE_RECENTLY_UPDATED_112',
  CELL_PHONE_TOO_OFTEN_USED_UPDATED_113 = 'CELL_PHONE_TOO_OFTEN_USED_UPDATED_113',
  ORDER_FORBIDDEN_TO_INDIVIDUAL_114 = 'ORDER_FORBIDDEN_TO_INDIVIDUAL_114',
  MAIL_FORBIDDEN_115 = 'MAIL_FORBIDDEN_115',
  ASSERTION_FAILED_999 = 'ASSERTION_FAILED_999',
  UNKNOWN = 'UNKNOWN'
}
